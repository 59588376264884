import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {get_all_compnay_mail_boxes,closeAlert,get_compnay_mailBox_by_id} from '../../../redux/action/compnayAdmin/companyActionCreator';
import Alert from 'react-bootstrap/Alert';
import Loader from '../../../Components/common/Loader';

const MailBoxListing = (props) =>{
    const [mailBoxList, setMailBoxList] = useState([]);
    //const {companyTerritories}= mailBoxList;
   // console.log(mailBoxList);
    useEffect(()=>{
        props.get_all_compnay_mail_boxes().then(result => {
            setMailBoxList(result);
            });
            
            props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //set company teritory by id
    const getCompanyMailBoxByIDHandler = (e,mailBoxId)=>{
        props.get_compnay_mailBox_by_id(mailBoxId,props.history)
    }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Mail Boxes</h1>
                            <Alert id="alert" className="alert" variant={props.alertData.resultType} show={props.alertData.show} 
                                onClose={() => props.closeAlert(false)} dismissible
                                >
                                <p>{props.alertData.msg}</p>
                                </Alert>
                        </div>
                        {/* <div className="short d-flex align-self-center">
                           <Link to={'/AddTerritory'} className="btn btn-primary">Add Territory</Link>
                        </div> */}
                    </div>
                    {mailBoxList ?
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mailBoxList && mailBoxList.map(item=>{
                                    return (
                                        <tr key={item.id}>
                                            <td className="agent-name">
                                                <h2>{item.emailAddress}</h2>
                                            </td>
                                            <td className="property-option">
                                                <div className="db-action">
                                                    <Link className="edit btn" to="#" onClick={(e)=>getCompanyMailBoxByIDHandler(e,item.id)}><span><i className="fa fa-pencil-square-o" aria-hidden="true"></i></span></Link>
                                                 </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                    </div>
                    :<Loader />}
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 

    );
} 


const mapStateToProps = state => {
    return {
      alertData: state.Company.alert,
    }
  }

export default connect(mapStateToProps,{get_all_compnay_mail_boxes,closeAlert,get_compnay_mailBox_by_id})(MailBoxListing);