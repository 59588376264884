
import React from 'react';
import Loader from "react-js-loader";

const Loaders =(props)=>{
    return(
        <React.Fragment>
            <div className="row">
                 <div className="col-lg-12">
                  <Loader type="spinner-default" bgColor={"#179bee"} title={"Loading...."} color={'#179bee'} size={100} />
                 </div>
            </div>
        </React.Fragment>
    )
}

export default Loaders;