import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {company_users_Listing,storeCompanyUserId} from '../../../redux/action/compnayAdmin/companyActionCreator';
import Loader from '../../../Components/common/Loader';

const UserListing = (props) =>{
    const [companyUsersList, setCompaniesUserList] = useState([]);
    useEffect(()=>{
        props.company_users_Listing().then(result => {
            setCompaniesUserList(result);
                // console.log('hi');
            });
            
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const editCompnayUserHandler = (e,companyUserId) => {
        props.storeCompanyUserId(companyUserId,props.history);
        
    }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Users</h1>
                        </div>
                        <div className="short d-flex align-self-center">
                           <Link to={'/AddUser'} className="btn btn-primary">Add New User</Link>
                        </div>
                    </div>
                    {companyUsersList ?
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">User Name</th>
                                    <th scope="col">User Details</th>
                                    <th scope="col">User Role</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyUsersList && companyUsersList.map(item=>{
                                    return (
                                        <tr key={item.id}>
                                            <td className="agent-name">
                                                <h2>{item.firstName} {item.lastName}</h2>
                                            </td>
                                            <td className="agent-status">
                                                <Link to="#"><i className="fa fa-envelope" aria-hidden="true"></i> {item.email}</Link>
                                                <Link to="#">{item.phoneNumber ? <i className="fa fa-phone" aria-hidden="true"></i>:null} {item.phoneNumber }</Link>
                                            </td>
                                            <td className="agent-status">
                                                <Link to="#"><i className="fa fa-user" aria-hidden="true"></i> {item.userType === "COMPANY_USER" ? "User":"Admin"}</Link>
                                            </td>
                                            <td  >
                                                <p>{item.status}</p>
                                            </td>
                                            <td className="property-option">
                                                <div className="db-action">
                                                    <Link className="edit btn" to="#" onClick={(e) => editCompnayUserHandler(e,item.id)}><span><i className="fa fa-pencil-square-o" aria-hidden="true"></i></span></Link>
                                                  </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                    </div>
                    :<Loader />}
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 

    );
} 

export default connect(null,{company_users_Listing,storeCompanyUserId})(UserListing);