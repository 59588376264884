import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import rootReducer from './reducers';

const initalState = {};

const middleware = [thunk];

export const store = createStore(rootReducer, initalState, composeWithDevTools(applyMiddleware(...middleware)));

export const persistor = persistStore(store);

//export default {store , persistor };
