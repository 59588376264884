import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {get_all_compnay_territories_info,get_compnay_territories_by_id,closeAlert} from '../../../redux/action/compnayAdmin/companyActionCreator';
import Alert from 'react-bootstrap/Alert';
import Loader from '../../../Components/common/Loader';

const CompanyListing = (props) =>{
    const [territoryList, setTerritoriesList] = useState([]);
    const {companyTerritories}=  territoryList || {};

    useEffect(()=>{
        props.get_all_compnay_territories_info().then(result => {
            setTerritoriesList(result);
            });
            
            props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //set company teritory by id
    const getCompanyTerritoryByIDHandler = (e,territoryId)=>{
        props.get_compnay_territories_by_id(territoryId,props.history)
    }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Territories</h1>
                            
                        </div>
                        {/* <div className="short d-flex align-self-center">
                           <Link to={'/AddTerritory'} className="btn btn-primary">Add Territory</Link>
                        </div> */}
                    </div>
                    <Alert id="alert" className="alert" variant={props.alertData.resultType} show={props.alertData.show} 
                                onClose={() => props.closeAlert(false)} dismissible
                                >
                                <p>{props.alertData.msg}</p>
                                </Alert>
                    {companyTerritories  ? 
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Territory Name</th>
                                    <th scope="col">Territory Location</th>
                                    <th scope="col">Status</th>
                                    {/* <th scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {companyTerritories && companyTerritories.map(item=>{
                                    return (
                                        <tr key={item.id}>
                                            <td className="agent-name">
                                                <h2>{item.territoryName}</h2>
                                            </td>
                                            
                                            <td className="agent-status">
                                            {item.zipcode.slice(0, 2).map((item,i) => {
                                                return ( 
                                                        <Link to="#" key={i}><i className="fa fa-map-marker " aria-hidden="true"></i> {item.zipCode}</Link>
                                                        )})}
                                                {item.zipcode.length > 2 ? <Link to="#"  style={{color: '#179bee'}} onClick={(e)=>getCompanyTerritoryByIDHandler(e,item.id)}><i className="fa fa-link" aria-hidden="true"></i>More...</Link>:null}
                                            
                                            {/* {item.zipcode.map(cityandZipcode => {
                                                return ( 
                                                        <Link to="#" key={cityandZipcode}><i className="fa fa-map-marker " aria-hidden="true"></i> {cityandZipcode.zipCode}</Link>
                                                       
                                                        )} )} */}
                                             </td>
                                            <td >
                                                <p>{item.isActive ? 'Active':'Inactive'}</p>
                                            </td>
                                            {/* <td className="property-option">
                                                <div className="db-action">
                                                    <Link className="edit btn" to="edit-agent.html"><span><i className="fa fa-pencil-square-o" aria-hidden="true"></i></span></Link>
                                                    <Link className="delete" to="#"><span><i className="fa fa-trash-o" aria-hidden="true"></i></span></Link>
                                                </div>
                                            </td> */}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                    </div>
                    :<Loader/>}
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
            </div>
        </div>
            </div> 
            </div> 

    );
} 


const mapStateToProps = state => {
    return {
      alertData: state.Company.alert,
      territoryDetails: state.Company.companyTerritories,
    }
  }

export default connect(mapStateToProps,{get_all_compnay_territories_info,get_compnay_territories_by_id,closeAlert})(CompanyListing);