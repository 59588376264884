import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Login from './containers/Login';
import ResetPassword from './containers/Login/resetPassword';
import Layout from './containers/Layout';
import { getSession } from './redux/Auth/sessionData';
import CreateCompany from './containers/ProductAdmin/Company/createCompany';
import EditCompany from './containers/ProductAdmin/Company/editCompany';
import CompanyListing from './containers/ProductAdmin/Company/companiesList';
import AddTerritory from './containers/ProductAdmin/Territory/addTerritory';
import EditTerritory from './containers/ProductAdmin/Territory/editTerritory';
import TerritoryList from './containers/ProductAdmin/Territory/territoryList';
// import AddZipcode from './containers/ProductAdmin/Zipcode/addZipcode';
// import ZipcodeList from './containers/ProductAdmin/Zipcode/zipcodeList';
import AddUser from './containers/companyAdmin/UserManagement/addUser';
import EditUser from './containers/companyAdmin/UserManagement/editUser';
import AllUsers from './containers/companyAdmin/UserManagement/usersList';
import CompnayTerritoryList from './containers/companyAdmin/TerritoryManagement/territoryList';
import UpdateProfile from './containers/Login/profile';
import CompanyUpdateProfile from './containers/Login/profile';
import CompanyUserProfile from './containers/Login/profile';
import ForgotPassword from './containers/Login/forgotPassword';
import CompanyTerritory from './containers/companyAdmin/TerritoryManagement/territory';
import CreateMailBox from './containers/companyAdmin/MailBoxManagement/addMailBox';
import EditMailBox from './containers/companyAdmin/MailBoxManagement/editMailBox';
import AllMailBox from './containers/companyAdmin/MailBoxManagement/listMailBox';
import AddCampaign from './containers/compnayUser/campaignManagement/addCampaign'
import CampaignList from './containers/compnayUser/campaignManagement/listCampaign'
import EditCampaign from './containers/compnayUser/campaignManagement/editCampaign'
import DrawMap from './containers/companyAdmin/TerritoryManagement/map';
import AddSubTerritories from './containers/companyAdmin/TerritoryManagement/addSubTerritories';
import SubTerritoriesList from './containers/companyAdmin/TerritoryManagement/listSubTerritories';
import ViewSubTerritory from './containers/companyAdmin/TerritoryManagement/viewSubTerritory';
import UserSubTerritoriesList from './containers/compnayUser/subTerritoryManagement/userSubTerritoriesList';
import ViewUserSubTerritory from './containers/compnayUser/subTerritoryManagement/viewUserSubTerritory';
import viewCampaign from './containers/compnayUser/campaignManagement/viewCampaign';
import MessageTemplate from './containers/compnayUser/TemplateManagement/messageTemplate';
import PropertyDetails from './containers/compnayUser/propertyManagement';
import Dashboard from './Components/Dashboard';
import CompanyDashboard from './containers/companyAdmin/Dashboard';
import ContactList from './containers/compnayUser/contact';
import ViewContact from './containers/compnayUser/contact/viewContact';
import Unsubscribe from './Components/unsubscribe';

//Login Route
const ProtectedLoginRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        var stored_token = getSession();
        if (stored_token && stored_token.userType === "PRODUCT_ADMIN" ) {
          return (
            <Redirect
              to={{
                pathname: "/CompanyList",
                state: {
                  from: props.location
                }
              }}
            />
          );

        }else if(stored_token && stored_token.userType === "COMPANY_ADMIN" ){
          return (
            <Redirect
              to={{
                pathname: "/AllUsers",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }else if(stored_token && stored_token.userType === "COMPANY_USER" ){
          return (
            <Redirect
              to={{
                pathname: "/AllCampaign",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
        else {
          return <Component {...props} />;
        }
      }}
    />
  );
};


//Admin users route
const ProtectedAdminRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        var stored_token = getSession();
        if (stored_token && stored_token.userType === "PRODUCT_ADMIN") {
          return <Component {...props} />;
        }
        else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

//Compnay Admin users route
const ProtectedCompanyAdminRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        var stored_token = getSession();
        if (stored_token && stored_token.userType === "COMPANY_ADMIN") {
          return <Component {...props} />;
        }
        else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

//Compnay User route
const ProtectedCompanyUserRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        var stored_token = getSession();
        if (stored_token && stored_token.userType === "COMPANY_USER") {
          return <Component {...props} />;
        }
        else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

//admin & user routes
const ProtectedCompanyAdminUserRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        var stored_token = getSession();
        if(stored_token){
        if (stored_token.userType === "COMPANY_ADMIN"  || stored_token.userType === "COMPANY_USER") {
          return <Component {...props} />;
        }
        else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }else {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location
              }
            }}
          />
        );
      }
      }}
    />
  );
};

const App =(props)=> {
  const session_data = getSession();
  
  const router=<Switch>
                  <Route path="/Unsubscribe:name" exact component={Unsubscribe} />
                  <ProtectedLoginRoute path="/" exact component={Login} />
                  <ProtectedLoginRoute path="/reset_password:name" exact component={ResetPassword} />
                  <ProtectedLoginRoute path="/Forgot_Password" exact component={ForgotPassword} />
                  <ProtectedAdminRoute path="/Profile" exact component={UpdateProfile} />
                  <ProtectedAdminRoute path="/AddCompany" exact component={CreateCompany} />
                  <ProtectedAdminRoute path="/EditCompany" exact component={EditCompany} />
                  <ProtectedAdminRoute path="/CompanyList" exact  component={CompanyListing} />
                  <ProtectedAdminRoute path="/AddTerritory" exact component={AddTerritory} />
                  <ProtectedAdminRoute path="/EditTerritory" exact component={EditTerritory} />
                  <ProtectedAdminRoute path="/TerritoryList" exact component={TerritoryList} />
                  {/* <ProtectedAdminRoute path="/AddZipcode" exact component={AddZipcode} />
                  <ProtectedAdminRoute path="/ZipcodeList" exact component={ZipcodeList} /> */}
                  <ProtectedCompanyAdminRoute path="/CompanyProfile" exact  component={CompanyUpdateProfile} /> 
                  <ProtectedCompanyAdminRoute path="/AddUser" exact component={AddUser} /> 
                  <ProtectedCompanyAdminRoute path="/AddSubTerritory" exact component={AddSubTerritories} /> 
                  <ProtectedCompanyAdminRoute path="/AllSubTerritories" exact component={SubTerritoriesList} /> 
                  <ProtectedCompanyAdminRoute path="/ViewSubTerritory" exact component={ViewSubTerritory} /> 
                  <ProtectedCompanyAdminRoute path="/map" exact component={DrawMap} /> 
                  <ProtectedCompanyAdminRoute path="/EditUser" exact component={EditUser} /> 
                  <ProtectedCompanyAdminRoute path="/AllUsers" exact component={AllUsers} />
                  <ProtectedCompanyAdminRoute path="/CompnayTerritoryList" exact component={CompnayTerritoryList} />
                  <ProtectedCompanyAdminRoute path="/CompanyTerritory" exact component={CompanyTerritory} />
                  <ProtectedCompanyAdminRoute path="/AddMailBox" exact component={CreateMailBox} />
                  <ProtectedCompanyAdminRoute path="/UpdateMailBox" exact component={EditMailBox} />
                  <ProtectedCompanyAdminRoute path="/AllMailBoxes" exact component={AllMailBox} />
                  <ProtectedCompanyAdminRoute path="/CompanyDashboard" exact component={CompanyDashboard} /> 
                  <ProtectedCompanyAdminRoute path="/CompanyContact" exact component={ContactList} /> 
                  <ProtectedCompanyAdminRoute path="/ViewCompanyContact" exact component={ViewContact} /> 
                  {/* <ProtectedCompanyAdminRoute path="/ViewCompanyPropertyDetails" exact component={PropertyDetails} /> */}
                  <ProtectedCompanyAdminUserRoute path="/AllCampaign" exact component={CampaignList} />
                  <ProtectedCompanyAdminUserRoute path="/AddCampaign" exact component={AddCampaign} />
                  <ProtectedCompanyAdminUserRoute path="/UpdateCampaign" exact component={EditCampaign} /> 
                  <ProtectedCompanyAdminUserRoute path="/ViewCampaign" exact component={viewCampaign} /> 
                  <ProtectedCompanyAdminUserRoute path="/ViewPropertyDetails" exact component={PropertyDetails} /> 


                  {/* <ProtectedCompanyUserRoute path="/AllCampaign" exact component={CampaignList} />
                  <ProtectedCompanyUserRoute path="/AddCampaign" exact component={AddCampaign} />
                  <ProtectedCompanyUserRoute path="/UpdateCampaign" exact component={EditCampaign} /> */}
                  <ProtectedCompanyUserRoute path="/UserProfile" exact component={CompanyUserProfile} />
                  <ProtectedCompanyUserRoute path="/AllTerritories" exact component={UserSubTerritoriesList} />
                  <ProtectedCompanyUserRoute path="/ViewTerritory" exact component={ViewUserSubTerritory} /> 
                  {/* <ProtectedCompanyUserRoute path="/ViewCampaign" exact component={viewCampaign} />  */}
                  <ProtectedCompanyUserRoute path="/MessageTemplate" exact component={MessageTemplate} /> 
                  {/* <ProtectedCompanyUserRoute path="/ViewPropertyDetails" exact component={PropertyDetails} />  */}
                  <ProtectedCompanyUserRoute path="/Dashboard" exact component={Dashboard} /> 
                  <ProtectedCompanyUserRoute path="/Contact" exact component={ContactList} /> 
                  <ProtectedCompanyUserRoute path="/ViewContact" exact component={ViewContact} /> 

                </Switch>;
 
  
  return (
    <React.Fragment>
      <Router >
      
      <Layout routes={router} userType={session_data && session_data.userType}></Layout>
      </Router>
    </React.Fragment>
  );
}

export default App;
