import React, { useEffect, useState } from 'react';
import { Link,withRouter } from 'react-router-dom';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { connect } from 'react-redux';
import {getall_state_codes,getall_cities_zipcodes,create_territory,closeAlert} from '../../../redux/action/productAdmin/productActionCreator';


const CreateTerritory = (props) =>{
    const [territoryName,setTerritoryName] =useState('');
    const [stateName,setStateName] =useState('');
    const [stateCodeList,setstateCodeList] =useState([]);
    const [citieszipcodeList,setCitieszipcodeList] =useState([]);
    const [zipcodeList,setZipcodeList] =useState([]);
    const [citiesList,setCitiesList] =useState([]);
    const [updateCitieszipcodeList,setUpdateCitieszipcodeList] =useState([]);
    const [isActive,setisActive] =useState(true);
    const [isShow,setIsShow] =useState(false);
    const [error,setError]=useState({
        territoryNameErr:'',
        stateNameErr:'',
        citiesListErr:'',
        zipcodeListErr:'',
    });

    useEffect(()=>{
        props.getall_state_codes().then(result => {
            setstateCodeList(result);
        });
        props.closeAlert(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(updateCitieszipcodeList.length > 0){
        const zipList = updateCitieszipcodeList.filter(item => zipcodeList.some(list => list.value === item.value));
        setUpdateCitieszipcodeList(zipList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[zipcodeList]);

    const  {cities}  = citieszipcodeList || {};
    const  {states}  = stateCodeList || {};
    const  options  = cities && cities.map(item => {
        const val= { label:  item.cityName, value:  item.cityName, zipcode: item.zipcodes  };
        return val;
      });
   
    //   const zipcodeData = zipcodeList && zipcodeList.map((item,i) => {
    //     //  console.log(item);
    //     const val= { cityName: item.cityName,zipCode: item.Value,label:  item.Value, value:  item.Value};
    //     return val;
    //   });

   

    const toggleActiveHandler = (e) =>{
        setisActive(!isActive);
    }

    const setCityZipcode =(e)=>{
        debugger;
        setCitiesList(e);
        let zipcodes= [];
        let city= '';
        e && e.forEach(item => {
            city= item.label;

            const zipcodesWithCityName = item.zipcode.map(zip => {
              const val= {cityName: city, zipCode: zip,label: zip,value:zip};
              return val;
               // return zip;
                })
           zipcodes= [...zipcodes,...zipcodesWithCityName];
        });
        setZipcodeList(zipcodes);
      //  setUpdateCitieszipcodeList(zipcodes);
      if(e.length === 0){
        setZipcodeList([]);
        setUpdateCitieszipcodeList([]);
      }
    }


    const setZipcodeData = (e) =>{
        // setCitiesList([...new Set(e.map(item => {
        //  const val= { label:  item.cityName, value:  item.cityName, zipcodes: item.zipcodes,cityName: item.cityName  };
        //      return val;
        //  }))])
        setUpdateCitieszipcodeList(e);
          //if(e.length === 0){
              //setZipcodeList([]);
              //setUpdateCitieszipcodeList([]);
          //}
     }

     const stateChangeHandler = (e) =>{
        setCitiesList([]);
        setZipcodeList([]);
        setUpdateCitieszipcodeList([]);
        if(e.target.value === ''){
            setIsShow(false);
            setStateName('');
        }else{
            setStateName(e.target.value);
            props.getall_cities_zipcodes(e.target.value).then(result => {
                setCitieszipcodeList(result);
                setIsShow(true);
            });
        }
    }

    //console.log(updateCitieszipcodeList);
    const validate = (e) => {
        let territoryNameVal = territoryNameValidation(territoryName);
        let stateNameVal = stateNameValidation(stateName);
        if(stateNameVal === true){
            let citiesListVal = citiesListValidation(citiesList);
            let zipcodeListVal = zipcodeListValidation(updateCitieszipcodeList);
            if(territoryNameVal === true && citiesListVal === true && zipcodeListVal === true) {
                return true;
             }
        }
         
      }
    
      function territoryNameValidation(territoryName) { 
          
        if(!territoryName) {
            setError(prevState => ({
                ...prevState,
                territoryNameErr: 'Please Enter Territory Name'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(territoryName)){
        //     setError(prevState => ({
        //         ...prevState,
        //         territoryNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(territoryName) {
            setError(prevState => ({
                ...prevState,
                territoryNameErr: ''
            }));
          return true;
        }
      }
      function stateNameValidation(stateName) { 
        if(!stateName || stateName === "") {
            setError(prevState => ({
                ...prevState,
                stateNameErr: 'Please Select State'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(territoryName)){
        //     setError(prevState => ({
        //         ...prevState,
        //         territoryNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(stateName) {
            setError(prevState => ({
                ...prevState,
                stateNameErr: ''
            }));
          return true;
        }
      }
      function citiesListValidation(citiesList) {  
          //console.log(citiesList.length);  
        if(citiesList.length === 0){
            setError(prevState => ({
                ...prevState,
                citiesListErr: 'Please Select City'
            }));
            return false;
        }
        if(citiesList.length > 0){
            setError(prevState => ({
                ...prevState,
                citiesListErr: ''
            }));
            return true;
        }
      }
      function zipcodeListValidation(updateCitieszipcodeList) {
        if(updateCitieszipcodeList.length === 0){
            setError(prevState => ({
                ...prevState,
                zipcodeListErr: 'Please Select Zipcode'
            }));
            return false;
        }
        if(updateCitieszipcodeList.length >0){
            setError(prevState => ({
                ...prevState,
                zipcodeListErr: ''
            }));
            return true;
        }
        
      }

    const addTerritoryHandler = async (e) =>{
        e.preventDefault();
        //console.log(citiesList);
        // updateCitieszipcodeList.forEach(zipcode => zipcode = zipcode.value);
        const payload_data= {
                cityZipCodes: updateCitieszipcodeList,
                isActive: isActive,
                territoryName: territoryName,
        };
       //console.log(payload_data);
         if(validate()){
           // console.log(payload_data);
           props.create_territory(payload_data,props.history);
           }
    }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Add Territory</h1>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="territoryname">Territory Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control mb-0" 
                                        placeholder="Enter Territory Name"
                                        value={territoryName}
                                        onChange={(e) => setTerritoryName(e.target.value)}
                                        />
                                        {error.territoryNameErr ? <span className="error-span">{error.territoryNameErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                    <label htmlFor="city">Select State</label>
                                    <select
                                        className='form-control mb-0'
                                        placeholder='Select State'
                                        options={stateCodeList}
                                        onChange={(e) => stateChangeHandler(e)}
                                    >
                                        <option value="">Please Select State</option>
                                        {states && states.map((item,i) => {
                                            return <option key={i} value={item.stateCode}>{item.stateName}</option>})}
                                    </select>
                                   {error.stateNameErr ? <span className="error-span">{error.stateNameErr}</span>:null}

                                    </div>
                                </div>
                            </div>
                            {isShow ? <>
                            <div className="row">
                                
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="city">Select City</label>
                                <MultiSelect options={options || []} value={citiesList} placeholder="Select City" onChange={setCityZipcode} />
  
                                {error.citiesListErr ? <span className="error-span">{error.citiesListErr}</span>:null}

                                    </div>
                                </div>
                            <div className="col-md-6">
                                    <div className="form-group">
                                    <label htmlFor="zipcode">Select Zipcode</label>
                                    <MultiSelect options={zipcodeList||[]} value={updateCitieszipcodeList || []} placeholder="Select Zipcode" onChange={setZipcodeData} />
  
                                {error.zipcodeListErr ? <span className="error-span">{error.zipcodeListErr}</span>:null}
                                    
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <BootstrapSwitchButton
                                            checked={isActive}
                                            onlabel='Active'
                                            offlabel='Inactive'
                                            onChange={toggleActiveHandler}
                                            width={100} 
                                        />
                                    </div>
                                </div>
                                </div>
                                
                                </>:null}
                        <button className="btn btn-primary" onClick={addTerritoryHandler}>Add Territory</button> 
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

export default connect(null,{getall_state_codes,getall_cities_zipcodes,create_territory,closeAlert})(withRouter(CreateTerritory));
//export default CreateTerritory;