import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import productReducer from './productAdmin/productReducer';
import companyReducer from './companyAdmin/companyReducer';
import companyUserReducer from './companyUsers/companyUsersReducer';
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['Product','Company','Login','CompanyUser']
}

const rootReducer = combineReducers({
  Login: loginReducer,
  Product: productReducer,
  Company: companyReducer,
  CompanyUser: companyUserReducer,
})

export default persistReducer(persistConfig, rootReducer);
// export default  rootReducer;