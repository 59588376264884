import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {company_users_Campaign_Listing,storeUserCampaignId,updateCompanyUserCampaignData,changeCampaignStatusByID,get_properities_by_campagin_id,deleteUserCampaignId,dupilcateUserCampaignId,closeAlert} from '../../../redux/action/companyUser/companyUserActionCreator';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Loader from '../../../Components/common/Loader';
import MyVerticallyCenteredModal from '../../../Components/common/Modal/modal';
import Dropdown from 'react-bootstrap/Dropdown'

const CampaignList = (props) =>{
    const [companyUsersCampaignList, setCompaniesUserCampaignList] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [loaderShow, setLoader] = useState(true);
    const [showDeleting, setShowDeleting] = useState(false);
    const [campaignId, setCampaignId] = useState(0);
    const [campaignName, setCampaignName] = useState('');
    const [disable, setDisable] = useState(false);

    useEffect(()=>{
        // setTimeout(() => {})
            props.company_users_Campaign_Listing().then(result => {
                setCompaniesUserCampaignList(result);
                    // console.log('hi');
                    setLoader(false);
                });
                props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

    //change status
    const statusChangeHandler = (e,statusType,campId) =>{
        e.preventDefault();
        props.updateCompanyUserCampaignData("status",statusType);
        const payload_data={
          campaignId: campId,
          campaignStatusEnum: statusType
        } 
        props.changeCampaignStatusByID(payload_data);
        if(statusType === "ACTIVE"){
          setDisable(true)
        }
        if(statusType === "STOP" || statusType === "DRAFT"){
          setDisable(false)
        }
  
      }

    let routeVal=";"
    const viewUserCampaignHandler = (e,campaignId) => {
        let payload_data={
            campaignId:parseInt(campaignId),
            page: 0,
            size: 50
          };
        props.get_properities_by_campagin_id(payload_data);
         routeVal= "View";
        props.storeUserCampaignId(campaignId,props.history,routeVal);
       
     }

    const editUserCampaignHandler = (e,campaignId) => {
        routeVal= "Edit";
       props.storeUserCampaignId(campaignId,props.history,routeVal);
    }

    const deleteUserCampaignHandler = (e,campaignId) => {
        setShowDeleting(true);
        props.deleteUserCampaignId(campaignId);
    }

    const deleteHandler = (e,id)=>{
        e.preventDefault();
        setModalShow(true);
        setShowDeleting(false);
        setCampaignId(id);
        // console.log(id)
        let campaignDetails ='';
        campaignDetails = companyUsersCampaignList && companyUsersCampaignList.filter(item => item.id === parseInt(id));
        setCampaignName(campaignDetails[0].campaign_name);
    }

    const duplicateUserCampaignHandler = (e,campaignId) => {
        props.dupilcateUserCampaignId(campaignId);
    }
    
    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main" >
                    <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Campaign</h1>
                        </div>
                        <div className="short d-flex align-self-center">
                           <Link to={'/AddCampaign'} className="btn btn-primary">Add New Campaign</Link>
                        </div>
                    </div>
                    <Alert id="alert" className="alert" variant={props.campaginAlert.resultType} show={props.campaginAlert.show} 
                    onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.campaginAlert.msg}</p>
                    </Alert>
                    {loaderShow ? <Loader/>:
                    companyUsersCampaignList.length === 0 ?( <Card>
                    <h1 style={{textAlign:'center',padding:'100px'}}>No campaign available, please <Link to={'/AddCampaign'} style={{color:'#179bee'}}>  click here</Link> to create campaign</h1>
                    </Card>):<div className="all-agent properties-table ">
                    {companyUsersCampaignList && companyUsersCampaignList.map(item=>{
                      //  console.log(item);
                                    return (
                        <Card key={item.id} style={{margin: '10px'}}>
                            <Card.Header as="h5">
                                {item.campaign_name}
                                <Dropdown className="d-inline mx-2" autoclose="inside" style={{float:'right'}}>
                                    <Dropdown.Toggle id="dropdown-autoclose-inside" className="dropdown-campaign-toggle" style={{backgroundColor: 'white !important',borderColor: '#179bee !important'}}>
                                    <h1 className="cmap-dots" >...</h1>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    <Dropdown.Item  onClick={(e) => viewUserCampaignHandler(e,item.id)}>View Details</Dropdown.Item>
                                    <Dropdown.Item  onClick={(e) => editUserCampaignHandler(e,item.id)}>Edit</Dropdown.Item>
                                    <Dropdown.Item  onClick={(e) => deleteHandler(e,item.id)}>Delete</Dropdown.Item>
                                    <Dropdown.Item  onClick={(e) => duplicateUserCampaignHandler(e,item.id)}>Duplicate</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    subterritoryName={campaignName}
                                    deleteText={showDeleting}
                                    deleteSubterritory={(e)=>deleteUserCampaignHandler(e,campaignId)}
                                />
                                {/* <div className="example-jsx">
                                    <div className="block">
                                        <Link to="#" data-for="soclose" data-tip={item.id} style={{fontSize: '35px',marginTop: '-27px'}}>
                                            . . .
                                        </Link>
                                    </div>
                                        <ReactTooltip
                                            key={item.id}
                                            id="soclose"
                                            getContent={dataTip => (
                                            <React.Fragment key={item.id}>
                                                <p>{dataName => dataName}</p>
                                                    <p><Link to="#"  onClick={(e) => viewUserCampaignHandler(e,dataTip)}>View Details</Link></p>
                                                    <p><Link to="#"  onClick={(e) => editUserCampaignHandler(e,dataTip)}>Edit</Link></p>
                                                    <p><Link to="#" onClick={(e) => deleteHandler(e,dataTip)}>Delete</Link></p>
                                                    <p><Link to="#" onClick={(e) => duplicateUserCampaignHandler(e,dataTip)}>Duplicate</Link></p>
                                                   
                                            </React.Fragment>
                                            )}
                                            effect="solid"
                                            delayHide={500}
                                            delayShow={500}
                                            delayUpdate={500}
                                            place="right"
                                            border={true}
                                            type="light"
                                        />
                                        </div>
                                         */}
                                <br />
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>
                                    <div className="row">

                                    <div className="col-lg-2">
                                        <p><small>Status:</small> {item.status}</p>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-sts" disabled={item.status === "ACTIVE" ? !disable:null} onClick={(e)=>statusChangeHandler(e,"ACTIVE",item.id)}>ACTIVE</button> 
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-sts" disabled={item.status === "ACTIVE" ? disable: item.status !== "ACTIVE" ? !disable:null} onClick={(e)=>statusChangeHandler(e,"STOP",item.id)}> Stop </button> 
                                        </div>
                                    </div>
                                    <div className="col-lg-6" style={{textAlign:'end'}}>
                                        <p><small>Email:</small> {item.mailBox.emailAddress}</p>
                                    </div>
                                    </div>
                                    <div className="row">
                                    {/*<div className="col-lg-2" style={{textAlign:'center'}}>
                                         <h1 className="camp-txt">{item.numberOfLeads || '0'}</h1> Leads
                                        </div>*/}
                                    <div className="col-lg-3" style={{textAlign:'center'}}>
                                         <h1 className="camp-txt">{item.numberOfMessagesSent || '0'}</h1> Messages Sent
                                    </div>
                                    <div className="col-lg-3" style={{textAlign:'center'}}>
                                         <h1 className="camp-txt">{item.numberOfProperties || '0'}</h1> Properties
                                    </div>
                                    <div className="col-lg-3" style={{textAlign:'center'}}>
                                         <h1 className="camp-txt">{item.numberOfContacts || '0'}</h1> Contacts
                                    </div>
                                    <div className="col-lg-3" style={{textAlign:'center'}}>
                                         <h1 className="camp-txt">{item.doNotContact || '0'}</h1>Do not Contact
                                    </div>
                                    </div>
                                </Card.Title>
                            </Card.Body>
                    
                        </Card>)}
                        )}
                     </div>}
                    {/* {companyUsersCampaignList !== '' ?
                    :<Loader/>} */}
                     
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 

    );
} 

const mapStateToProps = state => {
    return {
      campaginAlert: state.CompanyUser.alert,
    }
}

export default connect(mapStateToProps,{company_users_Campaign_Listing,storeUserCampaignId,get_properities_by_campagin_id,updateCompanyUserCampaignData,changeCampaignStatusByID,deleteUserCampaignId,dupilcateUserCampaignId,closeAlert})(CampaignList);