import React,{useEffect,useState} from 'react';
import { connect } from 'react-redux';
import {get_dashboard_data,closeAlert} from '../../redux/action/companyUser/companyUserActionCreator';
import Alert from 'react-bootstrap/Alert';
// import { useDispatch } from 'react-redux';
import Loader from '../common/Loader';
import { Link } from 'react-router-dom';

const Dashboard = (props) =>{
    const [companyUsersDashboardList, setCompaniesUserDashboardList] = useState('');
    // const dispatch = useDispatch();

    useEffect(()=>{
        props.get_dashboard_data().then(result => {
            setCompaniesUserDashboardList(result);
            
            });
            props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 
    // useEffect(()=>{
    //         dispatch(closeAlert(false));
    // },[dispatch]); 
    return (
        <div className="tl-section">
            <div className="tl-dashboard bg-color">
                
            
                <div className="container-fluid p-0">
                        <div className="main">
                            <div className="main-title">
                                <h1 className="db-title">Overview</h1>
                                <Alert id="alert" className="alert" variant={props.campaginAlert.resultType} show={props.campaginAlert.show} 
                                    onClose={() => props.closeAlert(false)} dismissible
                                    >
                                    <p>{props.campaginAlert.msg}</p>
                                </Alert>
                            </div>
                            {companyUsersDashboardList !== ''  ? <React.Fragment>
                            <div className="row">

                                <div className="col-sm-6 col-md-4">
                                    <div className="db-box box-1">
                                        <div className="db-box-inner">
                                            <h4>Campaigns</h4>
                                            <span className="tl-border"><img src="assets/images/db/border.png" alt="Img" className="img-fluid" /></span>
                                            <h3 style={{color: 'green'}}>{companyUsersDashboardList &&  companyUsersDashboardList.numberOfCampaigns}</h3>
                                            {/* <span>Responses - 29.4%</span>
                                            <span>Messages Sent - 1.9%</span> */}
                                           
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-sm-6 col-md-4">
                                    <div className="db-box box-2">
                                        <div className="db-box-inner">
                                            <h4>Territories</h4>
                                            <span className="tl-border"><img src="assets/images/db/border.png" alt="Img" className="img-fluid" /></span>
                                            <h3 style={{color: 'orange'}}>{companyUsersDashboardList && companyUsersDashboardList.numberOfTerritories}</h3>
                                            {/* <span>Responses - 0.0%</span>
                                            <span>Messages Sent - 0%</span> */}
                                            
                                        </div>
                                    </div>
                                </div>
                                
                               {/*<div className="col-sm-6 col-md-4">
                                    <div className="db-box box-3">
                                        <div className="db-box-inner">
                                            <h4>Leads</h4>
                                            <span className="tl-border"><img src="assets/images/db/border.png" alt="Img" className="img-fluid" /></span>
                                            <h3 style={{color: 'green'}}>{companyUsersDashboardList && companyUsersDashboardList.numberOfLeads}</h3>
                                             <span>Responses - 45.4%</span>
                                            <span>Messages Sent - 4.9%</span> 
                                            
                                        </div>
                                    </div>
                                </div>
                            */}

                                <div className="col-sm-6 col-md-4">
                                    <div className="db-box box-2">
                                        <div className="db-box-inner">
                                            <h4>Properties</h4>
                                            <span className="tl-border"><img src="assets/images/db/border.png" alt="Img" className="img-fluid" /></span>
                                            <h3 style={{color: 'orange'}}>{companyUsersDashboardList && companyUsersDashboardList.numberOfProperties}</h3>
                                            {/* <span>Responses - 0.0%</span>
                                            <span>Messages Sent - 0%</span> */}
                                            
                                        </div>
                                    </div>
                                </div>
                             
                              </div>
                            <div className="row">

                                <div className="col-sm-6 col-md-4">
                                    <div className="db-box box-3">
                                        <div className="db-box-inner">
                                            <h4>Contacts</h4>
                                            <span className="tl-border"><img src="assets/images/db/border.png" alt="Img" className="img-fluid" /></span>
                                            <h3 style={{color: 'green'}}>{companyUsersDashboardList && companyUsersDashboardList.numberOfAgents}</h3>
                                            {/* <span>Responses - 45.4%</span>
                                            <span>Messages Sent - 4.9%</span> */}
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4">
                                    <div className="db-box box-3">
                                        <div className="db-box-inner">
                                            <h4>Do not Contact</h4>
                                            <span className="tl-border"><img src="assets/images/db/border.png" alt="Img" className="img-fluid" /></span>
                                            <h3 style={{color: 'red'}}>{companyUsersDashboardList && companyUsersDashboardList.doNotContactCount}</h3>
                                            {/* <span>Responses - 29.4%</span>
                                            <span>Messages Sent - 1.9%</span> */}
                                            
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                            </React.Fragment>:<Loader/>}
                            <div className="copy-right text-center">
                                <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                            </div>
                    </div>
                    </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
      campaginAlert: state.CompanyUser.alert,
    }
}

export default connect(mapStateToProps,{get_dashboard_data,closeAlert})(Dashboard);
