import React, {  useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {get_all_compnay_subterritories,deleteSubTerritoryById,storeTerritoryId,closeAlert} from '../../../redux/action/compnayAdmin/companyActionCreator';
import Alert from 'react-bootstrap/Alert';
import MyVerticallyCenteredModal from '../../../Components/common/Modal/modal';
import Loader from '../../../Components/common/Loader';

const SubTerritoriesList = (props) =>{
    const [subTerritoryList, setSubTerritoriesList] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [subTerritoryId, setSubTerritoryId] = useState(0);
    const [territoryName, setTerritoryName] = useState('');
    const {companySubTerritoryDTOS}= subTerritoryList || {};
    const [showDeleting, setShowDeleting] = useState(false);

    useEffect(()=>{
        props.get_all_compnay_subterritories().then(result => {
            setSubTerritoriesList(result);
            });
            
            props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const deleteSubterritoryHandler = (e,id) =>{
        setShowDeleting(true);
        props.deleteSubTerritoryById(id);
    }

    const deleteHandler = (e,id,territoryName)=>{
        setModalShow(true);
        setShowDeleting(false);
        setSubTerritoryId(id);
        setTerritoryName(territoryName);
    }

    const viewTerritoryHandler = (e,territoryId) => {
        props.storeTerritoryId(territoryId,props.history);
        
    }

    return(<React.Fragment>
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Sub-Territories</h1>
                            
                        </div>
                        <div className="short d-flex align-self-center">
                           <Link to={'/AddSubTerritory'} className="btn btn-primary">Add New Sub Territory</Link>
                        </div>
                        
                    </div>
                    <Alert id="alert" className="alert" variant={props.alertData.resultType} show={props.alertData.show} 
                                onClose={() => props.closeAlert(false)} dismissible
                                >
                                <p>{props.alertData.msg}</p>
                                </Alert>
                    {companySubTerritoryDTOS ? 
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">SubTerritory Name</th>
                                    <th scope="col">Territory Location</th>
                                    <th scope="col">Action</th>
                                    {/* <th scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {companySubTerritoryDTOS && companySubTerritoryDTOS.map(item=>{
                                    return (
                                        <tr key={item.id}>
                                            <td className="agent-name">
                                                <h2><Link to="#" onClick={(e)=>viewTerritoryHandler(e,item.id)}>{item.territoryName}</Link></h2>
                                            </td>
                                            
                                            <td className="agent-status">
                                            {item.zipcode && item.zipcode.slice(0, 2).map((item,i) => {
                                                return ( 
                                                        <Link to="#" key={i}><i className="fa fa-map-marker " aria-hidden="true"></i> {item.zipCode}</Link>
                                                        )})}
                                                 {item.zipcode.length > 2 ? <Link to="#"  style={{color: '#179bee'}} onClick={(e)=>viewTerritoryHandler(e,item.id)}><i className="fa fa-link" aria-hidden="true"></i>More...</Link>:null} 
                                            
                                            {/* {item.zipcode.map(cityandZipcode => {
                                                return ( 
                                                        <Link to="#" key={cityandZipcode}><i className="fa fa-map-marker " aria-hidden="true"></i> {cityandZipcode.zipCode}</Link>
                                                       
                                                        )} )} */}
                                             </td>
                                            <td >
                                                {/* <Link to="#" onClick={(e)=>deleteSubterritoryHandler(e,item.id)}><i className="fa fa-trash"></i></Link> */}
                                                <Link to="#" onClick={(e) => deleteHandler(e,item.id,item.territoryName)}><i className="fa fa-trash"></i></Link>
                                            </td>
                                             <MyVerticallyCenteredModal
                                                    show={modalShow}
                                                    onHide={() => setModalShow(false)}
                                                    subterritoryName={territoryName}
                                                    deleteText={showDeleting}
                                                    deleteSubterritory={(e)=>deleteSubterritoryHandler(e,subTerritoryId)}
                                                />
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                    </div>
                    :<Loader/>}
                    
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 
           
                    </React.Fragment>
    );
} 


const mapStateToProps = state => {
    return {
      alertData: state.Company.alert,
      //territoryDetails: state.Company.companyTerritories,
    }
  }

export default connect(mapStateToProps,{get_all_compnay_subterritories,storeTerritoryId,deleteSubTerritoryById,closeAlert})(SubTerritoriesList);