import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {closeAlert } from '../../../redux/action/compnayAdmin/companyActionCreator';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import Alert from 'react-bootstrap/Alert';
import DrawMap from './map'
import Geocode from 'react-geocode';


const ViewSubTerritory = (props) =>{
    const [mapOpen, setMapOpen] = useState(false);
    const [mapLatLong, setMapLatLong] = useState();

    useEffect(()=>{
      Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
      Geocode.fromAddress(props.subTerritoryDetails.zipCodeDTOS[0].zipCode).then(
       (response) => {
               const data = response.results[0].geometry.location;
               setMapLatLong({lat: data.lat,lng: data.lng});
               setMapOpen(true);
                  },
       (error) => {
         setMapLatLong({lat:-34.397, lng:150.644});
       });
       
       props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  

  //company territories
//   const  {companyTerritories}  = territoriesList;
  const  options  = props.subTerritoryDetails && props.subTerritoryDetails.zipCodeDTOS.map(item => {
    //console.log(item);
    const val= { id:item.id,label: item.zipCode,value: item.zipCode,boundaries: item.boundaries};
    return val;
    });

  //company users
  const  userOptions  = props.subTerritoryDetails && props.subTerritoryDetails.usersList.map(item => {
    const val= {  id: item.id, firstName: item.firstName,lastName: item.lastName,users:  item.firstName +' ' +item.lastName,label: item.firstName +' ' +item.lastName,value: item.firstName +' ' +item.lastName};
    return val;
  });

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">View SubTerritory</h1>
                    <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show} 
                     onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.companyalert.msg}</p>
                    </Alert>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="territory">Territory Name</label>
                                        <input 
                                             className="form-control"
                                             value={props.subTerritoryDetails.companyTerritoryDTO.territoryName || ''}
                                             disabled={true}
                                             style={{backgroundColor: '#ccc',cursor: 'not-allowed'}}
                                        />
                                    {/* <select
                                        className="form-control"
                                        value={props.subTerritoryDetails.companyTerritoryDTO.territoryName || ''}
                                        disabled={true}
                                        style={{backgroundColor: '#ccc',cursor: 'not-allowed'}}
                                    >
                                      {/* <option value="0">Assign Territory</option>    
                                           {options} 
                                        </select> */}
                                {/* {error.userTerritoryErr ? <span className="error-span">{error.userTerritoryErr}</span>:null} */}
                                </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                      <label htmlFor="subterritory">Sub Territory Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Enter Territory Name"
                                        value={props.subTerritoryDetails.territoryName}
                                        autoComplete="off"
                                        // onChange={e => setSubTerritory(e.target.value)}
                                        disabled={true}
                                        style={{backgroundColor: '#ccc',cursor: 'not-allowed'}}
                                    />
                                    </div>
                                      
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                      <label htmlFor="zipcodes">Selected Zipcodes</label>
                                    <MultiSelect options={[]} value={options} placeholder="Select Zipcode" className="disabledinpt" isDisabled={true} closeMenuOnSelect={true} isSearchable={false}  />
                                    </div>
                                </div>

                                </div>
                                <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                      <label htmlFor="users">Assigned Users</label>
                                    <MultiSelect options={[]} value={userOptions} placeholder="Assign Users" className="disabledinpt" isDisabled={true} closeMenuOnSelect={true} isSearchable={false} />
                                    </div>
                                </div>
                                </div>
                                {mapOpen ? 
                                <React.Fragment>
                                <label htmlFor="polygon">Assigned Ploygon:</label>
                                <DrawMap
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                  lat={mapLatLong.lat || ''}
                                  lng={mapLatLong.lng || ''}
                                 setpolygonsDTO={props.subTerritoryDetails}
                                 editPolygon={mapOpen}
                                 drawPolygon={!mapOpen}
                                setZipcodeBoundaries={options}
                                /></React.Fragment>:null}
                                <br/>
                            {/* <button className="btn btn-primary" onClick={(e)=>saveSubTerritoryHandler(e,false)}>Save</button> &nbsp;&nbsp;
                            <button className="btn btn-primary" onClick={(e)=>saveSubTerritoryHandler(e,true)}>Save & Add New Territory</button><br/>
                            {inputlistErr ? <span className="error-span">{inputlistErr}</span>:null} */}

                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
  return {
    companyalert: state.Company.alert,
    subTerritoryDetails: state.Company.companySubTerritoryById
  }
}

export default connect(mapStateToProps,{closeAlert})(ViewSubTerritory);