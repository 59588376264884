export const CREATE_COMPANY_USER = "CREATE_COMPANY_USER";
export const CREATE_COMPANY_USER_ERROR = "CREATE_COMPANY_USER_ERROR";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const STORE_COMPNAY_USER_ID = "STORE_COMPNAY_USER_ID";
export const STORE_COMPNAY_USER_ID_ERROR = "STORE_COMPNAY_USER_ID_ERROR";
export const STORE_TERRITORY_ID = "STORE_TERRITORY_ID";
export const STORE_TERRITORY_ID_ERROR = "STORE_TERRITORY_ID_ERROR";
export const UPDATE_COMPANY_USER_DATA = "UPDATE_COMPANY_USER_DATA";
export const UPDATE_COMPANY_USER_TERRITORIES = "UPDATE_COMPANY_USER_TERRITORIES";
export const COMPANY_ADMIN_TERRITORIES = "COMPANY_ADMIN_TERRITORIES";
export const COMPANY_ADMIN_TERRITORIES_ERROR = "COMPANY_ADMIN_TERRITORIES_ERROR";
export const COMPANY_ADMIN_TERRITORIES_INFO = "COMPANY_ADMIN_TERRITORIES_INFO";
export const COMPANY_ADMIN_TERRITORIES_INFO_ERROR = "COMPANY_ADMIN_TERRITORIES_INFO_ERROR";
export const CREATE_COMPANY_MAILBOX = "CREATE_COMPANY_MAILBOX";
export const CREATE_COMPANY_MAILBOX_ERROR = "CREATE_COMPANY_MAILBOX_ERROR";
export const GET_COMPANY_USERS_ERROR = "GET_COMPANY_USERS_ERROR";
export const GET_COMPANY_MAIL_BOXES_ERROR = "GET_COMPANY_MAIL_BOXES_ERROR";
export const UPDATE_COMPNAY_MAIL_BOX_BY_ID = "UPDATE_COMPNAY_MAIL_BOX_BY_ID";
export const UPDATE_COMPNAY_MAIL_BOX_USERLIST_BY_ID = "UPDATE_COMPNAY_MAIL_BOX_USERLIST_BY_ID";
export const GET_COMPNAY_MAIL_BOX_BY_ID = "GET_COMPNAY_MAIL_BOX_BY_ID";
export const GET_COMPNAY_MAIL_BOX_BY_ID_ERROR = "GET_COMPNAY_MAIL_BOX_BY_ID_ERROR";
export const SAVE_COMPANY_SUBTERRITORIES_ERROR = "SAVE_COMPANY_SUBTERRITORIES_ERROR";
export const GET_COMPANY_SUBTERRITORIES_ERROR = "GET_COMPANY_SUBTERRITORIES_ERROR";
export const DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR = "DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR";
export const GET_ADMIN_DASHBOARD_ERROR = "GET_ADMIN_DASHBOARD_ERROR";