import React, { useEffect, useState } from 'react';
import { Link,withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {companyListing, storeCompanyId} from '../../../redux/action/productAdmin/productActionCreator';
import Loader from '../../../Components/common/Loader';

const CompanyListing = (props) =>{
    const [companiesList, setCompaniesList] = useState([]);
    useEffect(()=>{
        props.companyListing().then(result => {
                setCompaniesList(result);
                // console.log('hi');
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const editCompnayHandler = (e,companyId) => {
        props.storeCompanyId(companyId,props.history);
        // setInterval(() => { 
        //     props.history.push('/EditCompany');
        // }, 1000);
        
    }


    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Companies</h1>
                        </div>
                        <div className="short d-flex align-self-center">
                           <Link to={'/AddCompany'} className="btn btn-primary">Add New Compnay</Link>
                        </div>
                    </div>
                    {companiesList ?
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Company Details</th>
                                    <th scope="col">Company Location</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companiesList && companiesList.map(item=>{
                                    return (
                                        <tr key={item.id}>
                                            <td className="agent-name">
                                                <h2>{item.companyName}</h2>
                                            </td>
                                            <td className="agent-status">
                                                <Link to="#"><i className="fa fa-envelope" aria-hidden="true"></i> {item.companyEmail}</Link>
                                                <Link to="#"><i className="fa fa-phone" aria-hidden="true"></i> {item.phoneNumber1}/{item.phoneNumber2}</Link>
                                            </td>
                                            <td className="agent-status">
                                                <Link to="#" style={{display: 'flex'}}><i className="fa fa-home fa-fw" aria-hidden="true"></i> {item.address1}<br/>{item.city}<br/>{item.state}<br/>{item.zipCode}</Link>
                                            </td>
                                            <td >
                                                <p>{item.isActive ? 'Active':'Inactive'}</p>
                                            </td>
                                            <td className="property-option">
                                                <div className="db-action">
                                                    <Link className="edit btn"  onClick={(e) => editCompnayHandler(e,item.id)}><span><i className="fa fa-pencil-square-o" aria-hidden="true"></i></span></Link>
                                                   </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                    </div>:<Loader />}
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 

    );
} 

// export default CompanyListing;
export default connect(null,{companyListing,storeCompanyId})(withRouter(CompanyListing));