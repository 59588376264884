import {  ALL_STATECODES_ERROR,CREATE_COMPANY_ERROR,CREATE_TERRITORY_ERROR,UPDATE_COMPANY_TERRITORY_LIST,UPDATE_COMPANY_TERRITORY_DETAILS,STORE_COMPNAY_ID,STORE_TERRITORY_ID,GET_COMPNAY_BY_ID_ERROR,CREATE_COMPANY,UPDATE_COMPANY_DATA,UPDATE_COMPANY_TERRITORY_DATA,UPDATE_COMPANY_ADMIN_DATA,CLOSE_PRODUCT_ADMNI_ALERT,ADD_ZIPCODES_ERROR } from './productActionTypes';
import { API } from "../../services/APIBuilder";
import {ALL_STATECODES_URL,CITYS_ZIPCODES_URL,GET_TERRITORY_URL,CREATE_COMPNAY_URL,COMPNAY_LIST_URL,CREATE_TERRITORY_URL,TERRITORY_LIST_URL,TERRITORY_ACTIVE_LIST_URL,GET_COMPNAY_URL,ADD_ZIPCODES_URL} from '../../../ApiServices';
import { getSession } from '../../Auth/sessionData';

export const getall_cities_zipcodes = (payload_data) => async dispatch => {
  try { 
    const session_data=getSession();
    let city_zipcode_List= await API.get(CITYS_ZIPCODES_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
             // ;
              dispatch({
                  type: CREATE_COMPANY_ERROR,
                  payload: 'Invalid Credentials, Please enter valid details'
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: CREATE_COMPANY_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: CREATE_COMPANY_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: CREATE_COMPANY_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: CREATE_COMPANY_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return city_zipcode_List;
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const getall_state_codes = () => async dispatch => {
  try {
    let state_code_List= await API.get(ALL_STATECODES_URL)
    .then(result => {
        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
             // ;
              dispatch({
                  type: ALL_STATECODES_ERROR,
                  payload: 'Invalid Credentials, Please enter valid details'
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: ALL_STATECODES_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: ALL_STATECODES_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: ALL_STATECODES_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: ALL_STATECODES_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return state_code_List;
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const create_company = (payload_data,history) => async dispatch => {
    try {
        const session_data=getSession();
      API.post(CREATE_COMPNAY_URL,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {
        // dispatch({
        //     type: CREATE_COMPANY,
        //     payload: result.data
        // });
          history.push('/CompanyList');
        }
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
               // ;
                dispatch({
                    type: GET_COMPNAY_BY_ID_ERROR,
                    payload: err.response.data.msg
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: GET_COMPNAY_BY_ID_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 403) {
            //;
            dispatch({
                type: GET_COMPNAY_BY_ID_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 404) {
            //;
            dispatch({
                type: GET_COMPNAY_BY_ID_ERROR,
                payload: err.response.data.msg
            })
        }  else if (err.response.status === 500) {
                dispatch({
                    type: GET_COMPNAY_BY_ID_ERROR,
                    payload: "Internal error"
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: GET_COMPNAY_BY_ID_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: GET_COMPNAY_BY_ID_ERROR,
                payload: "Something went wrong"
            })
          }
        });
    
    
       
    }
    catch (e) {
        dispatch({
            type: GET_COMPNAY_BY_ID_ERROR,
            payload: "Something went wrong",
        })
    }

}

export const get_compnay = (payload_data) => async dispatch => {
  try {
      const session_data=getSession();
    let companyDetails=await API.get(GET_COMPNAY_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
      dispatch({
        type: CREATE_COMPANY,
        payload: 'success'
    });
          return result.data;
          
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: CREATE_COMPANY_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: CREATE_COMPANY_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
      return companyDetails;
     
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const companyListing = () => async dispatch => {
  try {
      const session_data=getSession();
    let companyList=await API.get(COMPNAY_LIST_URL,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
          return result.data.companyDTOS;
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: CREATE_COMPANY_ERROR,
                payload: 'Something went wrong'
            })
              
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: CREATE_COMPANY_ERROR,
              payload: 'Something went wrong'
          })
            
            
        } else if (err.response.status === 500) {
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: 'Something went wrong'
        })
          
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: "Something went wrong",
        })
        } else {
          // anything else
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: "Something went wrong",
        })
        }
      });
  
      return companyList;
     
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_ERROR,
          payload: "Something went wrong",
      })
  }

}


export const create_territory = (payload_data,history) => async dispatch => {
  try {
      const session_data=getSession();
    API.post(CREATE_TERRITORY_URL,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
        history.push('/TerritoryList');
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
             // ;
              dispatch({
                  type: CREATE_TERRITORY_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            dispatch({
                type: CREATE_TERRITORY_ERROR,
                payload: err.response.data.msg
            })
        } else if (err.response.status === 403) {
          dispatch({
              type: CREATE_TERRITORY_ERROR,
              payload: err.response.data.msg
          })
      }else if (err.response.status === 500) {
              dispatch({
                  type: CREATE_TERRITORY_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: CREATE_TERRITORY_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: CREATE_TERRITORY_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: CREATE_TERRITORY_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const territoryListing = () => async dispatch => {
  try {
      const session_data=getSession();
    let territoryList=await API.get(TERRITORY_LIST_URL,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
          return result.data;
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              return 'Authorization failed';
          }else if (err.response.status === 401) {
            //;
            return 'Authorization failed';
            
        } else if (err.response.status === 500) {
              
          return 'Something went wrong';
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          
          return 'Something went wrong';
        } else {
          // anything else
          
          return 'Something went wrong';
        }
      });
  
      return territoryList;
     
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const activeterritoryListing = () => async dispatch => {
  try {
      const session_data=getSession();
    let territoryList=await API.get(TERRITORY_ACTIVE_LIST_URL,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
          return result.data;
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              return 'Authorization failed';
          }else if (err.response.status === 401) {
            //;
            return 'Authorization failed';
            
        } else if (err.response.status === 500) {
              
          return 'Something went wrong';
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          
          return 'Something went wrong';
        } else {
          // anything else
          
          return 'Something went wrong';
        }
      });
  
      return territoryList;
     
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const storeCompanyId = (payload_data,history) => async dispatch => {
   
  try {
    const session_data=getSession();
    await API.get(GET_COMPNAY_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
      
          dispatch({
            type: STORE_COMPNAY_ID,
            payload: result.data,

        });
        history.push('/EditCompany');
         // return result.data;
          
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: CREATE_COMPANY_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: CREATE_COMPANY_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_ERROR,
          payload: 'Something went wrong',
      })
  }

}

export const storeTerritoryId = (payload_data,history) => async dispatch => {
   
  try {
    const session_data=getSession();
    await API.get(GET_TERRITORY_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
      
          dispatch({
            type: STORE_TERRITORY_ID,
            payload: result.data,

        });
        history.push('/EditTerritory');
         // return result.data;
          
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: CREATE_COMPANY_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: CREATE_COMPANY_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: CREATE_COMPANY_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_ERROR,
          payload: 'Something went wrong',
      })
  }

}

export const updateCompanyData = (name, value) => async dispatch => {
  //;
  dispatch({
      type: UPDATE_COMPANY_DATA,
      payload: {
          name,
          value
      },
  })
}

export const updateCompanyAdminData = (name, value) => async dispatch => {
  //;
  dispatch({
      type: UPDATE_COMPANY_ADMIN_DATA,
      payload: {
          name,
          value
      },
  })
}


export const updateTerritoryList = (payload_data) => async dispatch => {
  dispatch({
      type: UPDATE_COMPANY_TERRITORY_DATA,
      payload: payload_data,
  })
}

export const updateTerritoriesList = (payload_data) => async dispatch => {
  dispatch({
      type: UPDATE_COMPANY_TERRITORY_LIST,
      payload: payload_data,
  })
}

export const closeAlert = (payload_data) => async dispatch => {
  try{
    dispatch({
      type: CLOSE_PRODUCT_ADMNI_ALERT,
      payload: payload_data,
  })
  }
  catch (e) {
      dispatch({
          type: CLOSE_PRODUCT_ADMNI_ALERT,
          payload: payload_data
      })
  }
}

export const updateCompanyTerritoryData = (name, value) => async dispatch => {
  //;
  dispatch({
      type: UPDATE_COMPANY_TERRITORY_DETAILS,
      payload: {
          name,
          value
      },
  })
}

//save zipcodes
export const save_zipcodes = (payload_data,history) => async dispatch => {
  try {
      const session_data=getSession();
    API.post(ADD_ZIPCODES_URL,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
        history.push('/zipcodeList');
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              dispatch({
                  type: ADD_ZIPCODES_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            dispatch({
                type: ADD_ZIPCODES_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 403) {
          dispatch({
              type: ADD_ZIPCODES_ERROR,
              payload: err.response.data.msg
          })
      }else if (err.response.status === 404) {
          dispatch({
              type: ADD_ZIPCODES_ERROR,
              payload: err.response.data.msg
          })
      }  else if (err.response.status === 500) {
              dispatch({
                  type: ADD_ZIPCODES_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: ADD_ZIPCODES_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: ADD_ZIPCODES_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: ADD_ZIPCODES_ERROR,
          payload: "Something went wrong",
      })
  }

}