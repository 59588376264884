/* global google */
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  Marker
} from "react-google-maps";

import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";


const DrawMap = (props) => {
  const cordst = props.selectedpolygonsDTO;

  // const OPTIONS = {
  //   minZoom: 4,
  //   // maxZoom: 18,
  // }
  const zipcordslet = props.setZipcodeBoundaries && props.setZipcodeBoundaries.map(companySubterritory => {
    return companySubterritory.boundaries.map(polygon => polygon.coordinates.map(coordinate => {
      return {
              lng : parseFloat(coordinate.longitude),
              lat : parseFloat(coordinate.latitude)
            }
    }))});
  return <GoogleMap
    defaultZoom={11}
    defaultCenter={new google.maps.LatLng(props.lat, props.lng)}

    // options={OPTIONS}
  >
    {props.properitiesCoordinates ?
      props.properitiesCoordinates.map((item, i) => {
        return <Marker
          position={item}
          title={item.title}
          key={i}
        />
      })
      : null}

    {props.editPolygon ?
      cordst.map((item, i) => {
        return <Polygon
          path={item}
          key={i}
          options={{
            strokeColor: "black",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#179bee78",
            fillOpacity: 0.35
          }}
        />
      })
      : null}

    {props.setZipcodeBoundaries ? 
         zipcordslet.map((item,i) => {
          return item.map(latLng =>{
             
           //console.log(latLng);
            return <Polygon
            path={latLng}
            key={i}
            options={{
              strokeColor: "red",
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillColor: "transparent",
              fillOpacity: 0.35
            }}
          />
          })
         })
         :null}

    <DrawingManager
      // defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
      defaultOptions={{
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            // google.maps.drawing.OverlayType.POLYGON,
          ],
        },
        polygonOptions: {
          fillColor: `#0707073b`,
          fillOpacity: 1,
          strokeWeight: 2,
          zIndex: 1,
        },
      }}
    />
  </GoogleMap>

}


export default withScriptjs(withGoogleMap(DrawMap));

