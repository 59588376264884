import React from 'react';
import { Link } from 'react-router-dom';
import { getSession } from '../../../redux/Auth/sessionData';
import { connect } from 'react-redux';
import { unsetLogin } from '../../../redux/action/accountAction/logoutAction';

const Header = (props) =>{
    const sessions_data = getSession(); 

    return(
        <React.Fragment>
            {sessions_data && sessions_data.userType !== '' ? <header className="tl-header header-style-1">
            <div className="tl-menu menu-absolute menu-sticky"></div></header>:
            <header className="tl-header header-style-1">
            <div className="tl-menu menu-absolute menu-sticky">
                <nav className="navbar navbar-expand-lg p-0" >
                    <div className="container">
                        <Link className="navbar-brand" to={'/'} style={{display: 'inline-block'}}>
                            <img src="assets/images/clogo.jpg" alt="Logo" className="img-fluid" style={{width:'90%'}} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fa fa-align-justify"></i></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                            <ul className="navbar-nav">
                                <li><Link to={'/'} className="active">Login</Link></li>
                            </ul>       
                        </div> 
                    
                    </div>
                </nav>                                
            </div>            
            </header>
            }
        </React.Fragment>

    );
}


export default connect(null,{unsetLogin})(Header);
// export default Header;