import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLogin } from '../../redux/action/accountAction/loginAction';
import Cookies from 'universal-cookie';
import Footer from '../../Components/common/Footer/footer';
import Loader from "react-js-loader";

const Login = (props) => {
    const cookies = new Cookies();
    const email = cookies.get('username');
    const password = cookies.get('password');
    const rememberMeCheck = cookies.get('rememberMe');
    const [rememberMe, setRememberMe] = useState(rememberMeCheck);
    const [UserName, setUserName] = useState(email);
    const [Password, setPassword] = useState(password);
    const [UserNameErr, setUserNameErr] = useState("");
    const [PasswordErr, setPasswordErr] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [loaderShow, setLoader] = useState(false);

    const rememberChangeHandler = (e) => {
        setRememberMe(!rememberMe);
    }

    const validate = (e) => {
        if (!UserName && !Password) {
            setUserNameErr('Please Enter Email');
            setPasswordErr('Password should not be empty');
            return false;
        }
        if (!UserName) {
            setUserNameErr('Please Enter Email');
            return false;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(UserName)) {
            setUserNameErr('Invalid email address');
            return false;
        }
        if (UserName && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(UserName)) {
            setUserNameErr('');
        }
        if (!Password) {
            setPasswordErr('Password should not be empty');
            return false;
        }
        if (Password) {
            setPasswordErr('');
        }
        return true;
    }

    const loginHandler = async (e) => {
        e.preventDefault();
        setIsActive(false);
        // console.log('hi');
        if (rememberMe) {
            cookies.set('username', UserName);
            cookies.set('password', Password);
            cookies.set('rememberMe', rememberMe);
        }
        else {
            cookies.remove('username');
            cookies.remove('password');
            cookies.remove('rememberMe');
        }
        if (validate() && isActive) {
            setLoader(true);
            props.setLogin(UserName, Password, props.history).then(result => {
                console.log(result);
                setLoader(false);
            });
        }
    };

    return (<React.Fragment>
        <div className="lt-breadcrumb">
            <div className="breadcrumb-content">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div className="title">
                            <h1>Login</h1>
                        </div>
                        <ol className="breadcrumb align-self-center">
                            <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Login</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div className="lt-page-content bg-color">
            <div className="lt-section">
                <div className="section-content lt-account section-padding">
                    <div className="account-content bg-white">
                        <h2>Login Into Account</h2>
                        <form action="login.html" className="lt-form">

                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email Address"
                                    value={UserName || ''}
                                    onChange={e => { setUserName(e.target.value); setIsActive(true) }}
                                />
                                <div className="input-addon">
                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                </div>
                                {UserNameErr ? <span className="error-span">{UserNameErr}</span> : null}
                            </div>

                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    value={Password || ''}
                                    onChange={e => { setPassword(e.target.value); setIsActive(true) }}
                                />
                                <div className="input-addon">
                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                </div>
                                {PasswordErr ? <span className="error-span">{PasswordErr}</span> : null}
                            </div>
                            <div className="form-group tnc">
                                <input type="checkbox" name="tnc" id="tnc" onChange={rememberChangeHandler} checked={rememberMe || false} />
                                <label htmlFor="tnc">Remember Me</label>
                            </div>
                            {!loaderShow ?
                                <>
                                    <button
                                        className={isActive ? "btn btn-primary d-block" : "btn d-block btn-primary disabled"}
                                        onClick={loginHandler}
                                        style={{ width: '-webkit-fill-available' }}
                                    >  Login </button>
                                    {props.loginErr ? <span className="error-span">{props.loginErr}
                                    </span> : null} </> :
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Loader type="spinner-default" bgColor={"#179bee"} title={"Loading...."} color={'#179bee'} size={100} />
                                    </div>
                                </div>
                            }


                            <div className="account-link justify-content-between d-flex">
                                {/* <span>Don’t have an account? <Link to="sign-up.html" className="color">Sign up</Link></span> */}
                                <span><Link to={'/Forgot_Password'}>Forgot Password? </Link></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </React.Fragment>);
}

const mapStateToProps = state => {
    return {
        loginErr: state.Login.error,
    }
};


export default connect(mapStateToProps, { setLogin })(withRouter(Login));