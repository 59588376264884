import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_all_compnay_users,updateCompanyMailBoxData,updateCompanyMailBoxUserList,closeAlert,create_mailbox } from '../../../redux/action/compnayAdmin/companyActionCreator';
import { MultiSelect } from '../../../Components/common/Multiselect/multiSelect';
import Alert from 'react-bootstrap/Alert';

const UpdateEmailBox = (props) =>{
    const [companyUsersList,setCompanyUsersList] =useState([]);
   
   const [error,setError]=useState({
        userNameErr:'',
        // userDisplayErr:'',
        emailErr:'',
        emailbccErr:'',
        emailccErr:'',
        portTypeErr:'',
        hostErr:'',
        protocolErr:'',
        passwordErr:'',
    });

    useEffect(()=>{
        props.get_all_compnay_users().then(result => {
            setCompanyUsersList(result);
            });
            
            props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);


    const  options  = companyUsersList && companyUsersList.map(item => {
      const val= {  id: item.id, firstName: item.firstName,lastName: item.lastName,users:  item.firstName +' ' +item.lastName,label: item.firstName +' ' +item.lastName,value: item.firstName +' ' +item.lastName};
      return val;
    });

    const  selectedOptions  = props.mailboxDetails && props.mailboxDetails.selectedUsers.map(item => {
        const val= {  id: item.id, firstName: item.firstName,lastName: item.lastName,users:  item.firstName +' ' +item.lastName,label: item.firstName +' ' +item.lastName,value: item.firstName +' ' +item.lastName};
        return val;
      });

      const changeUserHandler =(e)=>{
        if(e === []){
          props.updateCompanyMailBoxUserList('');
        }else{
          props.updateCompanyMailBoxUserList(e);
        }
        //props.updateCompanyUserData("mailBoxDTOS",e);
      }
    

    const changeProtocolHandler = (e) =>{
        //setProtocol(e.target.value);
        props.updateCompanyMailBoxData("protocol",e.target.value)
        if(e.target.value === "SMTP"){
          props.updateCompanyMailBoxData("port",587)
        }
        if(e.target.value === "IMAP"){
          props.updateCompanyMailBoxData("port",143)
        }
        if(e.target.value === "0"){
          props.updateCompanyMailBoxData("port",'')
        }
    }

    const validate = (e) => {
        let userNameVal = userNameValidation(props.mailboxDetails.userName);
        // let userDisplayVal = userDisplayValidation(props.mailboxDetails.userDisplayName);
        // let userbccVal = userbccValidation(props.mailboxDetails.bccRecipientName);
        // let userccVal = userccValidation(props.mailboxDetails.ccRecipientName);
        let emailVal = emailValidation(props.mailboxDetails.emailAddress);  
        // let emailbccVal = emailbccValidation(props.mailboxDetails.bccRecipientEmail);  
        // let emailccVal = emailccValidation(props.mailboxDetails.ccRecipientEmail);  
        //let portTypeVal = portValidation(portType);  
        // let portocolVal = portocolValidation(protocol);
        let hostVal = hostValidation(props.mailboxDetails.host);
        let passwordVal = passwordValidation(props.mailboxDetails.password);
        
        if( userNameVal === true &&  emailVal === true  && hostVal === true && passwordVal === true ){
          return true;
        }
      }

      // function userDisplayValidation(displayName) { 
          
      //   if(!displayName) {
      //       setError(prevState => ({
      //           ...prevState,
      //           userDisplayErr: 'Please Enter DisplayName'
      //       }));
      //     return false;
      //   }
      //   // if (!/^[a-zA-Z ]+$/.test(userName)){
      //   //     setError(prevState => ({
      //   //         ...prevState,
      //   //         userNameErr: 'Please Enter Only Characters'
      //   //     }));
      //   //   return false;
      //   // }
      //   if(displayName) {
      //       setError(prevState => ({
      //           ...prevState,
      //           userDisplayErr: ''
      //       }));
      //     return true;
      //   }
      // }
      // function userbccValidation(bccName) { 
          
      //   if(!bccName) {
      //       setError(prevState => ({
      //           ...prevState,
      //           userbccErr: 'Please Enter BCC Recipient Name'
      //       }));
      //     return false;
      //   }
      //   // if (!/^[a-zA-Z ]+$/.test(userName)){
      //   //     setError(prevState => ({
      //   //         ...prevState,
      //   //         userNameErr: 'Please Enter Only Characters'
      //   //     }));
      //   //   return false;
      //   // }
      //   if(bccName) {
      //       setError(prevState => ({
      //           ...prevState,
      //           userbccErr: ''
      //       }));
      //     return true;
      //   }
      // }
      // function userccValidation(ccName) { 
          
      //   if(!ccName) {
      //       setError(prevState => ({
      //           ...prevState,
      //           userccErr: 'Please Enter CC Recipient Name'
      //       }));
      //     return false;
      //   }
      //   // if (!/^[a-zA-Z ]+$/.test(userName)){
      //   //     setError(prevState => ({
      //   //         ...prevState,
      //   //         userNameErr: 'Please Enter Only Characters'
      //   //     }));
      //   //   return false;
      //   // }
      //   if(ccName) {
      //       setError(prevState => ({
      //           ...prevState,
      //           userccErr: ''
      //       }));
      //     return true;
      //   }
      // }

      function userNameValidation(userNameVal) { 
          
        if(!userNameVal) {
            setError(prevState => ({
                ...prevState,
                userNameErr: 'Please Enter Username'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(userNameVal)){
        //     setError(prevState => ({
        //         ...prevState,
        //         userNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(userNameVal) {
            setError(prevState => ({
                ...prevState,
                userNameErr: ''
            }));
          return true;
        }
      }
      function emailValidation(emailVal) { 
          if(!emailVal) {
            setError(prevState => ({...prevState,emailErr: 'Please Enter Email'}));
            return false;
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailVal)){
            setError(prevState => ({
                ...prevState,
                emailErr: 'Please Enter  Valid Email'
            }));
            return false;
          }
          if(emailVal && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailVal)) {
            setError(prevState => ({
                ...prevState,
                emailErr: ''
            }));
            return true;
          }
      }
    //   function emailbccValidation(bccEmail) { 
    //     if(!bccEmail) {
    //       setError(prevState => ({...prevState,emailbccErr: 'Please Enter BCC Recipient Email'}));
    //       return false;
    //     }
    //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(bccEmail)){
    //       setError(prevState => ({
    //           ...prevState,
    //           emailbccErr: 'Please Enter  Valid BCC Recipient Email'
    //       }));
    //       return false;
    //     }
    //     if(bccEmail && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(bccEmail)) {
    //       setError(prevState => ({
    //           ...prevState,
    //           emailbccErr: ''
    //       }));
    //       return true;
    //     }
    // }
    // function emailccValidation(ccEmail) { 
    //     if(!ccEmail) {
    //       setError(prevState => ({...prevState,emailccErr: 'Please Enter CC Recipient Email'}));
    //       return false;
    //     }
    //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ccEmail)){
    //       setError(prevState => ({
    //           ...prevState,
    //           emailccErr: 'Please Enter  Valid CC Recipient Email'
    //       }));
    //       return false;
    //     }
    //     if(ccEmail && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ccEmail)) {
    //       setError(prevState => ({
    //           ...prevState,
    //           emailccErr: ''
    //       }));
    //       return true;
    //     }
    // }
    //   function portValidation(portType) {
    //     if (userPhoneNumber === "") {
    //         setError(prevState => ({...prevState,userPhoneNumberErr: 'Please Enter Phone Number'}));     
    //       return false;
    //     }
    //     if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(userPhoneNumber)) {
    //         setError(prevState => ({...prevState,userPhoneNumberErr: 'Please Enter Valid Phone Number'}));
    //       return false;
    //     } 
    //     if(userPhoneNumber !== "" && /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(userPhoneNumber)) {
    //         setError(prevState => ({...prevState,userPhoneNumberErr: ''}));
    //       return true;
    //     } 
    //   }
    
    //   function portocolValidation(protocol) {
    //     if(password === ""){
    //         setError(prevState => ({
    //             ...prevState,
    //             passwordErr: 'Please Enter password'
    //         }));
    //         return false;
    //     }
    //     if(password !== ""){
    //         setError(prevState => ({
    //             ...prevState,
    //             passwordErr: ''
    //         }));
    //         return true;
    //     }
    //   }
      function hostValidation(hostVal) {
        if (!hostVal || hostVal === "" || /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/.test(hostVal)) {
            setError(prevState => ({
                ...prevState,
                hostErr: ''
            }));
            return true;
          }
           if(!/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/.test(hostVal)){
            setError(prevState => ({
                ...prevState,
                hostErr: 'Please Enter Valid host'
            }));
            return false;
          }
      }
      function passwordValidation(passwordVal) {
        if(passwordVal === ""){
            setError(prevState => ({
                ...prevState,
                passwordErr: 'Please Enter password'
            }));
            return false;
        }
        if(passwordVal !== ""){
            setError(prevState => ({
                ...prevState,
                passwordErr: ''
            }));
            return true;
        }
      }

    // Create User
    const addEmailBoxHandler = async (e) =>{
        e.preventDefault();
        if(validate()){
            const payload_data={
                companyUsers: props.mailboxDetails.selectedUsers,
                emailAddress: props.mailboxDetails.emailAddress,
                host: props.mailboxDetails.host,
                password: props.mailboxDetails.password,
                port: props.mailboxDetails.port,
                protocol: props.mailboxDetails.protocol,
                userName: props.mailboxDetails.userName,
                mailBoxId: props.mailboxDetails.id,
                forwardEmail:props.mailboxDetails.forwardEmail,
                userDisplayName: props.mailboxDetails.userDisplayName,
                ccRecipientEmail: props.mailboxDetails.ccRecipientEmail,
                bccRecipientEmail: props.mailboxDetails.bccRecipientEmail
              };
              //console.log(payload_data);
              props.create_mailbox(payload_data,props.history);
        
        }
    }


    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Update Mail Box</h1>
                    <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show} 
                     onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.companyalert.msg}</p>
                    </Alert>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <h4>Mail Box Details:</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                      <label htmlFor="username">User Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Username"
                                        value={props.mailboxDetails.userName || ''}
                                        onChange={(e)=>props.updateCompanyMailBoxData("userName",e.target.value)}
                                        />
                                        {error.userNameErr ? <span className="error-span">{error.userNameErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                      <label htmlFor="displayname">Display Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Display Name"
                                        value={props.mailboxDetails.userDisplayName || ''}
                                        onChange={(e)=>props.updateCompanyMailBoxData("userDisplayName",e.target.value)}
                                        />
                                        {/* {error.userDisplayErr ? <span className="error-span">{error.userDisplayErr}</span>:null} */}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">          
                                    <label htmlFor="email">User Email</label>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Enter Email"
                                        value={props.mailboxDetails.emailAddress || ''}
                                        onChange={(e)=>props.updateCompanyMailBoxData("emailAddress",e.target.value)}
                                    />
                                         {error.emailErr ? <span className="error-span">{error.emailErr}</span>:null}
                                </div>
                                </div>

                                
                            </div>
                            <div className="row">
                            <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="password">Enter password</label>
                                        <input 
                                        type="password" 
                                        className="form-control" 
                                        placeholder="Enter Pasword"
                                        value={props.mailboxDetails.password || ''}
                                        onChange={(e)=>props.updateCompanyMailBoxData("password",e.target.value)}
                                         />
                                         {error.passwordErr ? <span className="error-span">{error.passwordErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="host">Enter Host</label>
                                        <input 
                                        type="url" 
                                        className="form-control" 
                                        placeholder="Enter Host"
                                        value={props.mailboxDetails.host || ''}
                                        onChange={(e)=>props.updateCompanyMailBoxData("host",e.target.value)}
                                         />
                                         {error.hostErr ? <span className="error-span">{error.hostErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="protocol" >Select Protocol </label>
                                        <select
                                         className="form-control"
                                            defaultValue={props.mailboxDetails.protocol}
                                            onChange={changeProtocolHandler}
                                            // onChange={(e)=>props.updateCompanyMailBoxData("protocol",e.target.value)}
                                            required 
                                            >
                                            <option value="0">Select Protocol</option>
                                            <option value="SMTP">SMTP</option>
                                            <option value="IMAP">IMAP</option>
                                        </select>
                                        {error.portTypeErr ? <span className="error-span">{error.portTypeErr}</span>:null}

                                    </div>
                                </div>
                                                          
                            </div>

                            
                            <div className="row">
                            <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="porttype">Enter Port</label>
                                        <input 
                                        type="number" 
                                        className="form-control" 
                                        placeholder="Enter Port"
                                        value={props.mailboxDetails.port || ''}
                                        onChange={(e)=>props.updateCompanyMailBoxData("port",e.target.value)}
                                         />
                                    </div>
                                </div> 
                                <div className="col-lg-4">
                                      <div className="form-group">
                                        <label htmlFor="porttype">Forward Email To</label>
                                        <input 
                                             type="text" 
                                             className="form-control" 
                                             placeholder="Enter Forward email to"     
                                             value={props.mailboxDetails.forwardEmail || ''}
                                             onChange={(e)=>props.updateCompanyMailBoxData("forwardEmail",e.target.value)}
                                        />
                                      </div>
                                </div>
                                <div className="col-lg-4">
                                      <div className="form-group">
                                      <label htmlFor="user">Assign User</label>
                                      <MultiSelect  options={options} value={selectedOptions} placeholder="Assign User" onChange={changeUserHandler}/>
  
                                        {/* <Multiselect
                                            isObject={true}
                                            displayValue="users"
                                            onRemove={function noRefCheck(selectedList,removedItem){
                                                props.updateCompanyMailBoxUserList(selectedList)
                                            }}
                                            onSelect={function noRefCheck (selectedList,selectedItem){
                                                props.updateCompanyMailBoxUserList(selectedList)
                                            }}
                                            options={options}
                                            selectedValues={selectedOptions}
                                            placeholder="Assign Users"
                                        /> */}
                                      </div>
                                    </div>

                                
                                
                            </div>
                            <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="porttype">BCC Recipient Email</label>
                                            <textarea
                                                className="form-control-form-placeholder-pd"
                                                placeholder="BCC Recipient Email"     
                                                value={props.mailboxDetails.bccRecipientEmail || ''}
                                                onChange={(e)=>props.updateCompanyMailBoxData("bccRecipientEmail",e.target.value)}
                                            />
                                            {error.emailbccErr ? <span className="error-span">{error.emailbccErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="porttype">CC Recipient Email</label>
                                            <textarea
                                                className="form-control-form-placeholder-pd"
                                                placeholder="CC Recipient Email"     
                                                value={props.mailboxDetails.ccRecipientEmail || ''}
                                                onChange={(e)=>props.updateCompanyMailBoxData("ccRecipientEmail",e.target.value)}
                                            />
                                            {error.emailccErr ? <span className="error-span">{error.emailccErr}</span>:null}
                                    </div>
                                </div>
                                </div>
                            <button className="btn btn-primary" onClick={addEmailBoxHandler}>Update Mail Box</button><br/>
                           
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
  return {
    companyalert: state.Company.alert,
    mailboxDetails:state.Company.mailBoxDetailsById
  }
}

export default connect(mapStateToProps,{get_all_compnay_users,updateCompanyMailBoxData,updateCompanyMailBoxUserList,closeAlert,create_mailbox})(UpdateEmailBox);