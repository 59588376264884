import React, {useState,useEffect} from 'react';
import { Link,withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../../redux/action/accountAction/loginAction';
import Alert from 'react-bootstrap/Alert';
import Footer from '../../Components/common/Footer/footer';

const ResetPassword = (props) =>{
    const [verifiactionCode, setVerifiactionCode] = useState('');
    // const [verifiactionCodeError, SetVerifiactionCodeError] = useState('');
    const [userPassword, setPassword] = useState('');
    const [userConfirmPassword, setConfirmPassword] = useState('');
    const [userPasswordError, setPasswordError] = useState('');
    const [userConfirmPasswordError, setConfirmPasswordError] = useState('');
    // const [resMessage, SetMessage] = useState('');
    // const [resultType, setResultType] = useState('');
    // const [show, setShow] = useState(false);

    useEffect(() => {
        const verifiaction_id = props.match.params.name;
        setVerifiactionCode(verifiaction_id.substring(1));

    },[props])

    const checkPassword = (e) => {
        setPassword(e.target.value);
    }

    const checkConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    //validation
    const validate = () => {
        // let verifiactionCodeVal = verifiactionCodeValidation(verifiactionCode);
        let passwordVal = passwordValidation(userPassword);
        let confirmPasswordVal = confirmPasswordValidation(userPassword, userConfirmPassword);
        if ( passwordVal === true && confirmPasswordVal === true) {
         return true;
        }
      }

     function passwordValidation(userPassword) {
      if (!userPassword) {
        setPasswordError('Password should not be empty');
        return false;
      }
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(userPassword)) {
        setPasswordError('password must be eight characters including one uppercase letter, one special character and alphanumeric characters');
        return false;
      }
      if (userPassword !== '' && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(userPassword)) {
        setPasswordError('');
        return true;
      }
    }
    function confirmPasswordValidation(userPassword, userConfirmPassword) {
      if (!userConfirmPassword) {
        setConfirmPasswordError('Confirm Password should not be empty');
        return false;
      }
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(userConfirmPassword)) {
        setConfirmPasswordError('Confirm password must be eight characters including one uppercase letter, one special character and alphanumeric characters');
        return false;
      }
      if (userPassword !== userConfirmPassword) {
        setConfirmPasswordError('Password and Confirm password Mismatch');
        return false;
      }
      if (userConfirmPassword !== '' && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(userConfirmPassword) && userPassword === userConfirmPassword) {
        setConfirmPasswordError('');
        return true;
      }
    }
  
    //console.log(encodeURIComponent(verifiactionCode));
           
    const formDataHandler = async (e) => {
        //console.log('hi');
        e.preventDefault();

        if (validate()) {

            const payload_data = {
                //verificationCode: verifiactionCode,
                password: userPassword,
                confirmPassword: userConfirmPassword,
              };
            props.reset_password(payload_data,verifiactionCode,props.history);
        //    const config = {
        //         method: 'post',
        //         url: GRANDWELCOME_BASE_URL + '/registration/reset-password?verificationCode='+ encodeURIComponent(verifiactionCode),
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Accept: 'application/json'
        //         },
        //         data: payload_data
        //     }
        //     axios(config).then(result => {
        //         // do good things
        //         if (result && result.status === 200) {
        //             //window.location.reload();
                    
        //             props.history.push('/');
        //         }
        //     })
        //         .catch(err => {
        //             if (err.response) {
                        
        //                 //console.log(err.response);
        //                 if (err.response.status === 400) {
        //                     //on failure
        //                     //alert('Invalid Credentials, Please enter valid details');
        //                     setShow(true);
        //                     setResultType("danger");
        //                     SetMessage(err.response.data.msg);
        //                 } else if (err.response.status === 500) {
        //                     //on failure
        //                     setShow(true);
        //                     setResultType("danger");
        //                     SetMessage('Something Went Wrong, Please retry after sometime');
        //                 }
        //                 // client received an error response (5xx, 4xx)
        //             } else if (err.request) {
        //                 // client never received a response, or request never left
        //                 setShow(true);
        //                 setResultType("danger");
        //                 SetMessage('Something Went Wrong, Please retry after sometime');
        //             } else {
        //                 // anything else
        //                 setShow(true);
        //                 setResultType("danger");
        //                 SetMessage('Something Went Wrong, Please retry after sometime');
        //             }
        //         });
        }
    }

    return(<React.Fragment>
            <div className="lt-breadcrumb">
            <div className="breadcrumb-content">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div className="title">
                            <h1>Reset Password</h1>
                        </div>
                        <ol className="breadcrumb align-self-center">
                            <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Reset Password</li>
                        </ol>                        
                    </div>
                </div>
            </div>
        </div>

        <div className="lt-page-content bg-color">
            <div className="lt-section">
                <div className="section-content lt-account section-padding">
                    <div className="account-content bg-white">
                        <h2>Reset Password</h2>
                        <form action="login.html" className="lt-form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Verification Code"
                                    value={verifiactionCode || ''}
                                    //onChange={checkVerifiactionCode}
                                    disabled={true}
                                    style={{backgroundColor:'#ccc'}}
                                />
                                {/* {verifiactionCodeError ? <span style={{ color: 'red' }}>{verifiactionCodeError}</span> : null} */}
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    value={userPassword || ''}
                                    onChange={checkPassword}
                                />
                                {userPasswordError ? <span style={{ color: 'red' }}>{userPasswordError}</span> : null}
                                
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    value={userConfirmPassword || ''}
                                    onChange={checkConfirmPassword}
                                />
                                {userConfirmPasswordError ? <span style={{ color: 'red' }}>{userConfirmPasswordError}</span> : null}
                               
                            </div>
                            <button 
                            className="btn btn-primary d-block"
                            onClick={formDataHandler}
                            style={{width: '-webkit-fill-available'}}
                            >Reset Now</button>
                              <br />
                              <Alert id="alert" className="alert" variant={props.resetPasswordErr.resultType} show={props.resetPasswordErr.show} >
                                <p>{props.resetPasswordErr.msg}</p>
                              </Alert>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer></Footer>
        </React.Fragment>);
}

const mapStateToProps = state => {
    return {
        resetPasswordErr: state.Login.alert,
    }
};

export default connect(mapStateToProps,{reset_password})(withRouter(ResetPassword));