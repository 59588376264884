import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { connect } from 'react-redux';
import { getProfile,saveProfile,updateProfileData,changePassword } from '../../redux/action/accountAction/loginAction';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
//  import { getSession } from '../../redux/Auth/sessionData';

const UpdateProfile = (props) => {
    // const sessionData = getSession();
    //const [profileData,setProfileData] = useState();
    const dispatch = useDispatch();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // const [userPhoneNumber, setUserPhoneNumber] = useState(sessionData.phoneNumber);
    const [error, setError] = useState({
        userFirstNameErr: '',
        userLastNameErr: '',
        userEmailErr: '',
        userPhoneNumberErr: '',
        // userAddressErr:'',
        userTerritoryErr: '',
        oldpasswordErr: '',
        newpasswordErr: '',
        confirmpasswordErr: '',
    });

    useEffect(()=>{
        //props.getProfile();
        dispatch(getProfile());
    },[dispatch])


    const validate = (e) => {
        let userFirstNameVal = userFirstNameValidation(props.profileData.firstName);
        let userLastNameVal = userLastNameValidation(props.profileData.lastName);
        let userEmailVal = userEmailValidation(props.profileData.email);
        // let userAddressVal = userAddressValidation(userAddress);
        let userPhoneNumberVal = userPhoneNumberValidation(props.profileData.phoneNumber);
        // let userRoleTypeVal = userRoleTypeValidation(userRoleType);
        //let passwordVal = passwordValidation(password);
        if (userFirstNameVal === true && userLastNameVal === true && userEmailVal === true && userPhoneNumberVal === true) {
            return true;
        }
    }


    function userFirstNameValidation(userFirstNameVal) {

        if (!userFirstNameVal) {
            setError(prevState => ({
                ...prevState,
                userFirstNameErr: 'Please Enter First Name'
            }));
            return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(userFirstNameVal)) {
        //     setError(prevState => ({
        //         ...prevState,
        //         userFirstNameErr: 'Please Enter Only Characters'
        //     }));
        //     return false;
        // }
        if (userFirstNameVal) {
            setError(prevState => ({
                ...prevState,
                userFirstNameErr: ''
            }));
            return true;
        }
    }
    function userLastNameValidation(userLastNameVal) {

        if (!userLastNameVal) {
            setError(prevState => ({
                ...prevState,
                userLastNameErr: 'Please Enter Last Name'
            }));
            return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(userLastNameVal)) {
        //     setError(prevState => ({
        //         ...prevState,
        //         userLastNameErr: 'Please Enter Only Characters'
        //     }));
        //     return false;
        // }
        if (userLastNameVal) {
            setError(prevState => ({
                ...prevState,
                userLastNameErr: ''
            }));
            return true;
        }
    }
    function userEmailValidation(userEmailVal) {
        if (!userEmailVal) {
            setError(prevState => ({ ...prevState, userEmailErr: 'Please Enter Email' }));
            return false;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmailVal)) {
            setError(prevState => ({
                ...prevState,
                userEmailErr: 'Please Enter  Valid Email'
            }));
            return false;
        }
        if (userEmailVal && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmailVal)) {
            setError(prevState => ({
                ...prevState,
                userEmailErr: ''
            }));
            return true;
        }
    }
    // function userAddressValidation(userAddress) {
    //   if (userAddress === "") {
    //       setError(prevState => ({...prevState,userAddressErr: 'Please Enter Address1'}));
    //     return false;
    //   }
    //   // if (!/^[0-9a-zA-Z]{6,}$/.test(userAddress)) {
    //   //     setError(prevState => ({...prevState,userAddressErr: 'Please Enter mininum 6 characters'}));
    //   //   return false;
    //   // }
    //   if(userAddress !== "") {
    //       setError(prevState => ({...prevState,userAddressErr: ''}));
    //     return true;
    //   }
    // }

    function userPhoneNumberValidation(userPhoneNumberVal) {
        if (userPhoneNumberVal !== "") {
            if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(userPhoneNumberVal)) {
                setError(prevState => ({ ...prevState, userPhoneNumberErr: 'Please Enter Valid Phone Number' }));
                return false;
            }
        }
       
        if (userPhoneNumberVal === "" || /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(userPhoneNumberVal)) {
            setError(prevState => ({ ...prevState, userPhoneNumberErr: '' }));
            return true;
        }
    }

    // Create User
    const addCompnayUserHandler = async (e) => {
        e.preventDefault();

        if (validate()) {
            const payload_data = {
                email: props.profileData.email,
                firstName: props.profileData.firstName,
                lastName: props.profileData.lastName,
                phoneNumber: props.profileData.phoneNumber,
            };
           // console.log(payload_data);
           props.saveProfile(payload_data);

        }
    }

    const validatePasswords = (e) => {
        let oldpasswordVal = oldpasswordValidation(oldPassword);
        let newpasswordVal = newpasswordValidation(newPassword);
        let confirmpasswordVal = confirmpasswordValidation(newPassword, confirmPassword);
        if (oldpasswordVal === true && newpasswordVal === true && confirmpasswordVal === true ) {
            return true;
        }
    }

    function oldpasswordValidation(oldPassword) {
        if (!oldPassword) {
            setError(prevState => ({
                ...prevState,
                oldpasswordErr: 'Password should not be empty'
            }));
          return false;
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(oldPassword)) {
            setError(prevState => ({
                ...prevState,
                oldpasswordErr: 'password must be eight characters including one uppercase letter, one special character and alphanumeric characters'
            }));
            return false;
        }
        if (oldPassword !== '' && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(oldPassword)) {
            setError(prevState => ({
                ...prevState,
                oldpasswordErr: ''
            }));
          return true;
        }
      }
    function newpasswordValidation(newPassword) {
        if (!newPassword) {
            setError(prevState => ({
                ...prevState,
                newpasswordErr: 'New password should not be empty'
            }));
          return false;
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(newPassword)) {
            setError(prevState => ({
                ...prevState,
                newpasswordErr: 'New password must be eight characters including one uppercase letter, one special character and alphanumeric characters'
            }));
            return false;
        }
        if (newPassword !== '' && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(newPassword)) {
            setError(prevState => ({
                ...prevState,
                newpasswordErr: ''
            }));
          return true;
        }
      }
      function confirmpasswordValidation(newPassword, confirmPassword) {
        if (!confirmPassword) {
            setError(prevState => ({
                ...prevState,
                confirmpasswordErr: 'Confirm Password should not be empty'
            }));
          return false;
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(confirmPassword)) {
            setError(prevState => ({
                ...prevState,
                confirmpasswordErr: 'Confirm password must be eight characters including one uppercase letter, one special character and alphanumeric characters'
            }));
          return false;
        }
        if (newPassword !== confirmPassword) {
            setError(prevState => ({
                ...prevState,
                confirmpasswordErr: 'New password and Confirm password Mismatch'
            }));
          return false;
        }
        if (confirmPassword !== '' && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/.test(confirmPassword) && newPassword === confirmPassword) {
            setError(prevState => ({
                ...prevState,
                confirmpasswordErr: ''
            }));
          return true;
        }
      }


    // Create new password
    const updatePasswordHandler = async (e) => {
        e.preventDefault();

        if (validatePasswords()) {
            const payload_data = {
                confirmPassword: confirmPassword,
                newPassword: newPassword,
                oldPassword: oldPassword,
            };
            // console.log(payload_data);
            props.changePassword(payload_data);

        }
        }

    return (
        <div className="tl-section">
            <div className="tl-dashboard bg-color">
                <div className="container-fluid p-0">
                    <div className="main">
                        <h1 className="db-title">Edit Profile</h1>
                        <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show}
                        // onClose={() => setShow(false)} dismissible
                        >
                            <p>{props.companyalert.msg}</p>
                        </Alert>

                        <div className="add-agent bg-white sub-section">
                            <Accordion preExpanded={['a']}>
                                <AccordionItem uuid="a">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Profile Details:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <form action="#" className="lt-form">

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="firstname">First Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter First Name"
                                                            value={props.profileData.firstName || ''}
                                                            onChange={(e) => props.updateProfileData("firstName",e.target.value)}
                                                        />
                                                        {error.userFirstNameErr ? <span style={{ color: 'red' }}>{error.userFirstNameErr}</span> : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="lastname">Last Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Last Name"
                                                            value={props.profileData.lastName || ''}
                                                            onChange={(e) => props.updateProfileData("lastName",e.target.value)}
                                                        />
                                                        {error.userLastNameErr ? <span style={{ color: 'red' }}>{error.userLastNameErr}</span> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Enter Email"
                                                            value={props.profileData.email || ''}
                                                            onChange={(e) => props.updateProfileData("email",e.target.value)}
                                                        />
                                                        {error.userEmailErr ? <span style={{ color: 'red' }}>{error.userEmailErr}</span> : null}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="number">Phone Number</label>
                                                        <NumberFormat
                                                            format="(###)-###-####"
                                                            mask="_"
                                                            className="form-control"
                                                            placeholder="Enter Phone Number"
                                                            value={props.profileData.phoneNumber || ''}
                                                            onChange={(e) => props.updateProfileData("phoneNumber",e.target.value)}
                                                        />
                                                        {error.userPhoneNumberErr ? <span style={{ color: 'red' }}>{error.userPhoneNumberErr}</span> : null}

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row">

                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <div className="add-photo">
                                                            <input type="file" name="add-photo" id="add-photo" className="inputfile form-control inputfile-4" data-multiple-caption="{count} files selected" multiple />
                                                            <label htmlFor="add-photo"><i className="fa fa-camera" aria-hidden="true"></i> <span>Add Photo</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <button className="btn btn-primary" onClick={addCompnayUserHandler}>Update Profile</button>
                                        </form>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Change Password:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <form action="#" className="lt-form">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="oldpassword">Old Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Enter Old Password"
                                                            value={oldPassword}
                                                            onChange={(e)=>setOldPassword(e.target.value)}
                                                        />
                                                        {error.oldpasswordErr ? <span style={{ color: 'red' }}>{error.oldpasswordErr}</span> : null}

                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="newpass">New Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Enter New Password"
                                                            value={newPassword}
                                                            onChange={(e)=>setNewPassword(e.target.value)}
                                                        />
                                                        {error.newpasswordErr ? <span style={{ color: 'red' }}>{error.newpasswordErr}</span> : null}

                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="confirmpass">Confirm Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Enter Confirm Password"
                                                            value={confirmPassword}
                                                            onChange={(e)=>setConfirmPassword(e.target.value)}
                                                        />
                                                        {error.confirmpasswordErr ? <span style={{ color: 'red' }}>{error.confirmpasswordErr}</span> : null}

                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary" onClick={updatePasswordHandler}>Change Password</button>
                                        </form>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                        <div className="copy-right text-center">
                            <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = state => {
    return {
        companyalert: state.Login.alert,
        profileData: state.Login.userDetails,
    }
}

export default connect(mapStateToProps, { getProfile,saveProfile,updateProfileData,changePassword})(UpdateProfile);