import React, {  useEffect,useState } from 'react';
import { Link,withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DrawMap from './map';
import {get_properities_by_campagin_id,get_properities_details_by_propety_id,storeUserCampaignId,updateCompanyUserCampaignData,changeCampaignStatusByID,closeAlert} from '../../../redux/action/companyUser/companyUserActionCreator';
import Geocode from 'react-geocode';
import Pagination from "react-js-pagination";
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';

const ViewCampaign = (props) =>{
    const [mapLatLong, setMapLatLong] = useState({lat:-34.397, lng:150.644});
    const [showMap, setShowMap] = useState();
    const [disable, setDisable] = useState(false);
    const [activePage, setActivePage] = useState(1);

    useEffect(()=>{
      Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
      Geocode.fromAddress(props.campaignDetails.companySubTerritoriesDTO[0].zipcode[0].zipCode).then(
       (response) => {
               const data = response.results[0].geometry.location;
               setMapLatLong({lat: data.lat,lng: data.lng});
               setShowMap(true);
                  },
       (error) => {
         setMapLatLong({lat:-34.397, lng:150.644});
       });
       let payload_data={
        campaignId:parseInt(props.campaignDetails.id),
        page: 0,
        size: 50,

      };
    props.get_properities_by_campagin_id(payload_data);
   let routeVal= "View";
    props.storeUserCampaignId(props.campaignDetails.id,props.history,routeVal);
   
    props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(props.campaignDetails.status === "ACTIVE"){
        setDisable(true)
    }else{
      setDisable(false)

    }

   // eslint-disable-next-line react-hooks/exhaustive-deps
},[setDisable]);

    //Sort Property data
    const sortPropertyData = (e) => {
       console.log(props.campaignProperty.content);

       if(e === 'ALL'){
            let payload_data={
              campaignId:parseInt(props.campaignDetails.id),
              page: 0,
              size: 50
            };
          props.get_properities_by_campagin_id(payload_data);
        let routeVal= "View";
          props.storeUserCampaignId(props.campaignDetails.id,props.history,routeVal);
        
       }
       if(e === 'New Listing'){
        let payload_data={
          campaignId:parseInt(props.campaignDetails.id),
          page: 0,
          size: 50,
          triggerFilters:["NEW_LISTING"]
          
        };
      props.get_properities_by_campagin_id(payload_data);
     let routeVal= "View";
      props.storeUserCampaignId(props.campaignDetails.id,props.history,routeVal);
       }
       if(e === 'Pending'){
        let payload_data={
          campaignId:parseInt(props.campaignDetails.id),
          page: 0,
          size: 50,
          triggerFilters:["PENDING"]
          
        };
      props.get_properities_by_campagin_id(payload_data);
     let routeVal= "View";
      props.storeUserCampaignId(props.campaignDetails.id,props.history,routeVal);
       }
       if(e === 'Sold'){
        let payload_data={
          campaignId:parseInt(props.campaignDetails.id),
          page: 0,
          size: 50,
          triggerFilters:["SOLD"]
          
        };
      props.get_properities_by_campagin_id(payload_data);
     let routeVal= "View";
      props.storeUserCampaignId(props.campaignDetails.id,props.history,routeVal);
        }

        if(e === 'Rental'){
          let payload_data={
            campaignId:parseInt(props.campaignDetails.id),
            page: 0,
            size: 50,
          triggerFilters:["RENTAL"]
            
          };
        props.get_properities_by_campagin_id(payload_data);
       let routeVal= "View";
        props.storeUserCampaignId(props.campaignDetails.id,props.history,routeVal);
       
            }
        
      };


    const selectedTriggerList = props.campaignDetails.triggerDTO.map((item,index,arr) =>{
      return <strong className="black">{item.triggerDisplayName}{index === arr.length - 1 ? '' : ', ' }&nbsp; </strong>
    })

    const properitiesCordsList= props.campaignProperty.content && props.campaignProperty.content.filter(item => item.isMessageSent === true && item.messageSentOn !== null).map(item =>{
        let address =item.address.line+','+item.address.city+','+item.address.state+','+item.address.postal_code;
        const latLng= {lng:parseFloat(item.address.coordinates.longitude),lat: parseFloat(item.address.coordinates.latitude),title: address}
      return latLng;
    });
    const properitiesList= props.campaignProperty.content && props.campaignProperty.content.filter(item => item.isMessageSent === true && item.messageSentOn !== null).map(item =>{
      return item;
  });

  
    let polygonsCordsList = [];
    let zipCordsList = [];
    if(props.campaignDetails){
    if(props.campaignDetails.companySubTerritoriesDTO.length>0){
        props.campaignDetails.companySubTerritoriesDTO.map(item => 
        
        {
          const polygonCords = item.polygonsDTO.coordinates.map(coordinate => 
            {
           const  latLng  = {lng:parseFloat(coordinate.longitude),lat: parseFloat(coordinate.latitude)}
          return latLng}
            );
            
       const zip= item.zipcode.map(zip =>
        {
          return {label: zip.zipCode, value: zip.zipCode,id:zip.id, boundaries:zip.boundaries}})
            zipCordsList=[...zipCordsList,...zip]
          polygonsCordsList = [...polygonsCordsList, polygonCords];
          return polygonCords;
          })
        }    
    };

    let zipcode=[]
    const assignedZipcodes=props.campaignDetails && props.campaignDetails.companySubTerritoriesDTO.map((item) => {
      const zipList = item.zipcode.forEach(zip => {
        zipcode.push(zip.zipCode)
        // return <strong className="black">{zip.zipCode}{index === arr.length  ? '' : ','}</strong>
      })
     return zipList
     });
    //change status
    const statusChangeHandler = (e,statusType) =>{
      e.preventDefault();
      props.updateCompanyUserCampaignData("status",statusType);
      const payload_data={
        campaignId: props.campaignDetails.id,
        campaignStatusEnum: statusType
      } 
      props.changeCampaignStatusByID(payload_data);
      if(statusType === "ACTIVE"){
        setDisable(true)
      }
      if(statusType === "STOP" || statusType === "DRAFT"){
        setDisable(false)
      }

    }

    //set property campaign id
    const handlePageChange = (pageNumber) =>{
      setActivePage(pageNumber);
      let payload_data={
        campaignId:props.campaignDetails.id,
        page: pageNumber - 1,
        size: 50
      };
    props.get_properities_by_campagin_id(payload_data);
    }

    //set property id
    const propertyDetailsHandler = (e,propertyId) =>{
    // console.log(propertyId)
      props.get_properities_details_by_propety_id(propertyId,props.history);
     }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">View Campaign</h1>
                    {props.companyalert.msg !== '' ? <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show} 
                            onClose={() => props.closeAlert(false)} dismissible
                            >
                              <p>{props.companyalert.msg}</p>
                    </Alert>:null}
                    
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <div className="row">
                              <div className="col-lg-4">
                                    <div className="form-group" style={{display:'inline-flex',padding:'4px 0px'}}>
                                      <label>Campaign Status: &nbsp;</label> <strong>{props.campaignDetails.status}</strong>
                                    </div>
                              </div>
                              <div className="col-lg-2">
                                    <div className="form-group">
                              <button className="btn btn-primary btn-sts" disabled={disable} onClick={(e)=>statusChangeHandler(e,"ACTIVE")}>ACTIVE</button> 
                              </div>
                              </div>
                              <div className="col-lg-2">
                                
                              <div className="form-group">
                              <button className="btn btn-primary btn-sts" disabled={!disable} onClick={(e)=>statusChangeHandler(e,"STOP")}> Stop </button> 
                              </div>
                              </div>
                            </div>
                           
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        
                                        <label htmlFor="campaign">Campaign Name: &nbsp;<strong className="black">{props.campaignDetails.campaign_name || ''}</strong></label>
                                       </div>
                                    
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                    <label htmlFor="mailbox">Mail Box: &nbsp; <strong className="black">{props.campaignDetails.mailBox.emailAddress || ''}</strong></label>
                                    </div>
                                    
                                </div>
                               <div className="col-md-4">
                                    <div className="form-group">
                                    <label htmlFor="trigger">Triggers: &nbsp;
                                    {selectedTriggerList}
                                     </label>
                                    </div>
                                </div>
                            
                               </div>
                               <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                    <label htmlFor="territory">Assigned Territory: &nbsp;
                                    {props.campaignDetails && props.campaignDetails.companySubTerritoriesDTO.map((item,index,arr) => {
                                      return <strong className="black">{item.territoryName || ''}{index === arr.length - 1 ? '' : ', '}&nbsp;</strong>
                                     })}
                                    </label>
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                    <label  htmlFor="zipcode">Assigned Zipcodes: &nbsp;
                                    {assignedZipcodes ?  <strong className="black">{zipcode.join(', ')}</strong>:null}
                                   
                                     </label>
                                    </div>
                                  </div>
                                  
                                </div>
                                {showMap ? 
                                <React.Fragment>
                                <label htmlFor="ploygon">Assigned polygon:</label>
                                <DrawMap
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    lat={mapLatLong.lat}
                                    lng={mapLatLong.lng}
                                    selectedpolygonsDTO={polygonsCordsList}
                                    properitiesCoordinates={properitiesCordsList}
                                    setZipcodeBoundaries={zipCordsList}
                                    editPolygon={showMap}
                                  /> </React.Fragment>: null}

                               <br/>
                        <div className="all-agent properties-table bg-white">
                        <div className="row">
                                    <div className="col-lg-10">
                                        <div className="about-content">
                                          <h3>Property Listing:</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 pt-10">
                                        <select className="form-control" onChange={(e) => sortPropertyData(e.target.value)}>
                                            <option value="ALL">All</option>
                                          {  props.campaignDetails.triggerDTO.map(item =>{
                                            return  <option value={item.triggerDisplayName}>{item.triggerDisplayName}</option>
                                          })}
                                           
                                        </select>
                                    </div>

                                </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col"className="pad-th"><strong  className="black">Image</strong></th>
                                    <th scope="col"className="pad-th"><strong className="black">Property Details</strong></th>
                                    <th scope="col"className="pad-th"><strong className="black">Agent Details</strong></th>
                                    <th scope="col"className="pad-th"><strong className="black">Listing Price</strong></th>
                                    <th scope="col"className="pad-th"><strong className="black">Message Sent On</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                            {props.campaignProperty.content && properitiesList.map((item,i)=>{
                                    return (item.isMessageSent === true && item.messageSentOn !== null ? 
                                        <tr key={i} className="pad-tb">
                                           <td  className="pad-tb"><Link to="#" onClick={(e)=>propertyDetailsHandler(e,item.id)}><img src={item.primaryImage || 'https://ap.rdcpix.com/95c83cb6dd70b914c6f6b4a1c3dc520bl-m1801961723s-w1024_h768.jpg'} alt="img" style={{height: '50px',width:'50px'}}/></Link></td>
                                           <td className="pad-tb">
                                             
                                            <Link to="#" onClick={(e)=>propertyDetailsHandler(e,item.id)}>
                                              <h2 style={{color:'black',margin:'0px',fontFamily: 'Oxanium, cursive'}}><Link to="#" onClick={(e)=>propertyDetailsHandler(e,item.id)}>{item.propertyName || ''}</Link></h2>
                                              <span className="address" style={{display: 'flex'}}><i className="fa fa-map-marker" aria-hidden="true" style={{color:'#179bee',padding:'12px'}}></i> &nbsp;{item.address.line}, <br/> &nbsp;{item.address.city}, {item.address.state}, {item.address.postal_code}</span>
                                            </Link>   
                                        </td>
                                        <td className="pad-tb">
                                               <span>Name: <span className="black" >{item.agentDetails !==null ? item.agentDetails.name: ''}</span></span><br/>
                                               <span>Email: <span className="black" >{item.agentDetails !== null ? item.agentDetails.email: ''}</span></span><br/>
                                        
                                       </td>
                                       <td className="pad-tb">
                                         ${String(item.list_price).replace(/(.)(?=(\d{3})+$)/g,'$1,')}
                                         </td>
                                       <td className="pad-tb">{item.messageSentOn !== null ? moment(item.messageSentOn).format('DD-MM-YYYY HH:MM'):null}</td>
                                        </tr>
                                     :null); 
                                })} 
                                </tbody>
                            </table>
                            <div className="text-center">
                            <div className="lt-pagination">
                                <Pagination
                                  className="pagination "
                                  activePage={activePage}
                                  itemsCountPerPage={50}
                                  totalItemsCount={props.campaignProperty.totalElements}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange}
                                  activeLinkClass="activeLinkClass"
                                />
                                  
                            </div>
                            </div>

                        </div>
                        {/* </div> */}
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
    return {
      companyalert: state.CompanyUser.alert,
      campaignDetails: state.CompanyUser.campaignDetails,
      campaignProperty: state.CompanyUser.campaginProperities,
    }
}


export default connect(mapStateToProps,{get_properities_by_campagin_id,updateCompanyUserCampaignData,storeUserCampaignId,changeCampaignStatusByID,get_properities_details_by_propety_id,closeAlert})(withRouter(ViewCampaign));
//export default CreateTerritory;