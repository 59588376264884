import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_all_compnay_users,closeAlert,create_mailbox } from '../../../redux/action/compnayAdmin/companyActionCreator';
// import Multiselect from 'multiselect-react-dropdown';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import Alert from 'react-bootstrap/Alert';

const AddEmailBox = (props) =>{
    const [userName,setUserName] =useState('');
    const [displayName,setDisplayName] =useState('');
    const [password,setPassword] =useState('');
    const [forwardedEmail,setForwardedEmail] =useState('');
    const [ccEmail,setccEmail] =useState('');
    const [bccEmail,setbccEmail] =useState('');
    const [email,setEmail] =useState('');
    const [host,setHost] =useState('');
    const [portType,setPortType] =useState('');
    const [protocol,setProtocol] =useState('');
    const [companyUsersList,setCompanyUsersList] =useState([]);
    const [selectedcompanyUsersList,setSelectedcompanyUsersList] =useState([]);
    const [error,setError]=useState({
        userNameErr:'',
        emailErr:'',
        emailbccErr:'',
        emailccErr:'',
        portTypeErr:'',
        hostErr:'',
        protocolErr:'',
        passwordErr:'',
    });

    useEffect(()=>{
      props.get_all_compnay_users().then(result => {
          setCompanyUsersList(result);
          });
          
          props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


  const  options  = companyUsersList && companyUsersList.map(item => {
      const val= {  id: item.id, firstName: item.firstName,lastName: item.lastName,users:  item.firstName +' ' +item.lastName,label: item.firstName +' ' +item.lastName,value: item.firstName +' ' +item.lastName};
      return val;
    });

    const changeProtocolHandler = (e) =>{
        setProtocol(e.target.value);
        if(e.target.value === "SMTP"){
            setPortType(587)
        }
        if(e.target.value === "IMAP"){
            setPortType(143)
        }
        if(e.target.value === "0"){
            setPortType('')
        }
    }
    

    const validate = (e) => {
        let userNameVal = userNameValidation(userName);
        // let userDisplayVal = userDisplayValidation(displayName);
        // let userbccVal = userbccValidation(bccName);
        // let userccVal = userccValidation(ccName);
        let emailVal = emailValidation(email);  
        // let emailbccVal = emailbccValidation(bccEmail);  
        // let emailccVal = emailccValidation(ccEmail);  
        //let portTypeVal = portValidation(portType);  
        // let portocolVal = portocolValidation(protocol);
        let hostVal = hostValidation(host);
        let passwordVal = passwordValidation(password);
        
        if( userNameVal === true &&  emailVal === true  && hostVal === true && passwordVal === true ){
          return true;
        }
      }

    //   function userDisplayValidation(displayName) { 
          
    //     if(!displayName) {
    //         setError(prevState => ({
    //             ...prevState,
    //             userDisplayErr: 'Please Enter DisplayName'
    //         }));
    //       return false;
    //     }
    //     // if (!/^[a-zA-Z ]+$/.test(userName)){
    //     //     setError(prevState => ({
    //     //         ...prevState,
    //     //         userNameErr: 'Please Enter Only Characters'
    //     //     }));
    //     //   return false;
    //     // }
    //     if(displayName) {
    //         setError(prevState => ({
    //             ...prevState,
    //             userDisplayErr: ''
    //         }));
    //       return true;
    //     }
    //   }
    //   function userbccValidation(bccName) { 
          
    //     if(!bccName) {
    //         setError(prevState => ({
    //             ...prevState,
    //             userbccErr: 'Please Enter BCC Recipient Name'
    //         }));
    //       return false;
    //     }
    //     // if (!/^[a-zA-Z ]+$/.test(userName)){
    //     //     setError(prevState => ({
    //     //         ...prevState,
    //     //         userNameErr: 'Please Enter Only Characters'
    //     //     }));
    //     //   return false;
    //     // }
    //     if(bccName) {
    //         setError(prevState => ({
    //             ...prevState,
    //             userbccErr: ''
    //         }));
    //       return true;
    //     }
    //   }
    //   function userccValidation(ccName) { 
          
    //     if(!ccName) {
    //         setError(prevState => ({
    //             ...prevState,
    //             userccErr: 'Please Enter CC Recipient Name'
    //         }));
    //       return false;
    //     }
    //     // if (!/^[a-zA-Z ]+$/.test(userName)){
    //     //     setError(prevState => ({
    //     //         ...prevState,
    //     //         userNameErr: 'Please Enter Only Characters'
    //     //     }));
    //     //   return false;
    //     // }
    //     if(ccName) {
    //         setError(prevState => ({
    //             ...prevState,
    //             userccErr: ''
    //         }));
    //       return true;
    //     }
    //   }
      function userNameValidation(userName) { 
          
        if(!userName) {
            setError(prevState => ({
                ...prevState,
                userNameErr: 'Please Enter Username'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(userName)){
        //     setError(prevState => ({
        //         ...prevState,
        //         userNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(userName) {
            setError(prevState => ({
                ...prevState,
                userNameErr: ''
            }));
          return true;
        }
      }
      function emailValidation(email) { 
          if(!email) {
            setError(prevState => ({...prevState,emailErr: 'Please Enter Email'}));
            return false;
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
            setError(prevState => ({
                ...prevState,
                emailErr: 'Please Enter  Valid Email'
            }));
            return false;
          }
          if(email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setError(prevState => ({
                ...prevState,
                emailErr: ''
            }));
            return true;
          }
      }
    //   function emailbccValidation(bccEmail) { 
    //     if(!bccEmail) {
    //       setError(prevState => ({...prevState,emailbccErr: 'Please Enter BCC Recipient Email'}));
    //       return false;
    //     }
    //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(bccEmail)){
    //       setError(prevState => ({
    //           ...prevState,
    //           emailbccErr: 'Please Enter  Valid BCC Recipient Email'
    //       }));
    //       return false;
    //     }
    //     if(bccEmail && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(bccEmail)) {
    //       setError(prevState => ({
    //           ...prevState,
    //           emailbccErr: ''
    //       }));
    //       return true;
    //     }
    // }
    // function emailccValidation(ccEmail) { 
    //     if(!ccEmail) {
    //       setError(prevState => ({...prevState,emailccErr: 'Please Enter CC Recipient Email'}));
    //       return false;
    //     }
    //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ccEmail)){
    //       setError(prevState => ({
    //           ...prevState,
    //           emailccErr: 'Please Enter  Valid CC Recipient Email'
    //       }));
    //       return false;
    //     }
    //     if(ccEmail && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(ccEmail)) {
    //       setError(prevState => ({
    //           ...prevState,
    //           emailccErr: ''
    //       }));
    //       return true;
    //     }
    // }
      function hostValidation(host) {
        if (!host || host === "" || /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/.test(host)) {
            setError(prevState => ({
                ...prevState,
                hostErr: ''
            }));
            return true;
          }
           if(!/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/.test(host)){
            setError(prevState => ({
                ...prevState,
                hostErr: 'Please Enter Valid host'
            }));
            return false;
          }
      }
      function passwordValidation(password) {
        if(password === ""){
            setError(prevState => ({
                ...prevState,
                passwordErr: 'Please Enter password'
            }));
            return false;
        }
        if(password !== ""){
            setError(prevState => ({
                ...prevState,
                passwordErr: ''
            }));
            return true;
        }
      }

    // Create User
    const addEmailBoxHandler = async (e) =>{
        e.preventDefault();
        if(validate()){
            const payload_data={
                companyUsers: selectedcompanyUsersList,
                emailAddress: email,
                host: host,
                password: password,
                port: portType,
                protocol: protocol,
                userName: userName,
                userDisplayName: displayName,
                forwardEmail:forwardedEmail,
                ccRecipientEmail: ccEmail,
                bccRecipientEmail: bccEmail
              };
              //console.log(payload_data);
              props.create_mailbox(payload_data,props.history);
        
        }
    }


    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Add Mail Box</h1>
                    <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show} 
                     onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.companyalert.msg}</p>
                    </Alert>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <h4>Mail Box Details:</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="username">User Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Username"
                                        value={userName}
                                        onChange={(e)=>setUserName(e.target.value)}
                                        />
                                        {error.userNameErr ? <span className="error-span">{error.userNameErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="displayname">Display Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Display Name"
                                        value={displayName}
                                        onChange={(e)=>setDisplayName(e.target.value)}
                                        />
                                        {/* {error.userDisplayErr ? <span className="error-span">{error.userDisplayErr}</span>:null} */}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="email">User Email</label>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                         {error.emailErr ? <span className="error-span">{error.emailErr}</span>:null}
                                </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="password">Enter password</label>
                                        <input 
                                        type="password" 
                                        className="form-control" 
                                        placeholder="Enter Pasword"
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                         />
                                         {error.passwordErr ? <span className="error-span">{error.passwordErr}</span>:null}
                                    </div>
                                </div>
                                
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="host">Enter Host</label>
                                        <input 
                                        type="url" 
                                        className="form-control" 
                                        placeholder="Enter Host"
                                        value={host}
                                        onChange={(e)=>setHost(e.target.value)}
                                         />
                                         {error.hostErr ? <span className="error-span">{error.hostErr}</span>:null}
                                    </div>
                                </div>
                                
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="protocol" >Select Protocol </label>
                                        <select
                                         className="form-control"
                                            defaultValue={protocol}
                                            onChange={changeProtocolHandler}
                                            required 
                                            >
                                            <option value="0">Select Protocol</option>
                                            <option value="SMTP">SMTP</option>
                                            <option value="IMAP">IMAP</option>
                                        </select>
                                        {error.portTypeErr ? <span className="error-span">{error.portTypeErr}</span>:null}

                                    </div>
                                </div>
                                </div>
                            <div className="row">
                            <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="porttype">Enter Port</label>
                                        <input 
                                        type="number" 
                                        className="form-control" 
                                        placeholder="Enter Port"
                                        value={portType}
                                        onChange={(e)=>setPortType(e.target.value)}
                                         />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                      <div className="form-group">
                                        <label htmlFor="porttype">Forward Email To</label>
                                        <input 
                                             type="text" 
                                             className="form-control" 
                                             placeholder="Enter Forward email to"     
                                             value={forwardedEmail}
                                             onChange={(e)=>setForwardedEmail(e.target.value)}
                                        />
                                      </div>
                                </div>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                      <label htmlFor="user">Assign User</label>
                                      <MultiSelect options={options} value={selectedcompanyUsersList} placeholder="Assign User" onChange={setSelectedcompanyUsersList} />
                                      </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="porttype">BCC Recipient Email</label>
                                            <textarea
                                                className="form-control-form-placeholder-pd"
                                                placeholder="BCC Recipient Email"     
                                                value={bccEmail}
                                                onChange={(e)=>setbccEmail(e.target.value)}
                                            />
                                            {error.emailbccErr ? <span className="error-span">{error.emailbccErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="porttype">CC Recipient Email</label>
                                            <textarea
                                                className="form-control-form-placeholder-pd"
                                                placeholder="CC Recipient Email"     
                                                value={ccEmail}
                                                onChange={(e)=>setccEmail(e.target.value)}
                                            />
                                            {error.emailccErr ? <span className="error-span">{error.emailccErr}</span>:null}
                                    </div>
                                </div>
                                </div>
                           
                            <button className="btn btn-primary" onClick={addEmailBoxHandler}>Add Mail Box</button><br/>
                           
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
  return {
    companyalert: state.Company.alert,
  }
}

export default connect(mapStateToProps,{get_all_compnay_users,closeAlert,create_mailbox})(AddEmailBox);