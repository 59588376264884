import React from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import {closeAlert,get_properities_details_by_propety_id} from '../../../redux/action/companyUser/companyUserActionCreator';
import moment from 'moment';

const ViewContact = (props) =>{
    const propertyDetailsHandler = (e,propertyId) =>{
        props.get_properities_details_by_propety_id(propertyId,props.history);
    }
    return(<React.Fragment>
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">Contact Details</h1>
                            <Alert id="alert" className="alert" variant={props.companyalert.resultType ? props.companyalert.resultType:null} show={props.companyalert.show} 
                                onClose={() => props.closeAlert(false)} dismissible
                                >
                                <p>{props.companyalert.msg}</p>
                                </Alert>
                        </div>
                        
                        
                    </div>
                    <h3>Messages List</h3>
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Sender Email</th>
                                    <th scope="col">Reciver Email</th>
                                    <th scope="col">Message Subject</th>
                                    <th scope="col">Message Sent On</th>
                                    {/* <th scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {props.propertData && props.propertData.messages.map(item => {
                                    return <tr key={item.id}>
                                        
                                    <td>{item.senderMail}</td>
                                    <td>{item.receiverMail}</td>
                                    <td><Link to="#" onClick={(e)=>propertyDetailsHandler(e,item.propertyId)}>{item.messageSubject}</Link></td>
                                    <td>{moment(item.creationTimestamp).format('DD-MM-YYYY HH:MM')}</td>
                                  
                                </tr>
                                })}
                            </tbody>
                            </table>
                    </div>
                    <br/>
                    <h3>Properties List</h3>
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    
                                    <th scope="col">Image</th>
                                    <th scope="col">Agent Details</th>
                                    <th scope="col">Property Address</th>
                                    <th scope="col">Price</th>
                                    {/* <th scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {props.propertData && props.propertData.campaignProperties.map(item => {
                                    return <tr key={item.id}>
                                        
                                    <td><img src={item.primaryImage} alt="img" style={{height: '50px',width:'50px'}}/></td>
                                    <td className="pad-tb">
                                               <span>Name: <span className="black" >{item.agentDetails !==null ? item.agentDetails.name: ''}</span></span><br/>
                                               <span>Email: <span className="black" >{item.agentDetails !== null ? item.agentDetails.email: ''}</span></span><br/>
                                       </td>
                                    <td className="pad-tb">
                                           <span className="address" style={{display: 'flex'}}><i className="fa fa-map-marker" aria-hidden="true" style={{color:'#179bee',padding:'12px'}}></i> &nbsp;{item.address.line}, <br/> &nbsp;{item.address.city}, {item.address.state}, {item.address.postal_code}</span>
                                        </td> 
                                    <td>${String(item.list_price).replace(/(.)(?=(\d{3})+$)/g,'$1,')}</td>
                                </tr>
                                })}
                            </tbody>
                            </table>
                    </div>

                    
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 
           
                    </React.Fragment>
    );
} 


const mapStateToProps = state => {
    return {
      companyalert: state.CompanyUser.alert,
      propertData: state.CompanyUser.contactDeatis,
    }
}


export default connect(mapStateToProps,{closeAlert,get_properities_details_by_propety_id})(ViewContact);