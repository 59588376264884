import React from 'react';
import { Link,withRouter } from 'react-router-dom';
// import Multiselect from 'multiselect-react-dropdown';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { connect } from 'react-redux';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";

const ViewCompanyTerritory = (props) =>{
    let zipcodes= [];
    let city= '';
    props.territoryDetails.cityZipCodes && props.territoryDetails.cityZipCodes.forEach(item => {
            city= item.label;
            const zipcodesWithCityName = item.zipcodes.map(zip => {
              const val= {cityName: city, zipCode: zip,label: zip,value:zip};
              return val;
               // return zip;
                })
            zipcodes= [...zipcodes,...zipcodesWithCityName];
        });
    // const selectedOptions = props.territoryDetails.cityZipCodes && props.territoryDetails.cityZipCodes.map(item => {
    // const val= {   cityName:  item.cityName, zipCode: [item.zipCode]};
    // return val;
    // });

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">View Territory</h1>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Territory Name"
                                        value={props.territoryDetails.territoryName || ''}
                                        disabled={true}
                                        style={{backgroundColor: '#ccc',cursor:'not-allowed'}}
                                       />
                                        </div>
                                    
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                <MultiSelect options={[]} value={zipcodes ? zipcodes:[]} placeholder="Select City"  className="disabledinpt" isDisabled={true} closeMenuOnSelect={true} isSearchable={false} />
                                
                                {/* <Multiselect
                                    isObject={true}
                                    displayValue="zipCode"
                                    disable={true}
                                    onRemove={function noRefCheck(selectedList,removedItem){
                                    }}
                                    onSelect={function noRefCheck(selectedList,selectedItem){
                                      }}
                                    placeholder=""
                                    options={selectedOptions}
                                    selectedValues={selectedOptions}
                                /> */}

                                    {/* <MultiSelect
                                        onChange={selectCitiesHandler}
                                        options={options}
                                        placeholder="Select City"
                                    /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <BootstrapSwitchButton
                                            checked={props.territoryDetails.isActive}
                                            onlabel='Active'
                                            offlabel='Inactive'
                                            width={100} 
                                            disabled={true}
                                            style={{cursor:'not-allowed'}}
                                            className="disabled"
                                        />
                                    </div>
                                </div>
                                </div>
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}


const mapStateToProps = state => {
    return {
      alertData: state.Product.alert,
      territoryDetails: state.Company.companyTerritories,
    }
  }

export default connect(mapStateToProps,null)(withRouter(ViewCompanyTerritory));
//export default CreateTerritory;