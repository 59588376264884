export const GET_ALL_COMPANY_USER_TERRITORIES_ERROR = "GET_ALL_COMPANY_USER_TERRITORIES_ERROR";
export const GET_ALL_COMPANY_USER_TRIGGERS_ERROR = "GET_ALL_COMPANY_USER_TRIGGERS_ERROR";
export const CREATE_COMPANY_USER_CAMPAIGN_ERROR = "CREATE_COMPANY_USER_CAMPAIGN_ERROR";
export const GET_ALL_COMPANY_USER_CAMPAIGN_ERROR = "GET_ALL_COMPANY_USER_CAMPAIGN_ERROR";
export const GET_COMPANY_USERS_MAILBOX_ERROR = "GET_COMPANY_USERS_MAILBOX_ERROR";
export const STORE_COMPNAY_USER_CAMPAIGN_ID = "STORE_COMPNAY_USER_CAMPAIGN_ID";
export const STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR = "STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR";
export const UPDATE_COMPANY_USER_CAMPAIGN_DATA = "UPDATE_COMPANY_USER_CAMPAIGN_DATA";
export const UPDATE_COMPANY_USER_CAMPAIGN_TERRITORIES = "UPDATE_COMPANY_USER_CAMPAIGN_TERRITORIES";
export const UPDATE_COMPANY_USER_CAMPAIGN_TRIGGER = "UPDATE_COMPANY_USER_CAMPAIGN_TRIGGER";
export const DELETE_COMPANY_USER_CAMPAIGN = "DELETE_COMPANY_USER_CAMPAIGN";
export const DELETE_COMPANY_USER_CAMPAIGN_ERROR = "DELETE_COMPANY_USER_CAMPAIGN_ERROR";
export const DUPLICATE_COMPANY_USER_CAMPAIGN = "DUPLICATE_COMPANY_USER_CAMPAIGN";
export const DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR = "DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR";
export const GET_ALL_USERS_SUBTERRITORY_ERROR = "GET_ALL_USERS_SUBTERRITORY_ERROR";
export const GET_USERS_SUBTERRITORY_BY_ID = "GET_USERS_SUBTERRITORY_BY_ID";
export const GET_USERS_SUBTERRITORY_BY_ID_ERROR = "GET_USERS_SUBTERRITORY_BY_ID_ERROR";
export const SAVE_MESSAGE_TEMPLATE = "SAVE_MESSAGE_TEMPLATE";
export const SAVE_MESSAGE_TEMPLATE_ERROR = "SAVE_MESSAGE_TEMPLATE_ERROR";
export const UPDATE_COMPANY_USER_EMAIL_TEMPLATE = "UPDATE_COMPANY_USER_EMAIL_TEMPLATE";
export const GET_MESSAGE_TEMPLATE = "GET_MESSAGE_TEMPLATE";
export const GET_MESSAGE_TEMPLATE_ERROR = "GET_MESSAGE_TEMPLATE_ERROR";
export const CHANGE_CAMPAIGN_STATUS = "CHANGE_CAMPAIGN_STATUS";
export const CHANGE_CAMPAIGN_STATUS_ERROR = "CHANGE_CAMPAIGN_STATUS_ERROR";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const GET_PROPERITIES_BY_CAMPAIGN_ID = "GET_PROPERITIES_BY_CAMPAIGN_ID";
export const GET_PROPERITIES_DETAILS_BY_ID = "GET_PROPERITIES_BY_ID";
export const GET_PROPERITIES_DETAILS_BY_ID_ERROR = "GET_PROPERITIES_BY_ID_ERROR";
export const GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR = "GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR";
export const GET_ALL_CONTACTS_LIST_ERROR = "GET_ALL_CONTACTS_LIST_ERROR";
export const GET_CONTACTS_DATA_BY_ID = "GET_CONTACTS_DATA_BY_ID";
export const GET_CONTACTS_DATA_BY_ID_ERROR = "GET_CONTACTS_DATA_BY_ID_ERROR";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";
export const UPDATE_CONTACT_STATUS = "UPDATE_CONTACT_STATUS";
export const UPDATE_CONTACT_STATUS_ERROR = "UPDATE_CONTACT_STATUS_ERROR";
export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const UNSUBSCRIBE_ERROR = "UNSUBSCRIBE_ERROR";