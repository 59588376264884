/* global google */
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
} from "react-google-maps";

import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";

var selectedShape;

//set lat long
const drawPolygon = (polygon, props) => {
  const coords = polygon
    .getPath()
    .getArray()
    .map((coord) => {
      const polygonDTO = {
        latitude: coord.lat(),
        longitude: coord.lng(),
      };
      return polygonDTO;
    });
  const zipcordslet =
    props.setZipcodeBoundaries &&
    props.setZipcodeBoundaries.map((companySubterritory) => {
      return companySubterritory.boundaries.map((polygon) =>
        polygon.coordinates.map((coordinate) => {
          return {
            lat: parseFloat(coordinate.latitude),
            lng: parseFloat(coordinate.longitude),
            zipCode: companySubterritory.label,
          };
        })
      );
    });
  let polygonDrawCords = false;
  //zipBoundariesPolygon which holds the drawn polygon is valid or not with key as zipcode_index and value as true/false
  //Key as zipcode_index we are appending index beaucse some zipcodes may have multi regions
  var zipBoundariesPolygon = {};
  coords.map((latLng) => {
    let curPosition = new google.maps.LatLng(latLng.latitude, latLng.longitude);
    return zipcordslet.map((pathCords, i) => {
      return pathCords.map((item,j) => {
        //As break is not applicable in map, we have do this way
        if(zipBoundariesPolygon[item[0].zipCode+"_"+j] === undefined){
          zipBoundariesPolygon[item[0].zipCode+"_"+j] = true;
        }
        
        let zipCodeRegion = new google.maps.Polygon({ paths: item });
        let containsLocation = google.maps.geometry.poly.containsLocation(
          curPosition,
          zipCodeRegion
        );

        if (!containsLocation && zipBoundariesPolygon[item[0].zipCode+"_"+j]) {
          zipBoundariesPolygon[item[0].zipCode+"_"+j] = false;
        }
        return zipCodeRegion;
      });
    });
  });

  
  //If zipBoundariesPolygon holds any zipCode value as true, then polygon is valid
  Object.keys(zipBoundariesPolygon).forEach(function (key) {
    if (zipBoundariesPolygon[key]) {
      polygonDrawCords = true;
    }
  });

  if (!polygonDrawCords) {
    polygon.setMap(null);
  } else {
    if(selectedShape !== undefined) {
      selectedShape.setMap(null);
      selectedShape = undefined;
    }
    selectedShape = polygon;
    const polygonDTO ={ coordinates:[
      ...coords
    ]};
    props.setpolygonsDTO(polygonDTO);
  }
};

const DrawMap = (props) => {
  const { polygonsDTO } = props.setpolygonsDTO;
  const zipcordslet =
    props.setZipcodeBoundaries &&
    props.setZipcodeBoundaries.map((companySubterritory) => {
      return companySubterritory.boundaries.map((polygon) =>
        polygon.coordinates.map((coordinate) => {
          return {
            lng: parseFloat(coordinate.longitude),
            lat: parseFloat(coordinate.latitude),
          };
        })
      );
    });
  const cords =
    polygonsDTO &&
    polygonsDTO.coordinates.map((item) => {
      const latLng = {
        lng: parseFloat(item.longitude),
        lat: parseFloat(item.latitude),
      };
      return latLng;
    });

  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={new google.maps.LatLng(props.lat, props.lng)}
    >
      {props.editPolygon ? (
        <Polygon
          path={cords}
          options={{
            strokeColor: "black",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#179bee78",
            fillOpacity: 0.35,
          }}
        />
      ) : 
      null}

      {props.setZipcodeBoundaries
        ? zipcordslet.map((item, i) => {
            return item.map((latLng) => {
              return (
                <Polygon
                  path={latLng}
                  key={i}
                  options={{
                    strokeColor: "red",
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: "transparent",
                    fillOpacity: 0.35,
                  }}
                />
              );
            });
          })
        : null}

      {props.drawPolygon === false ? null : (
        <DrawingManager
          defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
          onPolygonComplete={(polygon) => drawPolygon(polygon, props)}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                google.maps.drawing.OverlayType.POLYGON,
              ],
            },
            polygonOptions: {
              fillColor: `#0707073b`,
              fillOpacity: 1,
              strokeWeight: 2,
              zIndex: 1,
            },
          }}
        />
      )}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(DrawMap));
