let GRANDWELCOME_BASE_URL;

switch (process.env.REACT_APP_SERVER_TYPE) {
  case "localhost":
    GRANDWELCOME_BASE_URL = "http://localhost:8080/realtor";
    break;
  case "stg":
    GRANDWELCOME_BASE_URL = "https://prelive-ecs.uplead.app/realtor";
    break;
  case "prod":
    GRANDWELCOME_BASE_URL = "https://live-ecs.uplead.app/realtor";
    break;
 default:
    GRANDWELCOME_BASE_URL = "https://prelive-ecs.uplead.app/realtor";
    // GRANDWELCOME_BASE_URL = "http://localhost:8080/realtor";
    break;
}
export const GRANDWELCOME_DOMAIN = GRANDWELCOME_BASE_URL;

export default GRANDWELCOME_BASE_URL;

