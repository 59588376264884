import React, {  useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {get_all_compnay_user_subterritories,storeSubTerritoryId} from '../../../redux/action/companyUser/companyUserActionCreator';
// import Alert from 'react-bootstrap/Alert';
import Loader from '../../../Components/common/Loader';

const UserSubTerritoriesList = (props) =>{
    const [subTerritoryList, setSubTerritoriesList] = useState([]);
    const {companySubTerritoryDTOS}= subTerritoryList  || {};

    useEffect(()=>{
        props.get_all_compnay_user_subterritories().then(result => {
            setSubTerritoriesList(result);
            });
            
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    const viewTerritoryHandler = (e,territoryId) => {
        props.storeSubTerritoryId(territoryId,props.history);
        
    }

    return(<React.Fragment>
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Territories</h1>
                            {/* <Alert id="alert" className="alert" variant={props.alertData.resultType} show={props.alertData.show} 
                                onClose={() => props.closeAlert(false)} dismissible
                                >
                                <p>{props.alertData.msg}</p>
                                </Alert> */}
                        </div>
                        
                        
                    </div>
                    {companySubTerritoryDTOS ? 
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Territory Name</th>
                                    <th scope="col">Territory Location</th>
                                    <th scope="col">Action</th>
                                    {/* <th scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {companySubTerritoryDTOS && companySubTerritoryDTOS.map(item=>{
                                    return (
                                        <tr key={item.id}>
                                            <td className="agent-name">
                                                <h2>{item.territoryName}</h2>
                                            </td>
                                            
                                            <td className="agent-status">
                                            {item.zipcode.slice(0, 2).map((item,i) => {
                                                return ( 
                                                        <Link to="#" key={i}><i className="fa fa-map-marker " aria-hidden="true"></i> {item.zipCode}</Link>
                                                        )})}
                                                {item.zipcode.length > 2 ? <Link to="#"  style={{color: '#179bee'}} onClick={(e)=>viewTerritoryHandler(e,item.id)}><i className="fa fa-link" aria-hidden="true"></i>More...</Link>:null}
                                            
                                            {/* {item.zipcode.map(cityandZipcode => {
                                                return ( 
                                                        <Link to="#" key={cityandZipcode}><i className="fa fa-map-marker " aria-hidden="true"></i> {cityandZipcode.zipCode}</Link>
                                                       
                                                        )} )} */}
                                             </td>
                                            <td >
                                                {/* <Link to="#" onClick={(e)=>deleteSubterritoryHandler(e,item.id)}><i className="fa fa-trash"></i></Link> */}
                                                <Link to="#" onClick={(e)=>viewTerritoryHandler(e,item.id)}>View Details</Link>
                                            </td>
                                            
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                    </div>
                    :<Loader/>}
                    
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 
           
                    </React.Fragment>
    );
} 


// const mapStateToProps = state => {
//     return {
//       alertData: state.Company.alert,
//       //territoryDetails: state.Company.companyTerritories,
//     }
//   }

export default connect(null,{get_all_compnay_user_subterritories,storeSubTerritoryId})(UserSubTerritoriesList);