import { create } from "axios";
import BaseUrls from "../../configs/baseUrls";

const { DOMAIN } = BaseUrls;

export const API = create({
  baseURL: DOMAIN,
  timeout: 200000,
  headers: {
    'Content-Type': 'application/json',
     Accept: 'application/json',
    "Access-Control-Allow-Origin": "*",
  },
});

export const setAuthHeader = (token) => {
  
  API.defaults.headers.common.Authorization = `Bearer ${token}`;
};


export default API;
// export default {
//   API,
//   setAuthHeader,
// };
