import { GET_ALL_COMPANY_USER_TERRITORIES_ERROR,GET_ALL_COMPANY_USER_TRIGGERS_ERROR,GET_ALL_USERS_SUBTERRITORY_ERROR,GET_USERS_SUBTERRITORY_BY_ID,GET_USERS_SUBTERRITORY_BY_ID_ERROR,
    UPDATE_COMPANY_USER_CAMPAIGN_DATA,UPDATE_COMPANY_USER_CAMPAIGN_TRIGGER,SAVE_MESSAGE_TEMPLATE,CLOSE_ALERT,GET_PROPERITIES_BY_CAMPAIGN_ID,
    DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR,DELETE_COMPANY_USER_CAMPAIGN_ERROR,GET_MESSAGE_TEMPLATE,GET_MESSAGE_TEMPLATE_ERROR,GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR,
    DELETE_COMPANY_USER_CAMPAIGN,DUPLICATE_COMPANY_USER_CAMPAIGN,GET_PROPERITIES_DETAILS_BY_ID,GET_PROPERITIES_DETAILS_BY_ID_ERROR,
    UPDATE_COMPANY_USER_EMAIL_TEMPLATE,CHANGE_CAMPAIGN_STATUS_ERROR,GET_CONTACTS_DATA_BY_ID,GET_CONTACTS_DATA_BY_ID_ERROR,UNSUBSCRIBE_ERROR,UNSUBSCRIBE,
    CHANGE_CAMPAIGN_STATUS,GET_ALL_CONTACTS_LIST_ERROR,GET_DASHBOARD_ERROR,UPDATE_CONTACT_STATUS_ERROR,UPDATE_CONTACT_STATUS,
    UPDATE_COMPANY_USER_CAMPAIGN_TERRITORIES,CREATE_COMPANY_USER_CAMPAIGN_ERROR,STORE_COMPNAY_USER_CAMPAIGN_ID,STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR} from '../../action/companyUser/companyUserActionTypes';

const initialState = {
    alert:{
        show:false,
        resultType: '',
        msg:'',
    },
    campaignDetails:{},
    subTerritoryDetails:{},
    emailTemplateDetails:{
        emailSubject: '',
        emailTemplate: '',
        id: null,
        triggerDTO: {
            
        }
    },
    campaginProperities:{},
    propertyDetails:{},
    contactDeatis:{},
}

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case GET_ALL_COMPANY_USER_TERRITORIES_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case GET_ALL_COMPANY_USER_TRIGGERS_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case CREATE_COMPANY_USER_CAMPAIGN_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case STORE_COMPNAY_USER_CAMPAIGN_ID:
            return{
                ...state,
                campaignDetails:action.payload
            }
        case STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case UPDATE_COMPANY_USER_CAMPAIGN_DATA:
            return{
                ...state,
                campaignDetails: {
                    ...state.campaignDetails,
                    [action.payload.name]: action.payload.value
                }
            }
        case UPDATE_COMPANY_USER_CAMPAIGN_TERRITORIES:
            return {
                
                ...state,
                campaignDetails: {
                    ...state.campaignDetails,
                    companySubTerritoriesDTO:action.payload
                }
            }
        case UPDATE_COMPANY_USER_CAMPAIGN_TRIGGER:
            return {
                
                ...state,
                campaignDetails: {
                    ...state.campaignDetails,
                    triggerDTO:action.payload
                }
            }
        case DELETE_COMPANY_USER_CAMPAIGN:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:false,
                }
            }
        case DELETE_COMPANY_USER_CAMPAIGN_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case DUPLICATE_COMPANY_USER_CAMPAIGN:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:false,
                }
            }
        case DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case GET_ALL_USERS_SUBTERRITORY_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case GET_USERS_SUBTERRITORY_BY_ID_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case GET_USERS_SUBTERRITORY_BY_ID:
            return{
                ...state,
                subTerritoryDetails:action.payload
            }
        case GET_MESSAGE_TEMPLATE_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case GET_MESSAGE_TEMPLATE:
            return{
                ...state,
                emailTemplateDetails:action.payload
            }
        case UPDATE_COMPANY_USER_EMAIL_TEMPLATE:
            return{
                ...state,
                emailTemplateDetails: {
                    ...state.emailTemplateDetails,
                    [action.payload.name]: action.payload.value
                }
            }
        // case CHANGE_CAMPAIGN_STATUS:
        //     return {
        //         ...state,
        //         campaignDetails: {
        //             ...state.campaignDetails,
        //             status: action.payload
        //         }
        //     }
        case CHANGE_CAMPAIGN_STATUS_ERROR:
            return {
                ...state,
                alert:{
                    ...alert, 
                    resultType: 'danger',
                    show:action.payload,
                }
            }
        case GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:action.payload,
                    resultType: 'danger',
                }
            }
        case SAVE_MESSAGE_TEMPLATE:
           
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'success',
                    msg:action.payload
                }
            }
        case CHANGE_CAMPAIGN_STATUS:
            // debugger
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'success',
                    msg:action.payload
                }
            }
        case GET_PROPERITIES_BY_CAMPAIGN_ID:
            return{
                ...state,
                campaginProperities: action.payload
            }
        case GET_PROPERITIES_DETAILS_BY_ID:
            return{
                ...state,
                propertyDetails: action.payload
            }
        case GET_PROPERITIES_DETAILS_BY_ID_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    resultType: 'danger',
                    show:action.payload,
                }
            }
        case CLOSE_ALERT:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:action.payload,
                }
            }
        case GET_ALL_CONTACTS_LIST_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    resultType: 'danger',
                    show:action.payload,
                }
            }
        case GET_DASHBOARD_ERROR:
        return {
            ...state,
            alert:{
                ...alert,
                resultType: 'danger',
                show:action.payload,
            }
        }
        case GET_CONTACTS_DATA_BY_ID:
            return{
                ...state,
                contactDeatis:action.payload
            }
        case GET_CONTACTS_DATA_BY_ID_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case UPDATE_CONTACT_STATUS:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'success',
                    msg:action.payload
                }
            }
        case UPDATE_CONTACT_STATUS_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case UNSUBSCRIBE:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'success',
                    msg:action.payload
                }
            }
        case UNSUBSCRIBE_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        default: return state
    }

}