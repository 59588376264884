import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { connect } from 'react-redux';
import { create_company_user,get_all_compnay_territories,get_all_compnay_subterritories,get_all_compnay_mail_boxes,closeAlert } from '../../../redux/action/compnayAdmin/companyActionCreator';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
//import MultiSelect from "react-multi-select-component";
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import Alert from 'react-bootstrap/Alert';


const AddUser = (props) =>{
    const [userFirstName,setUserFirstName] =useState('');
    const [userLastName,setUserLastName] =useState('');
    const [userEmail,setUserEmail] =useState('');
    const [userPhoneNumber,setUserPhoneNumber] =useState('');
    // const [userAddress,setUserAddress] =useState('');
    const [isActive,setisActive] =useState(true);
    // const [territoryFields,setTerritoryFields] =useState(false);
    const [userRoleType,setUserRoleType] =useState('');
    const [mailBoxList, setMailBoxList] = useState([]);
    const [territoriesList, setTerritoriesList] = useState([]);
    // const [mailBoxOptions, setmailBoxOptions] = useState([{id: 0,emailAddress: "Select All"}]);
    const [selectedMailBoxList, setSelectedMailBoxList] = useState([]);
    const [selectedTerritoryList, setSelectedTerritoryList] = useState([]);
    
    const [error,setError]=useState({
        userFirstNameErr:'',
        userLastNameErr:'',
        userEmailErr:'',
        userPhoneNumberErr:'',
        // userAddressErr:'',
        userTerritoryErr:'',
        userRoleTypeErr:'',
    });

    
    useEffect(()=>{
      // props.get_all_compnay_mail_boxes().then(result => {
      //   setMailBoxList(result);
      //   })
      props.get_all_compnay_subterritories().then(result => {
        setTerritoriesList(result);
        props.get_all_compnay_mail_boxes().then(result => {
          setMailBoxList(result);
          });
        }).catch(err => {
          props.get_all_compnay_mail_boxes().then(result => {
            setMailBoxList(result);
            })
          });
          
          props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);



  const  {companySubTerritoryDTOS}  = territoriesList;
  const  options  = companySubTerritoryDTOS && companySubTerritoryDTOS.map(item => {
    const val= {  id: item.id,label: item.territoryName,value:item.territoryName};
    return val;
      
    });

    
   const mailBoxOptions= mailBoxList && mailBoxList.map(item => {
      const val= {  id: item.id, emailAddress:  item.emailAddress,label: item.emailAddress,value:item.emailAddress};
      return val;
    });
    //chnage status      
    const toggleActiveHandler = (e) =>{
        setisActive(!isActive);
    }

    

    const validate = (e) => {
       // let territoriesVal = territoriesValidation(territories);
        let userFirstNameVal = userFirstNameValidation(userFirstName);
        let userLastNameVal = userLastNameValidation(userLastName);
        let userEmailVal = userEmailValidation(userEmail);  
        // let userAddressVal = userAddressValidation(userAddress);  
        let userPhoneNumberVal = userPhoneNumberValidation(userPhoneNumber);
        let userRoleTypeVal = userRoleTypeValidation(userRoleType);
        
        if( userRoleTypeVal === true  && userFirstNameVal === true && userLastNameVal === true && userEmailVal === true && userPhoneNumberVal === true ){
          return true;
        }
      }
    
      // function territoriesValidation(territories) { 
      //   if (!territories || territories === "0") {
      //     setError(prevState => ({
      //         ...prevState,
      //         userTerritoryErr: 'Please Assign Territory'
      //     }));
      //     return false;
      //   }else if(territories && territories !== "0"){
      //     setError(prevState => ({
      //         ...prevState,
      //         userTerritoryErr: ''
      //     }));
      //     return true;
      //   }
      // }
      function userFirstNameValidation(userFirstName) { 
          
        if(!userFirstName) {
            setError(prevState => ({
                ...prevState,
                userFirstNameErr: 'Please Enter First Name'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(userFirstName)){
        //     setError(prevState => ({
        //         ...prevState,
        //         userFirstNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(userFirstName ) {
            setError(prevState => ({
                ...prevState,
                userFirstNameErr: ''
            }));
          return true;
        }
      }
      function userLastNameValidation(userLastName) { 
          
        if(!userLastName) {
            setError(prevState => ({
                ...prevState,
                userLastNameErr: 'Please Enter Last Name'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(userLastName)){
        //     setError(prevState => ({
        //         ...prevState,
        //         userLastNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(userLastName ) {
            setError(prevState => ({
                ...prevState,
                userLastNameErr: ''
            }));
          return true;
        }
      }
      function userEmailValidation(userEmail) { 
          if(!userEmail) {
            setError(prevState => ({...prevState,userEmailErr: 'Please Enter Email'}));
            return false;
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmail)){
            setError(prevState => ({
                ...prevState,
                userEmailErr: 'Please Enter  Valid Email'
            }));
            return false;
          }
          if(userEmail && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmail)) {
            setError(prevState => ({
                ...prevState,
                userEmailErr: ''
            }));
            return true;
          }
      }
      function userPhoneNumberValidation(userPhoneNumber) {
        // if (userPhoneNumber === "") {
        //     setError(prevState => ({...prevState,userPhoneNumberErr: 'Please Enter Phone Number'}));     
        //   return false;
        // }
        // if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(userPhoneNumber)) {
        //     setError(prevState => ({...prevState,userPhoneNumberErr: 'Please Enter Valid Phone Number'}));
        //   return false;
        // }
        if(userPhoneNumber !== '' && !/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(userPhoneNumber) && userPhoneNumber !== null){
          setError(prevState => ({...prevState,userPhoneNumberErr: 'Please Enter Valid Phone Number'}));
          return false;
        } else {
            setError(prevState => ({...prevState,userPhoneNumberErr: ''}))
            return true;
        }
      }
    
      function userRoleTypeValidation(userRoleType) {
        if (!userRoleType || userRoleType === "0") {
            setError(prevState => ({
                ...prevState,
                userRoleTypeErr: 'Please Select User Role'
            }));
            return false;
          }else if(userRoleType && userRoleType !== "0"){
            setError(prevState => ({
                ...prevState,
                userRoleTypeErr: ''
            }));
            return true;
          }
      }

    // Create User
    const addCompnayUserHandler = async (e) =>{
        e.preventDefault();
        if(validate()){
            let status= null;
            if(isActive === true){
              status = "ACTIVE"
            }
            if(isActive === false){
              status = "INACTIVE"
            }
            const assignedTerritoryList = selectedTerritoryList.map(item =>{
              return {companySubTerritoryId: item.id,subTerritoryName:item.value}
            })
            const payload_data={
                    companySubTerritoryDTOS: assignedTerritoryList,
                    // companyUserTerritories: assignedTerritoryList,
                    email: userEmail,
                    mailBoxDTOS: selectedMailBoxList,
                    firstName: userFirstName,
                    status: status,
                    lastName: userLastName,
                    phoneNumber: userPhoneNumber,
                    userType: userRoleType,
                    companyUserId:null
              };
              //console.log(payload_data);
              props.create_company_user(payload_data,props.history);
        
        }
    }


    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Add Company User</h1>
                    <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show} 
                     onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.companyalert.msg}</p>
                    </Alert>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <h4>User Details:</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="firstname">First Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter First Name"
                                        value={userFirstName}
                                        onChange={(e)=>setUserFirstName(e.target.value)}
                                        />
                                        {error.userFirstNameErr ? <span className="error-span">{error.userFirstNameErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="lastname">Last Name</label>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Enter Last Name"
                                    value={userLastName}
                                    onChange={(e)=>setUserLastName(e.target.value)}
                                     />
                                     {error.userLastNameErr ? <span className="error-span">{error.userLastNameErr}</span>:null}
                                </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="Email">User Email</label>
                                        <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Enter Email"
                                        value={userEmail}
                                        onChange={(e)=>setUserEmail(e.target.value)}
                                         />
                                         {error.userEmailErr ? <span className="error-span">{error.userEmailErr}</span>:null}
                                    </div>
                                 </div>
                            </div>
                            <div className="row">
                              

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="number">Phone Number</label>
                                        <NumberFormat 
                                            format="(###)-###-####" 
                                            mask="_" 
                                            className="form-control" 
                                            placeholder="Enter Phone Number" 
                                            value={userPhoneNumber}
                                            onChange={(e)=>setUserPhoneNumber(e.target.value)}
                                            />
                                            {error.userPhoneNumberErr ? <span className="error-span">{error.userPhoneNumberErr}</span>:null}
                                        
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="userrole">Select User Role</label>
                                        <select
                                         className="form-control"
                                            defaultValue={userRoleType}
                                            onChange={(e)=>setUserRoleType(e.target.value)}
                                            required 
                                            >
                                            <option value="0">Select User Role</option>
                                            <option value="COMPANY_ADMIN">Admin</option>
                                            <option value="COMPANY_USER">User</option>
                                        </select>
                                        {error.userRoleTypeErr ? <span className="error-span">{error.userRoleTypeErr}</span>:null}

                                    </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label htmlFor="mialbox" >Assign Mail Box</label>
                                  <MultiSelect options={mailBoxOptions || []} value={selectedMailBoxList} placeholder="Assign Mail Box" onChange={setSelectedMailBoxList} />
                               </div>
                                </div>
                                
                                
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                      <label htmlFor="territory">Assign Territory</label>
                                    <MultiSelect options={options || []} value={selectedTerritoryList} placeholder="Assign Territory" onChange={setSelectedTerritoryList} />
                               
                                    {/* <select
                                         className="form-control"
                                        //  onChange={}
                                    >
                                      <option value="0">Assign Territory</option>
                                           {options} 
                                        </select> */}
                                {error.userTerritoryErr ? <span className="error-span">{error.userTerritoryErr}</span>:null}
                                </div>
                                </div>

                                
                                <div className="col-md-2">
                                    <div className="form-group">
                                      <label htmlFor="toggle" style={{visibility:'hidden',display:'block'}}>Toggle Map</label>
                                      <BootstrapSwitchButton
                                            checked={isActive}
                                            onlabel='Active'
                                            offlabel='Inactive'
                                            onChange={toggleActiveHandler}
                                            width={100} 
                                        />
                                    </div>
                                 </div>
                                </div>
                               
                            <button className="btn btn-primary" onClick={addCompnayUserHandler}>Add User</button><br/>
                            {/* {inputlistErr ? <span className="error-span">{inputlistErr}</span>:null} */}

                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
  return {
    companyalert: state.Company.alert,
  }
}

export default connect(mapStateToProps,{create_company_user,get_all_compnay_territories,get_all_compnay_subterritories,get_all_compnay_mail_boxes,closeAlert})(AddUser);