import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { create_company_subterritory,get_all_compnay_territories,get_all_compnay_users,closeAlert } from '../../../redux/action/compnayAdmin/companyActionCreator';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import Alert from 'react-bootstrap/Alert';
import DrawMap from './map'
import Geocode from 'react-geocode';
import Loader from '../../../Components/common/Loader';

const AddSubTerritory = (props) =>{
    const [territoriesList,setTerritoriesList] =useState([]);
    const [territoryId,setTerritoryId] =useState();
    const [subTerritory,setSubTerritory] =useState('');
    const [zipCode, setZipcode] = useState();
    const [zipcodeList, setZipcodeList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [polygonCords, setPolygonCords] = useState({});
    const [mapOpen, setMapOpen] = useState(false);
    const [companyUsersList,setCompanyUsersList] =useState([]);

    const [mapLatLong, setMapLatLong] = useState({lat:-34.397, lng:150.644});
    const [mapLatLongBounds, setMapLatLongBounds] = useState({});
    const [mapViewreportBounds, setMapViewreportBounds] = useState({});

    const [error,setError]=useState({
        userTerritoryErr:'',
        userSubTerritoryErr:'',
        userZipcodesErr:'',
        // userPolygonCordsErr:'',
    });

    
    useEffect(()=>{
      props.get_all_compnay_territories().then(result => {
        setTerritoriesList(result);
        props.get_all_compnay_users().then(result => {
          setCompanyUsersList(result);
          });
        }).catch(err => {
          props.get_all_compnay_users().then(result => {
            setCompanyUsersList(result);
            })
          });
          
          props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // console.log(polygonCords);

  const polygoncords = (list) =>{
    setPolygonCords(list);
}


  //company territories
  const  {companyTerritories}  = territoriesList || {};
  const  options  = companyTerritories && companyTerritories.map(item => {
    return <option id={item.id} key={item.id} data-key={item.id}>{item.territoryName}</option>
    });

  //company users
  const  userOptions  = companyUsersList && companyUsersList.map(item => {
    const val= {  id: item.id, firstName: item.firstName,lastName: item.lastName,users:  item.firstName +' ' +item.lastName,label: item.firstName +' ' +item.lastName,value: item.firstName +' ' +item.lastName};
    return val;
  });

//set zipcode 
const setZipcodeHandler = (e) => {
     const selectedIndex = e.target.options.selectedIndex;
   let territoryId=e.target.options[selectedIndex].getAttribute('data-key');
  setTerritoryId(territoryId);
  setMapOpen(false);
  setZipcodeList([]);
   if(e.target.value !== "0"){   
    Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
    Geocode.fromAddress(e.target.value).then(
       (response) => {
         //console.log(response.results[0]);
              setMapLatLongBounds(response.results[0].geometry.bounds);
              setMapViewreportBounds(response.results[0].geometry.viewport);
               const data = response.results[0].geometry.location;
               setMapOpen(true);
               setMapLatLong({lat: data.lat,lng: data.lng});
                  },
       (error) => {
         setMapLatLong({lat:-34.397, lng:150.644});
         setMapOpen(true);
       });
    var value = companyTerritories.filter(function(item) {
        //console.log(item);
        return item.territoryName === e.target.value
    })
   let zipcodes= [];
   value && value.forEach(companySubterritory => {
       const zipcodesWithCityName = companySubterritory.zipcode.map(zip => {
         const val= {id:zip.id,label: zip.zipCode,value:zip.zipCode,zipCode:zip.zipCode,boundaries: zip.boundaries};
         return val;
           })
      zipcodes= [...zipcodes,...zipcodesWithCityName];
   });
  //  let boundaries = value.map(companySubterritory => {
  //   return companySubterritory.zipcode.map(zip => zip.boundaries.map(polygon => polygon.coordinates.map(coordinate => {
  //     return {
  //             lng : coordinate.longitude,
  //             lat : coordinate.latitude
  //           }
  //   })))
  // });
  // console.log("boundaries======>")
  // console.log(boundaries)
   setZipcode(zipcodes);
  }else{
    setMapOpen(false);
    setZipcode([]);
    setZipcodeList([]);
    setPolygonCords([]);
  }

 }    




  //console.log(selectedTerritoryMap);
//   const handlePolygonChange = (e, index,data) => {
//     const list = [...inputList];
//    list[index].polygon = e;
//     setInputList(list);
//     setSelectedTerritoryMap(index);
    
//     if(e){
//       const getTerritory = list[index]; 
    
//     Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
//     Geocode.fromAddress(getTerritory.companyTerritory).then(
//        (response) => {
//                const data = response.results[0].geometry.location;
//                setMapOpen(true);
//                setMapLatLong({lat: data.lat,lng: data.lng});
//                   },
//        (error) => {
//          setMapLatLong({lat:-34.397, lng:150.644});
//        });
//       }else{
//         setMapOpen(false);
//         setSelectedTerritoryMap([]);
//       }
//   };

      

    

    const validate = (e) => {
        let territoriesVal = territoriesValidation(territoryId);
        let subTerritoryVal = subTerritoryValidation(subTerritory);
        let zipCodeVal = zipcodeValidation(zipcodeList);
        // let polygonsVal = polygonsValidation(polygonCords);
        //console.log(territoriesVal,subTerritoryVal,zipCodeVal,polygonsVal)
          if( territoriesVal === true  && subTerritoryVal === true && zipCodeVal === true ){
            return true;
          }
          //}
      }
      
      // function polygonsValidation(polygonCords) { 
      //   if (polygonCords.length === 0) {
      //     setError(prevState => ({
      //         ...prevState,
      //         userPolygonCordsErr: 'Please Draw Polygon on map'
      //     }));
      //     return false;
      //   }
      //    if(polygonCords.length !== 0){
      //      if(polygonCords.coordinates.length>0){
      //     setError(prevState => ({
      //         ...prevState,
      //         userPolygonCordsErr: ''
      //     }));
      //     return true;
      //   }
      //   }
      // }
      function zipcodeValidation(zipcodeList) { 
        if (zipcodeList.length === 0) {
          setError(prevState => ({
              ...prevState,
              userZipcodesErr: 'Please Select Zipcode'
          }));
          return false;
        }
         if(zipcodeList.length > 0){
          setError(prevState => ({
              ...prevState,
              userZipcodesErr: ''
          }));
          return true;
        }
      }
      function territoriesValidation(territoryId) { 
        if (territoryId === "0" || territoryId === null || territoryId === undefined) {
          setError(prevState => ({
              ...prevState,
              userTerritoryErr: 'Please Select Territory'
          }));
          return false;
        }
         if(territoryId > 0){
          setError(prevState => ({
              ...prevState,
              userTerritoryErr: ''
          }));
          return true;
        }
      }

      function subTerritoryValidation(subTerritory) { 
        if(!subTerritory) {
          setError(prevState => ({
              ...prevState,
              userSubTerritoryErr: 'Please Enter Sub Territory Name'
          }));
        return false;
      }
      // if (!/^[a-zA-Z ]+$/.test(subTerritory)){
      //     setError(prevState => ({
      //         ...prevState,
      //         userSubTerritoryErr: 'Please Enter Only Characters'
      //     }));
      //   return false;
      // }
      if(subTerritory) {
          setError(prevState => ({
              ...prevState,
              userSubTerritoryErr: ''
          }));
        return true;
      }
      }

    // Create User
    const saveSubTerritoryHandler = async (e,addnew) =>{
       e.preventDefault();
       //const zipcodesData=zipcodeList.map(zipcode => {return zipcode.label});
       let companyTerritories = territoriesList.companyTerritories.filter(territory => territory.id === parseInt(territoryId));
      
       companyTerritories=companyTerritories.map(item => {
         return {
           id: item.id,
           territoryName:item.territoryName,
           isActive: item.isActive
         }
       });
       //let usersDetails = companyUsersList.map(item => {return {id: item.id,firstName: item.firstName,lastName: item.Company}})
        //console.log(companyTerritories)
       if(validate()){
        
           const payload_data={
               // companyTerritoryId: parseInt(territoryId),
                usersList: usersList,
                companyTerritoryDTO:companyTerritories[0],
                polygonsDTO: polygonCords,
                territoryName: subTerritory,
                // zipCodeDTOS: zipcodesData
                zipCodeDTOS: zipcodeList
           }
          //  console.log(payload_data);
        props.create_company_subterritory(payload_data,addnew,props.history);
        
        }
    }


    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Add SubTerritory</h1>
                    <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show} 
                     onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.companyalert.msg}</p>
                    </Alert>
                    {options ? 
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                      <label htmlFor="territory">Assign Territory</label>
                                    <select
                                         className="form-control"
                                         onChange={setZipcodeHandler}
                                    >
                                      <option value="0">Assign Territory</option>
                                           {options} 
                                        </select>
                                {error.userTerritoryErr ? <span className="error-span">{error.userTerritoryErr}</span>:null}
                                </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                    <label htmlFor="territory">Territory Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Enter Territory Name"
                                        value={subTerritory}
                                        autoComplete="off"
                                        onChange={e => setSubTerritory(e.target.value)}
                                    />
                                  {error.userSubTerritoryErr ? <span className="error-span">{error.userSubTerritoryErr}</span>:null}

                                    </div>
                                      
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                      <label htmlFor="zipcodes">Select Zipcodes</label>
                                    <MultiSelect options={zipCode || []} value={zipcodeList} placeholder="Select Zipcode" onChange={setZipcodeList} />
                                  {error.userZipcodesErr ? <span className="error-span">{error.userZipcodesErr}</span>:null}
                                    
                                    </div>
                                </div>

                                </div>
                                <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                      <label htmlFor="users">Assign Users</label>
                                    <MultiSelect options={userOptions || []} value={usersList} placeholder="Assign Users" onChange={setUsersList} />
                                    </div>
                                </div>
                                </div>
                                {mapOpen ?
                                <React.Fragment>
                                <label htmlFor="polygon">Assign Polygon:</label>
                                <DrawMap
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                  lat={mapLatLong.lat}
                                  lng={mapLatLong.lng}
                                  north= {mapLatLongBounds ? mapLatLongBounds.northeast.lat: mapViewreportBounds.northeast.lat}
                                  south= {mapLatLongBounds ? mapLatLongBounds.southwest.lat: mapViewreportBounds.southwest.lat}
                                  west= {mapLatLongBounds ? mapLatLongBounds.southwest.lng: mapViewreportBounds.southwest.lng}
                                  east= {mapLatLongBounds ? mapLatLongBounds.northeast.lng: mapViewreportBounds.northeast.lng}
                                setpolygonsDTO={polygoncords}
                                polygonCoordinates={polygonCords}
                                setZipcodeBoundaries={zipcodeList}
                                />
                                {/* {error.userPolygonCordsErr ? <span className="error-span">{error.userPolygonCordsErr}</span>:null} */}
                                <br/>
                                </React.Fragment>:null}
                                
                            <button className="btn btn-primary" onClick={(e)=>saveSubTerritoryHandler(e,false)}>Save</button> &nbsp;&nbsp;
                            <button className="btn btn-primary" onClick={(e)=>saveSubTerritoryHandler(e,true)}>Save & Add New Territory</button><br/>
                            {/* {inputlistErr ? <span className="error-span">{inputlistErr}</span>:null} */}

                        </form>
                    </div>
                    :<Loader />}
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
  return {
    companyalert: state.Company.alert,
  }
}

export default connect(mapStateToProps,{create_company_subterritory,get_all_compnay_territories,get_all_compnay_users,closeAlert})(AddSubTerritory);