import { LOGIN_CHECK, LOGIN_CHECK_ERROR,RESET_PASSWORD_ERROR,FORGOT_PASSWORD,FORGOT_PASSWORD_ERROR,CHANGE_PASSWORD,CHANGE_PASSWORD_ERROR,GET_PROFILE,GET_PROFILE_ERROR,CLOSE_ALERT,SAVE_PROFILE,SAVE_PROFILE_ERROR,UPDATE_PROFILE_DATA } from '../../types';
import axios from 'axios';
import GRANDWELCOME_BASE_URL from '../../../configs/baseUrls';
import { getSession } from '../../Auth/sessionData';

export const setLogin = (UserName,Password,history) => async dispatch => {
    try {
    const payload_data = {
        email: UserName,
        password: Password
      };
      const config = {
        method: 'post',
        url: GRANDWELCOME_BASE_URL+'/registration/login',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: payload_data
      }
      await axios(config).then(result => {

        localStorage.setItem("Login_Data", JSON.stringify(result.data));
        dispatch({
            type: LOGIN_CHECK,
            payload: result.data
        });
        //console.log(result.data.token);
        //
        if(result.data.userType === "PRODUCT_ADMIN"){history.push("/CompanyList");}
        if(result.data.userType === "COMPANY_ADMIN"){history.push("/CompanyDashboard");}
        if(result.data.userType === "COMPANY_USER"){history.push("/Dashboard");}
        
        // window.location.reload(false);

        return 1;
        }
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
                //;
                dispatch({
                    type: LOGIN_CHECK_ERROR,
                    payload: 'Please enter valid Username/Password'
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: LOGIN_CHECK_ERROR,
                  payload: 'There is no token in header...'
              })
          } else if (err.response.status === 500) {
                dispatch({
                    type: LOGIN_CHECK_ERROR,
                    payload: "Internal error"
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: LOGIN_CHECK_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: LOGIN_CHECK_ERROR,
                payload: "Something went wrong"
            })
          }
        });
    
    
       
    }
    catch (e) {
        dispatch({
            type: LOGIN_CHECK_ERROR,
            payload: "Something went wrong",
        })
    }

}



export const reset_password = (payload_data,verifiactionCode,history) => async dispatch => {
  try {
    const config = {
      method: 'post',
      url: GRANDWELCOME_BASE_URL+'/registration/reset-password?verificationCode='+ verifiactionCode,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: payload_data
    }
    axios(config).then(result => {
        history.push('/');
      }
    )
      .catch(err => {
        if (err.response) {
         // console.log(err.response.data);
          if (err.response.status === 400) {
              //;
              dispatch({
                  type: RESET_PASSWORD_ERROR,
                  payload: 'Please enter valid Username/Password'
              })
          }else if (err.response.status === 401) {
            ;
            dispatch({
                type: RESET_PASSWORD_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: RESET_PASSWORD_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: RESET_PASSWORD_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: RESET_PASSWORD_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: RESET_PASSWORD_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const forgot_password = (userEmail) => async dispatch => {
  try {
    const payload_data={
      email: userEmail
    }
    const config = {
      method: 'post',
      url: GRANDWELCOME_BASE_URL+'/registration/forgot-password',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: payload_data
    }
    axios(config).then(result => {
      dispatch({
        type: FORGOT_PASSWORD,
        payload: result.data.msg
    })
      }
    )
      .catch(err => {
        if (err.response) {
         // console.log(err.response.data);
          if (err.response.status === 400) {
              //;
              dispatch({
                  type: FORGOT_PASSWORD_ERROR,
                  payload: 'Please enter valid Username/Password'
              })
          }else if (err.response.status === 401) {
            ;
            dispatch({
                type: FORGOT_PASSWORD_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: FORGOT_PASSWORD_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: RESET_PASSWORD_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: FORGOT_PASSWORD_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: FORGOT_PASSWORD_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const getProfile = () => async dispatch => {
  try {
    
    const session_data =getSession();
    const config = {
      method: 'get',
      url: GRANDWELCOME_BASE_URL+'/company-user/get-user-details',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${session_data.token}`
      },
    }
    let profileData= await  axios(config).then(result => {
      
      dispatch({
        type: GET_PROFILE,
        payload: result.data
    })
      return result.data;
      }
    )
      .catch(err => {
        if (err.response) {
          //console.log(err.response.data);
          if (err.response.status === 400) {
              //;
              dispatch({
                  type: GET_PROFILE_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            ;
            dispatch({
                type: GET_PROFILE_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_PROFILE_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_PROFILE_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_PROFILE_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
     return profileData;
     
  }
  catch (e) {
      dispatch({
          type: SAVE_PROFILE_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const saveProfile = (payload_data) => async dispatch => {
  try {
    const session_data =getSession();
    const config = {
      method: 'post',
      url: GRANDWELCOME_BASE_URL+'/company-user/save-profile',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${session_data.token}`
      },
      data: payload_data
    }
    axios(config).then(result => {
      dispatch({
        type: SAVE_PROFILE,
        payload: result.data.msg
    });
    setInterval(() => {
      dispatch({
        type: CLOSE_ALERT,
        payload: false
        
    })
    }, 1000);
      }
    )
      .catch(err => {
        if (err.response) {
         // console.log(err.response.data);
          if (err.response.status === 400) {
              //;
              dispatch({
                  type: SAVE_PROFILE_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            ;
            dispatch({
                type: SAVE_PROFILE_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: SAVE_PROFILE_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: SAVE_PROFILE_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: SAVE_PROFILE_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: SAVE_PROFILE_ERROR,
          payload: "Something went wrong",
      })
  }

}


export const updateProfileData = (name, value) => async dispatch => {
  //;
  dispatch({
      type: UPDATE_PROFILE_DATA,
      payload: {
          name,
          value
      },
      
  })
  
}



export const changePassword = (payload_data) => async dispatch => {
  try {
    const session_data =getSession();
    const config = {
      method: 'post',
      url: GRANDWELCOME_BASE_URL+'/company-user/change-password',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${session_data.token}`
      },
      data: payload_data
    }
    axios(config).then(result => {
      dispatch({
        type: CHANGE_PASSWORD,
        payload: result.data.msg
    });
    setInterval(() => {
      dispatch({
        type: CLOSE_ALERT,
        payload: false
        
    })
    }, 1000);
      }
    )
      .catch(err => {
        if (err.response) {
         // console.log(err.response.data);
          if (err.response.status === 400) {
              //;
              dispatch({
                  type: CHANGE_PASSWORD_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            ;
            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: CHANGE_PASSWORD_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: CHANGE_PASSWORD_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: CHANGE_PASSWORD_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: CHANGE_PASSWORD_ERROR,
          payload: "Something went wrong",
      })
  }

}