import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { connect } from 'react-redux';
import { create_company_user,get_all_compnay_mail_boxes,get_all_compnay_territories,get_all_compnay_subterritories,closeAlert,updateCompanyUserData,updateCompanyUserTerritories } from '../../../redux/action/compnayAdmin/companyActionCreator';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
// import Multiselect from 'multiselect-react-dropdown';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import Alert from 'react-bootstrap/Alert';
// import DrawMap from '../TerritoryManagement/map'
// import Geocode from 'react-geocode';

const EditUser = (props) =>{
    const [territoriesList,setTerritoriesList] =useState([]);
    // const [territories,setTerritories] =useState([]);
    const [mailBoxList, setMailBoxList] = useState([]);
    
    const [error,setError]=useState({
        userFirstNameErr:'',
        userLastNameErr:'',
        userEmailErr:'',
        userPhoneNumberErr:'',
        // userAddressErr:'',
        userTerritoryErr:'',
        userRoleTypeErr:'',
        passwordErr:'',
    });
    

    useEffect(()=>{
      props.get_all_compnay_subterritories().then(result => {
          setTerritoriesList(result);
          props.get_all_compnay_mail_boxes().then(result => {
            setMailBoxList(result);
            });
          }).catch(err => {
            props.get_all_compnay_mail_boxes().then(result => {
              setMailBoxList(result);
              })
            });
        
            props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    


      const  {companySubTerritoryDTOS}  = territoriesList;
      const  options  = companySubTerritoryDTOS && companySubTerritoryDTOS.map(item => {
        const val= {  companyUserTerritoryId: item.id, territoryName:  item.territoryName,label: item.territoryName,value:item.territoryName};
        return val;
        //return <option id={item.id} key={item.id} value={item.territoryName} data-key={item.id}>{item.territoryName}</option>
          // const val= {  id: item.id, territoryName:  item.territoryName};
          // return val;
        });

      const  mailBoxOptions  = mailBoxList && mailBoxList.map(item => {
        const val= {  id: item.id, emailAddress:  item.emailAddress,label: item.emailAddress,value:item.emailAddress};
        return val;
      });
      let selectedmailBoxOptions=[]
        selectedmailBoxOptions  = props.userDetails.mailBoxDTOS && props.userDetails.mailBoxDTOS.map(item => {
        const val= {  id: item.id, emailAddress:  item.emailAddress,label: item.emailAddress,value:item.emailAddress};
        return val;
      });

      const  selectedSubTerritoriesOptions  = props.userDetails.companyUserTerritoryDTOS && props.userDetails.companyUserTerritoryDTOS.map(item => {
        const val= {  companyUserTerritoryId: item.companyUserTerritoryId,territoryName: item.territoryName, label: item.territoryName,value:item.territoryName};
        return val;
      });
      
      // const zipcodeList =(e,id,index)=>{
      //     id.zipCodeDTOS =e;
      //     const list = [...inputList];
      //     list[index] = id;
      //     setInputList(list);
      // }
      
      const mailBoxHandler =(e)=>{
        if(e.length === 0){
          props.updateCompanyUserData("mailBoxDTOS",'');
        }else{
          props.updateCompanyUserData("mailBoxDTOS",e);
        }
        //props.updateCompanyUserData("mailBoxDTOS",e);
      }

      const subTerritoryHandler =(e)=>{
        if(e.length === 0){
          props.updateCompanyUserData("companyUserTerritoryDTOS",'');
        }else{
          props.updateCompanyUserData("companyUserTerritoryDTOS",e);
        }
        //props.updateCompanyUserData("mailBoxDTOS",e);
      }

    //   const setTerritoryInputList = (list) =>{
    //     setInputList(list);
    // }
    //console.log(inputList);
  //  //show more
  //  const showMoreHandler = (e) => {
  //    setShowMap(false);
  //   setTerritories(e.target.value);
  //   setEditPolygonView(false);
  //  // console.log(id);
  //   if (inputList.length === 0 && e !== "0") {
  //      const selectedIndex = e.target.options.selectedIndex;
  //      let territoryId=e.target.options[selectedIndex].getAttribute('data-key')
  //      var value = companyTerritories.filter(function(item) {
  //     //console.log(item);
  //     return item.territoryName === e.target.value
  //   })
    

  //   let zipcodes= [];
  //   value && value.forEach(item => {
  //       const zipcodesWithCityName = item.zipcode.map(zip => {
  //         const val= {label: zip.zipCode,value:zip.zipCode};
  //         return val;
  //           })
  //      zipcodes= [...zipcodes,...zipcodesWithCityName];
  //   });
  //   //setZipcodeList(zipcodes);
  //   setAddMore(true);
  //   setInputList([...inputList, {companyTerritoryId:territoryId,polygon:'', territoryName: "", zipCode: zipcodes,zipCodeDTOS :[], polygonsDTO :[],companyTerritory:e.target.value }]);
  //    }
  //   // setAddMoreBtn(false);
  // }

  // // handle input change
  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...inputList];
  //   list[index][name] = value;
  //   setInputList(list);
  // };
  
  // //console.log(inputList);
  
  // const handlePolygonChange = (e, index) => {
  //   if(e){
  //     const list = [...inputList];
  //  list[index].polygon = e;
  //   setInputList(list);
  //   setSelectedTerritoryMap(index);
      
  //   Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
  //   Geocode.fromAddress(territories).then(
  //      (response) => {
  //              const data = response.results[0].geometry.location;
  //              setMapOpen(true);
  //              setMapLatLong({lat: data.lat,lng: data.lng});
  //                 },
  //      (error) => {
  //        setMapLatLong({lat:-34.397, lng:150.644});
  //      });
  //     }else{
  //       setMapOpen(false);
  //       setSelectedTerritoryMap([]);
  //     }
  // };


  // const showPolygonsHandler = (e, item) => {
  //  //console.log(item.companyTerritoryDTO.territoryName);
  //  setSelectedInputList(item);
   
  //  setMapOpen(false);
  //   if(e){
  //     setShowMap(false);
  //     setEditPolygonView(true);
  //   Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
  //   Geocode.fromAddress(item.companyTerritoryDTO.territoryName).then(
  //      (response) => {
  //              const data = response.results[0].geometry.location;
  //             //console.log(data);
  //              setShowMap(true);
  //              setMapLatLong({lat: data.lat,lng: data.lng});
  //                 },
  //      (error) => {
  //        setMapLatLong({lat:-34.397, lng:150.644});
  //      });
  //     }else{
  //       setShowMap(false);
  //     }
  // };

  // // handle click event of the Remove button
  // const handleRemoveClick = index => {
  //   const list = [...inputList];
  //   list.splice(index, 1);
  //   setInputList(list);
  //   if (index === 0) {
  //     setAddMore(false);
  //     setEditPolygonView(false);
  //     setInputList([]);
  //   }
  // };


  // // handle click event of the Add button
  // const handleAddClick = (e) => {
  //   setEditPolygonView(false);
  //   if(e.target.value !== "0"){
  //     setMapOpen(false);
  //     setTerritories(e.target.value);
  //     const selectedIndex = e.target.options.selectedIndex;
  //     let territoryId=e.target.options[selectedIndex].getAttribute('data-key')
  //   var value = companyTerritories.filter(function(item) {
  //     //console.log(item);
  //     return item.territoryName === e.target.value
  //   })
  //   let zipcodes= [];
  //   value && value.forEach(item => {
     
  //       const zipcodesWithCityName = item.zipcode.map(zip => {
  //         const val= {label: zip.zipCode,value:zip.zipCode};
  //         return val;
  //           //return zip;
  //           })
  //      zipcodes= [...zipcodes,...zipcodesWithCityName];
  //   });
  //   setInputList([...inputList, {companyTerritoryId:territoryId,polygon:'', territoryName: "", zipCode: zipcodes,zipCodeDTOS :[],polygonsDTO :[],companyTerritory:e.target.value }]);
  // }
  // };
    //console.log(territories);
    const toggleActiveHandler = (e,statusType) =>{
        //console.log(statusType);
        //
        if(statusType === "ACTIVE"){
            props.updateCompanyUserData("status","INACTIVE");
        }
        if(statusType === "INACTIVE"){
            props.updateCompanyUserData("status","ACTIVE");
        }
      
    }

      // //delete Territory  
      // const deleteTerritoryHandler = (event, id) => {
      //  // console.
      //   event.preventDefault();
      //   const updateTerritoryData = props.userDetails.companyUserTerritoryDTOS.filter(item => item.companyUserTerritoryId !== id);
      //   props.updateCompanyUserData("companyUserTerritoryDTOS", updateTerritoryData);
      // }

    const validate = (e) => {
        // let territoriesVal = territoriesValidation(territories);
        let userFirstNameVal = userFirstNameValidation(props.userDetails.firstName);
        let userLastNameVal = userLastNameValidation(props.userDetails.lastName);
        let userEmailVal = userEmailValidation(props.userDetails.email);  
        // let userAddressVal = userAddressValidation(userAddress);  
        let userPhoneNumberVal = userPhoneNumberValidation(props.userDetails.phoneNumber);
        let userRoleTypeVal = userRoleTypeValidation(props.userDetails.userType);
        //let passwordVal = passwordValidation(password);
       
        if(  userRoleTypeVal === true  && userFirstNameVal === true && userLastNameVal === true && userEmailVal === true && userPhoneNumberVal === true ){
          return true;
        }
      }
    
      // function territoriesValidation(territories) { 
      //   if (!territories || territories === "0") {
      //     setError(prevState => ({
      //         ...prevState,
      //         userTerritoryErr: 'Please Assign Territory'
      //     }));
      //     return false;
      //   }else if(territories && territories !== "0"){
      //     setError(prevState => ({
      //         ...prevState,
      //         userTerritoryErr: ''
      //     }));
      //     return true;
      //   }
      // }
      function userFirstNameValidation(userFirstNameVal) { 
          
        if(!userFirstNameVal) {
            setError(prevState => ({
                ...prevState,
                userFirstNameErr: 'Please Enter First Name'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(userFirstNameVal)){
        //     setError(prevState => ({
        //         ...prevState,
        //         userFirstNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(userFirstNameVal) {
            setError(prevState => ({
                ...prevState,
                userFirstNameErr: ''
            }));
          return true;
        }
      }
      function userLastNameValidation(userLastNameVal) { 
          
        if(!userLastNameVal) {
            setError(prevState => ({
                ...prevState,
                userLastNameErr: 'Please Enter Last Name'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(userLastNameVal)){
        //     setError(prevState => ({
        //         ...prevState,
        //         userLastNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(userLastNameVal) {
            setError(prevState => ({
                ...prevState,
                userLastNameErr: ''
            }));
          return true;
        }
      }
      function userEmailValidation(userEmailVal) { 
          if(!userEmailVal) {
            setError(prevState => ({...prevState,userEmailErr: 'Please Enter Email'}));
            return false;
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmailVal)){
            setError(prevState => ({
                ...prevState,
                userEmailErr: 'Please Enter  Valid Email'
            }));
            return false;
          }
          if(userEmailVal && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmailVal)) {
            setError(prevState => ({
                ...prevState,
                userEmailErr: ''
            }));
            return true;
          }
      }
      // function userAddressValidation(userAddress) {    
      //   if (userAddress === "") {
      //       setError(prevState => ({...prevState,userAddressErr: 'Please Enter Address1'}));     
      //     return false;
      //   }
      //   // if (!/^[0-9a-zA-Z]{6,}$/.test(userAddress)) {
      //   //     setError(prevState => ({...prevState,userAddressErr: 'Please Enter mininum 6 characters'}));
      //   //   return false;
      //   // } 
      //   if(userAddress !== "") {
      //       setError(prevState => ({...prevState,userAddressErr: ''}));
      //     return true;
      //   }
      // }
     
      function userPhoneNumberValidation(userPhoneNumber) {
        // if (userPhoneNumber === "") {
        //     setError(prevState => ({...prevState,userPhoneNumberErr: 'Please Enter Phone Number'}));     
        //   return false;
        // }
        // if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(userPhoneNumber)) {
        //     setError(prevState => ({...prevState,userPhoneNumberErr: 'Please Enter Valid Phone Number'}));
        //   return false;
        // }
          if(userPhoneNumber !== '' && !/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(userPhoneNumber) && userPhoneNumber !== null){
              setError(prevState => ({...prevState,userPhoneNumberErr: 'Please Enter Valid Phone Number'}));
              return false;
            } else {
                setError(prevState => ({...prevState,userPhoneNumberErr: ''}))
                return true;
            }
      }
    
      function userRoleTypeValidation(userRoleTypeVal) {
        if (!userRoleTypeVal || userRoleTypeVal === "0") {
            setError(prevState => ({
                ...prevState,
                userRoleTypeErr: 'Please Select User Role'
            }));
            return false;
          }else if(userRoleTypeVal && userRoleTypeVal !== "0"){
            setError(prevState => ({
                ...prevState,
                userRoleTypeErr: ''
            }));
            return true;
          }
      }

    // Update User
    const updateCompnayUserHandler = async (e) =>{
        e.preventDefault();
        // inputList.forEach(item => item.zipCodeDTOS = item.zipCodeDTOS.map(zipcode => zipcode.label));
        const assignedTerritoryList = props.userDetails.companyUserTerritoryDTOS && props.userDetails.companyUserTerritoryDTOS.map(item =>{
          return {companySubTerritoryId: item.companyUserTerritoryId,subTerritoryName:item.territoryName}
        })
        if(validate()){
            const payload_data={
                    companySubTerritoryDTOS: assignedTerritoryList || [], 
                    // companyUserTerritories: assignedTerritoryList, 
                    email: props.userDetails.email,
                    mailBoxDTOS: props.userDetails.mailBoxDTOS,
                    firstName: props.userDetails.firstName,
                    status: props.userDetails.status,
                    lastName: props.userDetails.lastName,
                    phoneNumber: props.userDetails.phoneNumber,
                    userType: props.userDetails.userType,
                    companyUserId: props.userDetails.id,
              };
              //console.log(payload_data);
             props.create_company_user(payload_data,props.history);
        
        }
    }


    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Edit Company User</h1>
                    <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show} 
                     onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.companyalert.msg}</p>
                    </Alert>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <h4>User Details:</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="firstname">First Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter First Name"
                                        value={props.userDetails.firstName || ''}
                                        onChange={(e)=>props.updateCompanyUserData("firstName",e.target.value)}
                                        />
                                        {error.userFirstNameErr ? <span className="error-span">{error.userFirstNameErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="lastname">Last Name</label>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Enter Last Name"
                                    value={props.userDetails.lastName || ''}
                                    onChange={(e)=>props.updateCompanyUserData("lastName",e.target.value)}
                                     />
                                     {error.userLastNameErr ? <span className="error-span">{error.userLastNameErr}</span>:null}
                                </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="Email">User Email</label>
                                        <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Enter Email"
                                        value={props.userDetails.email || ''}
                                        onChange={(e)=>props.updateCompanyUserData("email",e.target.value)}
                                         />
                                         {error.userEmailErr ? <span className="error-span">{error.userEmailErr}</span>:null}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="number">Phone Number</label>
                                        <NumberFormat 
                                            format="(###)-###-####" 
                                            mask="_" 
                                            className="form-control" 
                                            placeholder="Enter Phone Number" 
                                            value={props.userDetails.phoneNumber || ''}
                                            onChange={(e)=>props.updateCompanyUserData("phoneNumber",e.target.value)}
                                            />
                                            {error.userPhoneNumberErr ? <span className="error-span">{error.userPhoneNumberErr}</span>:null}
                                        
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="userrole">Select User Role</label>
                                        <select
                                         className="form-control"
                                            defaultValue={props.userDetails.userType}
                                            onChange={(e)=>props.updateCompanyUserData("userType",e.target.value)}
                                            required 
                                            >
                                            <option value="0">Select User Role</option>
                                            <option value="COMPANY_ADMIN">Admin</option>
                                            <option value="COMPANY_USER">User</option>
                                        </select>
                                        {error.userRoleTypeErr ? <span className="error-span">{error.userRoleTypeErr}</span>:null}

                                    </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                  <label htmlFor="mialbox" >Assigned Mail Box</label>
                                  <MultiSelect  options={mailBoxOptions ||[]} value={selectedmailBoxOptions || []} placeholder="Assign Mail Box" onChange={mailBoxHandler}/>
  
                                  {/* <Multiselect
                                        isObject={true}
                                        displayValue="emailAddress"
                                        onRemove={function noRefCheck(selectedList,removedItem){
                                          props.updateCompanyUserData("mailBoxDTOS",selectedList)
                                        }}
                                        onSelect={function noRefCheck (selectedList,selectedItem){
                                          props.updateCompanyUserData("mailBoxDTOS",selectedList)
                                        }}
                                        options={mailBoxOptions}
                                        selectedValues={selectedmailBoxOptions}
                                        placeholder="Assign Mail Box"
                                    /> */}
                                    

                                  </div>
                                </div>
                            
                                </div>
                               
                                <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                    <label htmlFor="territory">Assigned Territory</label>
                                    <MultiSelect  options={options || []} value={selectedSubTerritoriesOptions || []} placeholder="Assign Territory" onChange={subTerritoryHandler}/>
  
                                {error.userTerritoryErr ? <span className="error-span">{error.userTerritoryErr}</span>:null}
                                </div>
                                </div>
                                
                                <div className="col-md-2">
                                    <div className="form-group">
                                      <label htmlFor="toggle" style={{visibility:'hidden',display:'block'}}>Toggle Map</label>
                                      <BootstrapSwitchButton
                                            checked={props.userDetails.status === "INACTIVE" ? false:true}
                                            onlabel='Active'
                                            offlabel='Inactive'
                                            onChange={(e)=>toggleActiveHandler(e,props.userDetails.status)}
                                            width={100} 
                                        />
                                    </div>
                                </div>
                                </div>
                                
                                  
                            <button className="btn btn-primary" onClick={updateCompnayUserHandler}>Update User Details</button><br/>
                            {/* {inputlistErr ? <span className="error-span">{inputlistErr}</span>:null} */}

                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
  return {
    companyalert: state.Company.alert,
    userDetails: state.Company.companyUserDetails,
  }
}

export default connect(mapStateToProps,{create_company_user,get_all_compnay_mail_boxes,get_all_compnay_subterritories,get_all_compnay_territories,updateCompanyUserData,updateCompanyUserTerritories,closeAlert})(EditUser);