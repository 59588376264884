import React, {useState} from 'react';
import { Link,withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgot_password } from '../../redux/action/accountAction/loginAction';
import Alert from 'react-bootstrap/Alert';
import Footer from '../../Components/common/Footer/footer';

const ForgotPassword = (props) =>{
   
    const [forgotUserName, setForgotUserName] = useState('');
    const [forgotUserNameErr, setForgotUserNameErr] = useState("");


    const validate = (e) => {
        if(!forgotUserName ){
            setForgotUserNameErr('Please Enter Email');
            return false;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(forgotUserName)) {
            setForgotUserNameErr('Invalid email address');
          return false;
        }
        if(forgotUserName && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(forgotUserName)){
            setForgotUserNameErr('');
        }
        
        return true;
      }

    const loginHandler = async (e) =>{
        e.preventDefault();
        if(validate()){
            props.forgot_password(forgotUserName);
        }
    }; 

    return(<React.Fragment>
            <div className="lt-breadcrumb">
            <div className="breadcrumb-content">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div className="title">
                            <h1>Forgot Password</h1>
                        </div>
                        <ol className="breadcrumb align-self-center">
                            <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Forgot Password</li>
                        </ol>                        
                    </div>
                </div>
            </div>
        </div>

        <div className="lt-page-content bg-color">
            <div className="lt-section">
                <div className="section-content lt-account section-padding">
                    <div className="account-content bg-white">
                        <h2>Reset Account Password</h2>
                        <form action="login.html" className="lt-form">

                            <div className="form-group">
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email Address"
                                    value={forgotUserName || ''}
                                    onChange={e => setForgotUserName(e.target.value)}
                                />
                                <div className="input-addon">
                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                </div>
                                {forgotUserNameErr ? <span className="error-span">{forgotUserNameErr}</span>:null}
                            </div>

                            <button 
                            className="btn btn-primary d-block"
                            onClick={loginHandler}
                            style={{width: '-webkit-fill-available'}}
                            >Reset Password</button>
                             {props.loginErr ? <span className="error-span">{props.loginErr}</span>:null}
                             <br/>
                             <Alert id="alert" className="alert" variant={props.forgotPasswordErr.resultType} show={props.forgotPasswordErr.show} >
                                <p>{props.forgotPasswordErr.msg}</p>
                              </Alert>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer></Footer>
        </React.Fragment>);
}

const mapStateToProps = state => {
    return {
        forgotPasswordErr: state.Login.alert,
    }
};


export default connect(mapStateToProps,{forgot_password})(withRouter(ForgotPassword));