import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { connect } from 'react-redux';
import { create_company,activeterritoryListing,closeAlert } from '../../../redux/action/productAdmin/productActionCreator';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import Alert from 'react-bootstrap/Alert';

const CreateCompnay = (props) =>{
    const [companyName,setCompanyName] =useState('');
    const [companyEmail,setCompnayEmail] =useState('');
    const [companyAddress1,setCompanyAddress1] =useState('');
    const [companyAddress2,setCompanyAddress2] =useState('');
    const [companyCity,setCompanyCity] =useState('');
    const [companyState,setCompanyState] =useState('');
    const [companyZipcode,setCompanyZipcode] =useState('');
    const [isActive,setisActive] =useState(true);
    const [companyPhoneNumber,setCompanyPhoneNumber] =useState('');
    const [companyAlternateNumber,setCompanyAlternateNumber] =useState('');
    const [adminFirstName,setAdminFirstName] =useState('');
    const [adminLastName,setAdminLastName] =useState('');
    const [adminEmail,setAdminEmail] =useState('');
    const [adminPhoneNumber,setAdminPhoneNumber] =useState('');
    const [territoriesList,setTerritoriesList] =useState([]);
    const [territories,setTerritories] =useState([]);
    // const [password,setPassword] =useState('');
    const [error,setError]=useState({
        companyNameErr:'',
        companyEmailErr:'',
        companyAddress1Err:'',
        companyCityErr:'',
        companyStateErr:'',
        companyZipcodeErr:'',
        // companyAddress2Err:'',
        compnayTerritoryErr:'',
        companyPhoneNumberErr:'',
        companyAlternateNumberErr:'',
        adminFirstNameErr:'',
        adminLastNameErr:'',
        adminEmailErr:'',
        adminPhoneNumberErr:'',
        // adminGenderErr:'',
        // passwordErr:'',
    });

    useEffect(()=>{
      props.activeterritoryListing().then(result => {
          setTerritoriesList(result);
          });
          props.closeAlert(false);
          // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const  {companyTerritories}  = territoriesList;
   const  options  = companyTerritories && companyTerritories.map(item => {
        const val= {  id: item.id, territoryName:  item.territoryName,label: item.territoryName,value: item.territoryName}
        return val;
      });
    const toggleActiveHandler = (e) =>{
      setisActive(!isActive);
    }

    const validate = (e) => {
        let companyNameVal = companyNameValidation(companyName);
        let companyEmailVal = companyEmailValidation(companyEmail);
        let companyAddress1Val = companyAddress1Validation(companyAddress1);
        // let companyAddress2Val = companyAddress2Validation(companyAddress2);
        let companyCityVal = companyCityValidation(companyCity);
        let companyStateVal = companyStateValidation(companyState);
        let companyZipcodeVal = companyZipcodeValidation(companyZipcode);
        let territoriesVal = territoriesValidation(territories);
        let companyPhoneNumberVal = companyPhoneNumberValidation(companyPhoneNumber);
        let companyAlternateNumberVal = companyAlternateNumberValidation(companyAlternateNumber);
        let adminFirstNameVal = adminFirstNameValidation(adminFirstName);
        let adminLastNameVal = adminLastNameValidation(adminLastName);
        let adminEmailVal = adminEmailValidation(adminEmail);
        let adminPhoneNumberVal = adminPhoneNumberValidation(adminPhoneNumber);
        // let adminGenderVal = adminGenderValidation(adminGender);
        // let passwordVal = passwordValidation(password);
        if(companyNameVal === true && companyCityVal === true && companyStateVal === true && companyZipcodeVal === true &&  companyEmailVal === true && territoriesVal === true && companyAddress1Val === true  && companyPhoneNumberVal === true && companyAlternateNumberVal === true && adminFirstNameVal === true && adminLastNameVal === true && adminEmailVal === true && adminPhoneNumberVal === true ){
          return true;
        }
      }
      

      function companyCityValidation(companyCity) { 
        if(!companyCity) {
          setError(prevState => ({
              ...prevState,
              companyCityErr: 'Please Enter City'
          }));
        return false;
      }
      // if (!/^[a-zA-Z ]+$/.test(companyCity)){
      //     setError(prevState => ({
      //         ...prevState,
      //         companyCityErr: 'Please Enter Only Characters'
      //     }));
      //   return false;
      // }
      if(companyCity) {
          setError(prevState => ({
              ...prevState,
              companyCityErr: ''
          }));
        return true;
      }
      }

      function companyStateValidation(companyState) { 
        if(!companyState) {
          setError(prevState => ({
              ...prevState,
              companyStateErr: 'Please Enter State'
          }));
        return false;
      }
      // if (!/^[a-zA-Z ]+$/.test(companyState)){
      //     setError(prevState => ({
      //         ...prevState,
      //         companyStateErr: 'Please Enter Only Characters'
      //     }));
      //   return false;
      // }
      if(companyState) {
          setError(prevState => ({
              ...prevState,
              companyStateErr: ''
          }));
        return true;
      }
      }

      
      function companyZipcodeValidation(companyZipcode) { 
        if(!companyZipcode) {
          setError(prevState => ({
              ...prevState,
              companyZipcodeErr: 'Please Enter Zipcode'
          }));
        return false;
      }
      if (!/^\d+$/.test(companyZipcode)){
          setError(prevState => ({
              ...prevState,
              companyZipcodeErr: 'Please Enter Only Numbers'
          }));
        return false;
      }
      if(companyZipcode && /^\d+$/.test(companyZipcode)) {
          setError(prevState => ({
              ...prevState,
              companyZipcodeErr: ''
          }));
        return true;
      }
      }

      function territoriesValidation(territories) { 
        if(territories.length === 0){
          setError(prevState => ({
              ...prevState,
              compnayTerritoryErr: 'Please Assign Territory'
          }));
          return false;
      }
      if(territories.length > 0){
          setError(prevState => ({
              ...prevState,
              compnayTerritoryErr: ''
          }));
          return true;
      }
      }
      function companyNameValidation(companyName) { 
          
        if(!companyName) {
            setError(prevState => ({
                ...prevState,
                companyNameErr: 'Please Enter Company Name'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(companyName)){
        //     setError(prevState => ({
        //         ...prevState,
        //         companyNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(companyName) {
            setError(prevState => ({
                ...prevState,
                companyNameErr: ''
            }));
          return true;
        }
      }
      function companyEmailValidation(companyEmail) { 
          if(!companyEmail) {
            setError(prevState => ({...prevState,companyEmailErr: 'Please Enter Email'}));
            return false;
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(companyEmail)){
            setError(prevState => ({
                ...prevState,
                companyEmailErr: 'Please Enter  Valid Email'
            }));
            return false;
          }
          if(companyEmail && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(companyEmail)) {
            setError(prevState => ({
                ...prevState,
                companyEmailErr: ''
            }));
            return true;
          }
      }
      function companyAddress1Validation(companyAddress1) {    
        if (companyAddress1 === "") {
            setError(prevState => ({...prevState,companyAddress1Err: 'Please Enter Address'}));     
          return false;
        }
        // if (!/^[0-9a-zA-Z]{3,}$/.test(companyAddress1)) {
        //     setError(prevState => ({...prevState,companyAddress1Err: 'Please Enter mininum 6 characters'}));
        //   return false;
        // } 
        if(companyAddress1 !== "" ) {
            setError(prevState => ({...prevState,companyAddress1Err: ''}));
          return true;
        }
      }
      function companyPhoneNumberValidation(companyPhoneNumber) {
        if (companyPhoneNumber === "") {
            setError(prevState => ({...prevState,companyPhoneNumberErr: 'Please Enter Phone Number'}));     
          return false;
        }
        if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(companyPhoneNumber)) {
            setError(prevState => ({...prevState,companyPhoneNumberErr: 'Please Enter Valid Phone Number'}));
          return false;
        } 
        if(companyPhoneNumber !== "" && /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(companyPhoneNumber)) {
            setError(prevState => ({...prevState,companyPhoneNumberErr: ''}));
          return true;
        } 
      }
      function companyAlternateNumberValidation(companyAlternateNumber) {
        if (companyAlternateNumber !== "") {
          if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(companyAlternateNumber)) {
            setError(prevState => ({...prevState,companyAlternateNumberErr: 'Please Enter Valid Phone Number'}));
          return false;
        } 
        }
        
        if(companyAlternateNumber === "" || /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(companyAlternateNumber)) {
            setError(prevState => ({...prevState,companyAlternateNumberErr: ''}));
          return true;
        } 
      }
      function adminFirstNameValidation(adminFirstName) {
        if(!adminFirstName) {
            setError(prevState => ({...prevState,adminFirstNameErr: 'Please Enter First Name'}));
            return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(adminFirstName)){
        //     setError(prevState => ({...prevState,adminFirstNameErr: 'Please Enter Only Characters'}));
        //     return false;
        // }
        if(adminFirstName ) {
            setError(prevState => ({...prevState,adminFirstNameErr: ''}));
          return true;
        }
      }
      function adminLastNameValidation(adminLastName) {
        if(!adminLastName) {
            setError(prevState => ({...prevState,adminLastNameErr: 'Please Enter First Name'}));
            return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(adminLastName)){
        //     setError(prevState => ({...prevState,adminLastNameErr: 'Please Enter Only Characters'}));
        //     return false;
        // }
        if(adminLastName) {
            setError(prevState => ({...prevState,adminLastNameErr: ''}));
          return true;
        }
      }
      function adminEmailValidation(adminEmail) {
        if(!adminEmail) {
            setError(prevState => ({...prevState,adminEmailErr: 'Please Enter Email'}));
            return false;
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminEmail)){
            setError(prevState => ({...prevState,adminEmailErr: 'Please Enter Valid Email'}));
            return false;
          }
          if(adminEmail && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminEmail)) {
            setError(prevState => ({...prevState,adminEmailErr: ''}));
            return true;
          }
      }
      function adminPhoneNumberValidation(adminPhoneNumber) {
        if (adminPhoneNumber !== "") {
          if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(adminPhoneNumber)) {
            setError(prevState => ({...prevState,adminPhoneNumberErr: 'Please Enter Valid Phone Number'}));
          return false;
        } 
        }
        if(adminPhoneNumber === "" || /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(adminPhoneNumber)) {
            setError(prevState => ({...prevState,adminPhoneNumberErr: ''}));
          return true;
        }
      }
  
    const addCompnayHandler = async (e) =>{
        e.preventDefault();
        const territoryIds=territories && territories.map(item =>{
          return item.id;
        });
        //console.log(territoryIds);
        if(validate()){
            const payload_data={
                companyAdminDetailsDTO: {
                  email: adminEmail,
                  firstName: adminFirstName,
                  lastName: adminLastName,
                  phoneNumber: adminPhoneNumber,
                },
                companyDetailsDTO: {
                  address1: companyAddress1,
                  address2: companyAddress2,
                  companyName: companyName,
                  isActive: isActive,
                  phoneNumber1: companyPhoneNumber,
                  phoneNumber2: companyAlternateNumber,
                  companyEmail: companyEmail,
                  city: companyCity,
                  state: companyState,
                  zipCode: companyZipcode,

                },
                territoryIds: territoryIds
              };
              props.create_company(payload_data,props.history);
        
        }
        else{
          window.scrollTo({top:150,behavior: "smooth"});
        }
    }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Add Company</h1>
                    <Alert id="alert" className="alert" variant={props.alertData.resultType} show={props.alertData.show} 
                    // onClose={() => setShow(false)} dismissible
                    >
                      <p>{props.alertData.msg}</p>
                    </Alert>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <h4>Company Details:</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="company">Company Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Company Name"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                        {error.companyNameErr ? <span className="error-span">{error.companyNameErr}</span>:null}
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="company">Company Email</label>
                                    <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Enter Company Email"
                                    value={companyEmail}
                                    onChange={(e) => setCompnayEmail(e.target.value)}
                                    />
                                    {error.companyEmailErr ? <span className="error-span">{error.companyEmailErr}</span>:null}
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                
                                <div className="col-md-6">
                                        <div className="form-group">
                                            {/* <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter Company Phone Number"
                                            value={companyPhoneNumber}
                                            onChange={(e)=>setCompanyPhoneNumber(e.target.value)}
                                            /> */}
                                            <label htmlFor="number">Phone Number</label>
                                            <NumberFormat 
                                            format="(###)-###-####" 
                                            mask="_" 
                                            className="form-control" 
                                            placeholder="Enter Phone Number" 
                                            value={companyPhoneNumber} 
                                            onChange={(e)=>setCompanyPhoneNumber(e.target.value)}
                                            />
                                            {error.companyPhoneNumberErr ? <span className="error-span">{error.companyPhoneNumberErr}</span>:null}
                                        </div>
                                    </div>
                                <div className="col-md-6">
                                        <div className="form-group">
                                            {/* <input 
                                            type="text" 
                                            name="adress" 
                                            id="adress" 
                                            placeholder="Enter Company Alternate Number" 
                                            className="form-control"
                                            value={companyAlternateNumber}
                                            onChange={(e)=>setCompanyAlternateNumber(e.target.value)}
                                            /> */}
                                            <label htmlFor="altnumber">Alternate Number</label>
                                            <NumberFormat 
                                            format="(###)-###-####" 
                                            mask="_" 
                                            className="form-control" 
                                            placeholder="Enter Alternate Number" 
                                            value={companyAlternateNumber}
                                            onChange={(e)=>setCompanyAlternateNumber(e.target.value)}
                                            />
                                            {error.companyAlternateNumberErr ? <span className="error-span">{error.companyAlternateNumberErr}</span>:null}
                                        
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            <div className="row">
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="address">Company Address</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Company Address"
                                        value={companyAddress1}
                                        onChange={(e)=>setCompanyAddress1(e.target.value)}
                                        />
                                        {error.companyAddress1Err ? <span className="error-span">{error.companyAddress1Err}</span>:null}
                                    </div>
                                </div>
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="address2">Company Address Line 2</label>
                                        <input 
                                        type="text" 
                                        name="adress" 
                                        id="adress" 
                                        placeholder="Enter Company Address Line 2" 
                                        className="form-control"
                                        value={companyAddress2}
                                        onChange={(e)=>setCompanyAddress2(e.target.value)}
                                        />
                                        {error.companyAddress2Err ? <span className="error-span">{error.companyAddress2Err}</span>:null}
                                    </div>
                                </div>
                                </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                      <label htmlFor="city">City</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="City"
                                        value={companyCity}
                                        onChange={(e)=>setCompanyCity(e.target.value)}
                                        />
                                        {error.companyCityErr ? <span className="error-span">{error.companyCityErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="state">State</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="State"
                                        value={companyState}
                                        onChange={(e)=>setCompanyState(e.target.value)}
                                        />
                                        {error.companyStateErr ? <span className="error-span">{error.companyStateErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="zipcode">Zipcode</label>
                                        <input 
                                        type="text" 
                                        name="adress" 
                                        id="adress" 
                                        placeholder="Zipcode" 
                                        className="form-control"
                                        value={companyZipcode}
                                        onChange={(e)=>setCompanyZipcode(e.target.value)}
                                        />
                                        {error.companyZipcodeErr ? <span className="error-span">{error.companyZipcodeErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                      <label htmlFor="territories">Assign Territory</label>
                                    <MultiSelect options={options || []} value={territories} placeholder="Assign Territory" onChange={setTerritories} />
  
                                    {/* <Multiselect
                                    isObject={true}
                                    displayValue="territoryName"
                                    onRemove={function noRefCheck(selectedList,removedItem){
                                      setTerritories(selectedList);
                                    }}
                                    onSelect={function noRefCheck(selectedList,selectedItem){
                                      setTerritories(selectedList);
                                    }}
                                    options={options}
                                    placeholder="Assign Territory"
                                /> */}
                                {error.compnayTerritoryErr ? <span className="error-span">{error.compnayTerritoryErr}</span>:null}
                                </div>
                                </div>
                                </div>
                               <div className="row">
                                  {/* <div className="col-md-4">
                                  <div className="form-group">
                                    <div className="add-photo">
                                          <input type="file" name="add-photo" id="add-photo" className="inputfile form-control inputfile-4" data-multiple-caption="{count} files selected" multiple />
                                          <label htmlFor="add-photo"><i className="fa fa-camera" aria-hidden="true"></i> <span>Add Company Logo</span></label>
                                      </div>
                                  </div>
                                  </div> */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                      <BootstrapSwitchButton
                                            checked={isActive}
                                            onlabel='Active'
                                            offlabel='Inactive'
                                            onChange={toggleActiveHandler}
                                            width={100} 
                                        />
                                    </div>
                                </div>
                                </div>
                           
                            <h4>Company Admin:</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                      <label htmlFor="firstname">First Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="First Name"
                                        value={adminFirstName}
                                        onChange={(e)=>setAdminFirstName(e.target.value)}
                                        />
                                        {error.adminFirstNameErr ? <span className="error-span">{error.adminFirstNameErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="lastname">Last Name</label>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Last Name"
                                    value={adminLastName}
                                    onChange={(e)=>setAdminLastName(e.target.value)}
                                     />
                                     {error.adminLastNameErr ? <span className="error-span">{error.adminLastNameErr}</span>:null}
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="email">Enter Email</label>
                                        <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Email"
                                        value={adminEmail}
                                        onChange={(e)=>setAdminEmail(e.target.value)}
                                         />
                                         {error.adminEmailErr ? <span className="error-span">{error.adminEmailErr}</span>:null}
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Phone Number"
                                        value={adminPhoneNumber}
                                        onChange={(e)=>setAdminPhoneNumber(e.target.value)}
                                        /> */}
                                        <label htmlFor="number">Phone Number</label>
                                        <NumberFormat 
                                            format="(###)-###-####" 
                                            mask="_" 
                                            className="form-control" 
                                            placeholder="Phone Number" 
                                            value={adminPhoneNumber}
                                            onChange={(e)=>setAdminPhoneNumber(e.target.value)}
                                            />
                                            {error.adminPhoneNumberErr ? <span className="error-span">{error.adminPhoneNumberErr}</span>:null}
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <button className="btn btn-primary" onClick={addCompnayHandler}>Add Company</button>
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
  return {
    alertData: state.Product.alert,
  }
}

export default connect(mapStateToProps,{create_company,activeterritoryListing,closeAlert})(CreateCompnay);