export const CREATE_COMPANY = "CREATE_COMPANY";
export const CREATE_COMPANY_ERROR = "CREATE_COMPANY_ERROR";
export const CREATE_TERRITORY_ERROR = "CREATE_TERRITORY_ERROR";
export const STORE_COMPNAY_ID = "STORE_COMPNAY_ID";
export const STORE_TERRITORY_ID = "STORE_TERRITORY_ID";
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";
export const UPDATE_COMPANY_ADMIN_DATA = "UPDATE_COMPANY_ADMIN_DATA";
export const GET_COMPNAY_BY_ID_ERROR = "GET_COMPNAY_BY_ID_ERROR";
export const UPDATE_COMPANY_TERRITORY_DATA = "UPDATE_COMPANY_TERRITORY_DATA";
export const UPDATE_COMPANY_TERRITORY_DETAILS = "UPDATE_COMPANY_TERRITORY_DETAILS";
export const UPDATE_COMPANY_TERRITORY_LIST = "UPDATE_COMPANY_TERRITORY_LIST";
export const CLOSE_PRODUCT_ADMNI_ALERT = "CLOSE_PRODUCT_ADMNI_ALERT";
export const ADD_ZIPCODES_ERROR = "ADD_ZIPCODES_ERROR";
export const ALL_STATECODES_ERROR = "ALL_STATECODES_ERROR";