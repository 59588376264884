import React from 'react';
import { Link } from 'react-router-dom';
import { getSession } from '../../../redux/Auth/sessionData';

const Footer = (props) =>{
    const session_data = getSession();
    const handleClick = () => {
        window.open("https://cloudfirstgroup.com/");
      };
    return(
        <React.Fragment>
        {session_data && session_data.userType !== "" ? null: 
        <div className="tl-footer" >
        <div className="footer-content bg-image" style={{backgroundImage: 'url(assets/images/bg/footer.jpg)'}}>
            <div className="container">
                {/* <div className="footer-top">
                    <div className="newsletter justify-content-between">
                        <h3>Subscribe Our Newsletter</h3>
                        <form action="#">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Enter Your E-mail" />
                                <input type="submit" className="btn btn-primary" value="Subscribe Now" />
                            </div>
                        </form>
                    </div>
                </div>

                <div className="footer-middle">
                    <div className="row">
                        {/* <div className="col-md-8 col-lg-5">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <img src="assets/images/logo-white.png" alt="Logo" className="img-fluid" />
                                </div>
                                <p>Holisticly aggregate compelling ROI before out-of-the-box benefits. Efficiently create cross functional paradigms without an expanded array of testing procedures.</p>
                                <span className="phone-number"><Link to="tel:990085627586"> <span className="mr-2 fa fa-phone"></span> 9900 - 8562 - 7586</Link></span>
                            </div>
                        </div> */}
                        {/*<div className="col-md-6 col-lg-6">
                            <div className="footer-widget">
                                <h3>Navigation</h3>
                                <ul className="global-list">
                                    <li><Link to="#">About Us</Link></li>
                                    <li><Link to="#">Contact Us</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                    <li><Link to="#">Privacy & Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="footer-widget">
                                <h3>Property Cities</h3>
                                <ul className="global-list">
                                    <li><Link to="property.html">New York</Link></li>
                                    <li><Link to="property.html">Sydney</Link></li>
                                    <li><Link to="property.html">Dubai</Link></li>
                                    <li><Link to="property.html">Austin</Link></li>
                                    <li><Link to="property.html">Japan</Link></li>
                                    <li><Link to="property.html">Quatar</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="footer-bottom clearfix" style={{textAlign:'center'}}>
                    {/* <div className="d-flex justify-content-between"> */}
                    <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={handleClick}>Cloud First</Link></span>
                        {/* <div className="left-content">
                            <span>Leadomatic &copy; 2024 Developed by <Link to="#">Cloud First</Link></span>
                        </div>
                        <div className="right-content">
                            <ul className="global-list d-flex">
                                <li><Link to="#">Privacy</Link></li>
                                <li><Link to="#">Terms & Condition</Link></li>
                                <li><Link to="#">Contact</Link></li>
                            </ul>
                        </div>             */}
                    {/* </div>              */}
                </div>                      
            </div>              
        </div>
    </div>     
    }</React.Fragment>
    );
}

export default Footer;