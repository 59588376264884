import {  GET_COMPANY_USERS_ERROR,CLOSE_ALERT,STORE_TERRITORY_ID_ERROR,STORE_TERRITORY_ID,COMPANY_ADMIN_TERRITORIES_INFO_ERROR,
  SAVE_COMPANY_SUBTERRITORIES_ERROR,GET_COMPANY_SUBTERRITORIES_ERROR,DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR,GET_ADMIN_DASHBOARD_ERROR,
  CREATE_COMPANY_MAILBOX_ERROR,UPDATE_COMPNAY_MAIL_BOX_USERLIST_BY_ID,GET_COMPANY_MAIL_BOXES_ERROR,UPDATE_COMPNAY_MAIL_BOX_BY_ID,GET_COMPNAY_MAIL_BOX_BY_ID,GET_COMPNAY_MAIL_BOX_BY_ID_ERROR,CREATE_COMPANY_USER_ERROR,STORE_COMPNAY_USER_ID,STORE_COMPNAY_USER_ID_ERROR,COMPANY_ADMIN_TERRITORIES,COMPANY_ADMIN_TERRITORIES_ERROR,UPDATE_COMPANY_USER_DATA,UPDATE_COMPANY_USER_TERRITORIES } from './companyActionTypes';
import { API } from "../../services/APIBuilder";
import {GET_ALL_COMPNAY_MAIL_BOX_BY_ID_URL,GET_DASHBOARD_URL,COMPANY_TERRITORY_INFO_LIST_URL,
  GET_COMPANY_SUBTERRITORIES_BY_ID,DELETE_COMPANY_SUBTERRITORY_BY_ID,SAVE_COMPANY_SUBTERRITORIES,GET_COMPANY_SUBTERRITORIES,GET_ALL_COMPNAY_USER_URL,GET_ALL_COMPNAY_MAIL_BOX,CREATE_COMPNAY_USER_URL,CREATE_COMPNAY_MAIL_BOX,COMPNAY_USERS_LIST_URL,COMPANY_TERRITORY_LIST_URL,GET_COMPNAY_USER_URL,GET_TERRITORY_URL} from '../../../ApiServices';
import { getSession } from '../../Auth/sessionData';


export const get_all_compnay_territories = () => async dispatch => {
    try {
        const session_data=getSession();
      let compnay_territories_List= await API.get(COMPANY_TERRITORY_LIST_URL, { headers: { Authorization: `Bearer ${session_data.token}` } }, {timeout: 70000})
      .then(result => {

          return result.data;
        }
        
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
               // ;
                dispatch({
                    type: CREATE_COMPANY_USER_ERROR,
                    payload: 'Invalid Credentials, Please enter valid details'
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: CREATE_COMPANY_USER_ERROR,
                  payload: 'There is no token in header...'
              })
          } else if (err.response.status === 500) {
                dispatch({
                    type: CREATE_COMPANY_USER_ERROR,
                    payload: "Internal error"
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: CREATE_COMPANY_USER_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: CREATE_COMPANY_USER_ERROR,
                payload: "Something went wrong"
            })
          }
        });
        return compnay_territories_List;
    }
    catch (e) {
        dispatch({
            type: CREATE_COMPANY_USER_ERROR,
            payload: "Something went wrong",
        })
    }
  
  }

  export const get_all_compnay_territories_info = () => async dispatch => {
    try {
        const session_data=getSession();
      let compnay_territories_info_List= await API.get(COMPANY_TERRITORY_INFO_LIST_URL, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {

          return result.data;
        }
        
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
               // ;
                dispatch({
                    type: COMPANY_ADMIN_TERRITORIES_INFO_ERROR,
                    payload: 'Invalid Credentials, Please enter valid details'
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: COMPANY_ADMIN_TERRITORIES_INFO_ERROR,
                  payload: 'There is no token in header...'
              })
          } else if (err.response.status === 500) {
                dispatch({
                    type: COMPANY_ADMIN_TERRITORIES_INFO_ERROR,
                    payload: "Internal error"
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: COMPANY_ADMIN_TERRITORIES_INFO_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: COMPANY_ADMIN_TERRITORIES_INFO_ERROR,
                payload: "Something went wrong"
            })
          }
        });
        return compnay_territories_info_List;
    }
    catch (e) {
        dispatch({
            type: COMPANY_ADMIN_TERRITORIES_INFO_ERROR,
            payload: "Something went wrong",
        })
    }
  
  }

  export const get_dashboard_data = () => async dispatch => {
    try {
    const session_data=getSession();
      let dashboardData= await API.get(GET_DASHBOARD_URL, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {
          
          return result.data;
        }
        
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
                //debugger
                dispatch({
                    type: GET_ADMIN_DASHBOARD_ERROR,
                    payload: err.response.data.msg
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: GET_ADMIN_DASHBOARD_ERROR,
                  payload: err.response.data.msg
              })
          } else if (err.response.status === 500) {
                dispatch({
                    type: GET_ADMIN_DASHBOARD_ERROR,
                    payload: err.response.data.msg
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: GET_ADMIN_DASHBOARD_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: GET_ADMIN_DASHBOARD_ERROR,
                payload: "Something went wrong"
            })
          }
        });
        return dashboardData;
    }
    catch (e) {
        dispatch({
            type: GET_ADMIN_DASHBOARD_ERROR,
            payload: "Something went wrong",
        })
    }
  
  }

  export const get_all_compnay_subterritories = () => async dispatch => {
    try {
        const session_data=getSession();
      let compnay_subterritories_List= await API.get(GET_COMPANY_SUBTERRITORIES, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {

          return result.data;
        }
        
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
               // ;
                dispatch({
                    type: GET_COMPANY_SUBTERRITORIES_ERROR,
                    payload: 'Invalid Credentials, Please enter valid details'
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: GET_COMPANY_SUBTERRITORIES_ERROR,
                  payload: 'There is no token in header...'
              })
          } else if (err.response.status === 500) {
                dispatch({
                    type: GET_COMPANY_SUBTERRITORIES_ERROR,
                    payload: "Internal error"
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: GET_COMPANY_SUBTERRITORIES_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: GET_COMPANY_SUBTERRITORIES_ERROR,
                payload: "Something went wrong"
            })
          }
        });
        return compnay_subterritories_List;
    }
    catch (e) {
        dispatch({
            type: GET_COMPANY_SUBTERRITORIES_ERROR,
            payload: "Something went wrong",
        })
    }
  
  }

export const create_company_user = (payload_data,history) => async dispatch => {
    try {
        const session_data=getSession();
      API.post(CREATE_COMPNAY_USER_URL,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {
          history.push('/AllUsers');
        }
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
                
               // ;
                dispatch({
                    type: CREATE_COMPANY_USER_ERROR,
                    payload: err.response.data.msg
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: CREATE_COMPANY_USER_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 403) {
            //;
            dispatch({
                type: CREATE_COMPANY_USER_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 404) {
            //;
            dispatch({
                type: CREATE_COMPANY_USER_ERROR,
                payload: err.response.data.msg
            })
        }  else if (err.response.status === 500) {
                dispatch({
                    type: CREATE_COMPANY_USER_ERROR,
                    payload: "Internal error"
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: CREATE_COMPANY_USER_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: CREATE_COMPANY_USER_ERROR,
                payload: "Something went wrong"
            })
          }
        });
    
    
       
    }
    catch (e) {
        dispatch({
            type: CREATE_COMPANY_USER_ERROR,
            payload: "Something went wrong",
        })
    }

}

export const create_company_subterritory = (payload_data,addnew,history) => async dispatch => {
  try {
      const session_data=getSession();
    API.post(SAVE_COMPANY_SUBTERRITORIES,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      if(addnew === true){
        window.location.reload(false)
      }else{
        history.push('/AllSubTerritories');
      }
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              
             // ;
              dispatch({
                  type: SAVE_COMPANY_SUBTERRITORIES_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: SAVE_COMPANY_SUBTERRITORIES_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 403) {
          //;
          dispatch({
              type: SAVE_COMPANY_SUBTERRITORIES_ERROR,
              payload: err.response.data.msg
          })
      }else if (err.response.status === 404) {
          //;
          dispatch({
              type: SAVE_COMPANY_SUBTERRITORIES_ERROR,
              payload: err.response.data.msg
          })
      }  else if (err.response.status === 500) {
              dispatch({
                  type: SAVE_COMPANY_SUBTERRITORIES_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: SAVE_COMPANY_SUBTERRITORIES_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: SAVE_COMPANY_SUBTERRITORIES_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: SAVE_COMPANY_SUBTERRITORIES_ERROR,
          payload: "Something went wrong",
      })
  }

}


export const company_users_Listing = () => async dispatch => {
  try {
      const session_data=getSession();
    let companyList=await API.get(COMPNAY_USERS_LIST_URL,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
         return result.data.companyUserDTOS;
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              return 'Authorization failed';
          }else if (err.response.status === 401) {
            //;
            return 'Authorization failed';
            
        } else if (err.response.status === 500) {
              
          return 'Something went wrong';
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          
          return 'Something went wrong';
        } else {
          // anything else
          
          return 'Something went wrong';
        }
      });
  
      return companyList;
     
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_USER_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const storeCompanyUserId = (payload_data,history) => async dispatch => {
   
  try {
    const session_data=getSession();
    await API.get(GET_COMPNAY_USER_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
      
          dispatch({
            type: STORE_COMPNAY_USER_ID,
            payload: result.data,

        });
        history.push('/EditUser');
         // return result.data;
          
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: STORE_COMPNAY_USER_ID_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: STORE_COMPNAY_USER_ID_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: STORE_COMPNAY_USER_ID_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: STORE_COMPNAY_USER_ID_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: STORE_COMPNAY_USER_ID_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: STORE_COMPNAY_USER_ID_ERROR,
          payload: 'Something went wrong',
      })
  }

}


export const storeTerritoryId = (payload_data,history) => async dispatch => {
   
  try {
    const session_data=getSession();
    await API.get(GET_COMPANY_SUBTERRITORIES_BY_ID + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
      
          dispatch({
            type: STORE_TERRITORY_ID,
            payload: result.data,

        });
        history.push('/ViewSubTerritory');
         // return result.data;
          
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: STORE_TERRITORY_ID_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: STORE_TERRITORY_ID_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: STORE_TERRITORY_ID_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: STORE_TERRITORY_ID_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: STORE_TERRITORY_ID_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: STORE_TERRITORY_ID_ERROR,
          payload: 'Something went wrong',
      })
  }

}

export const closeAlert = (payload_data) => async dispatch => {
  try{
    dispatch({
      type: CLOSE_ALERT,
      payload: payload_data,
  })
  }
  catch (e) {
      dispatch({
          type: CLOSE_ALERT,
          payload: payload_data
      })
  }
}


export const updateCompanyUserData = (name, value) => async dispatch => {
  //;
  dispatch({
      type: UPDATE_COMPANY_USER_DATA,
      payload: {
          name,
          value
      },
  })
}

export const updateCompanyUserTerritories = (payload_data) => async dispatch => {
  // ;
  // console.log(payload_data);
  dispatch({
      type: UPDATE_COMPANY_USER_TERRITORIES,
      payload: payload_data,
  })
}


export const get_compnay_territories_by_id = (payload_data,history) => async dispatch => {
  try {
      const session_data=getSession();
    let compnay_territories_by_id= await API.get(GET_TERRITORY_URL + payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
          dispatch({
            type: COMPANY_ADMIN_TERRITORIES,
            payload: result.data
        })
        history.push('/CompanyTerritory');
        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
             // ;
              dispatch({
                  type: COMPANY_ADMIN_TERRITORIES_ERROR,
                  payload: 'Invalid Credentials, Please enter valid details'
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: COMPANY_ADMIN_TERRITORIES_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: COMPANY_ADMIN_TERRITORIES_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: COMPANY_ADMIN_TERRITORIES_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: COMPANY_ADMIN_TERRITORIES_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return compnay_territories_by_id;
  }
  catch (e) {
      dispatch({
          type: COMPANY_ADMIN_TERRITORIES_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const get_all_compnay_users = () => async dispatch => {
  try {
      const session_data=getSession();
    let compnay_users_List= await API.get(GET_ALL_COMPNAY_USER_URL, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {

        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
             // ;
              dispatch({
                  type: GET_COMPANY_USERS_ERROR,
                  payload: 'Invalid Credentials, Please enter valid details'
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_COMPANY_USERS_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_COMPANY_USERS_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_COMPANY_USERS_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_COMPANY_USERS_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return compnay_users_List;
  }
  catch (e) {
      dispatch({
          type: GET_COMPANY_USERS_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const create_mailbox = (payload_data,history) => async dispatch => {
  try {
      const session_data=getSession();
    API.post(CREATE_COMPNAY_MAIL_BOX,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
    //   dispatch({
    //     type: CREATE_COMPANY_MAILBOX,
    //     payload: result.data.msg
    // })
        history.push('/AllMailBoxes');
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              
             // ;
              dispatch({
                  type: CREATE_COMPANY_MAILBOX_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: CREATE_COMPANY_MAILBOX_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 403) {
          //;
          dispatch({
              type: CREATE_COMPANY_MAILBOX_ERROR,
              payload: err.response.data.msg
          })
      }else if (err.response.status === 404) {
          //;
          dispatch({
              type: CREATE_COMPANY_MAILBOX_ERROR,
              payload: err.response.data.msg
          })
      }  else if (err.response.status === 500) {
              dispatch({
                  type: CREATE_COMPANY_MAILBOX_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: CREATE_COMPANY_MAILBOX_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: CREATE_COMPANY_MAILBOX_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_USER_ERROR,
          payload: "Something went wrong",
      })
  }

}


export const get_all_compnay_mail_boxes = () => async dispatch => {
  try {
      const session_data=getSession();
    let compnay_mailbox_List= await API.get(GET_ALL_COMPNAY_MAIL_BOX, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {

        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
             // ;
              dispatch({
                  type: GET_COMPANY_MAIL_BOXES_ERROR,
                  payload: 'Invalid Credentials, Please enter valid details'
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_COMPANY_MAIL_BOXES_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_COMPANY_MAIL_BOXES_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_COMPANY_MAIL_BOXES_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_COMPANY_MAIL_BOXES_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return compnay_mailbox_List;
  }
  catch (e) {
      dispatch({
          type: GET_COMPANY_MAIL_BOXES_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const get_compnay_mailBox_by_id = (payload_data,history) => async dispatch => {
  try {
      const session_data=getSession();
    let compnay_mailbox_by_id= await API.get(GET_ALL_COMPNAY_MAIL_BOX_BY_ID_URL + payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
          dispatch({
            type: GET_COMPNAY_MAIL_BOX_BY_ID,
            payload: result.data
        })
        history.push('/UpdateMailBox');
        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
             // ;
              dispatch({
                  type: GET_COMPNAY_MAIL_BOX_BY_ID_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_COMPNAY_MAIL_BOX_BY_ID_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_COMPNAY_MAIL_BOX_BY_ID_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_COMPNAY_MAIL_BOX_BY_ID_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_COMPNAY_MAIL_BOX_BY_ID_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return compnay_mailbox_by_id;
  }
  catch (e) {
      dispatch({
          type: GET_COMPNAY_MAIL_BOX_BY_ID_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const updateCompanyMailBoxData = (name, value) => async dispatch => {
  //;
  dispatch({
      type: UPDATE_COMPNAY_MAIL_BOX_BY_ID,
      payload: {
          name,
          value
      },
  })
}


export const updateCompanyMailBoxUserList = (payload_data) => async dispatch => {
  // ;
  // console.log(payload_data);
  dispatch({
      type: UPDATE_COMPNAY_MAIL_BOX_USERLIST_BY_ID,
      payload: payload_data,
  })
}

export const deleteSubTerritoryById = (payload_data,history) => async dispatch => {
  try {
      const session_data=getSession();
     await API.delete(DELETE_COMPANY_SUBTERRITORY_BY_ID + payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
          window.location.reload(false);
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
             // ;
              dispatch({
                  type: DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR,
                payload: 'There is no token in header...'
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR,
                  payload: "Internal error"
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  }
  catch (e) {
      dispatch({
          type: DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR,
          payload: "Something went wrong",
      })
  }

}