export const LOGIN_CHECK = 'LOGIN_CHECK';
export const LOGIN_CHECK_ERROR = 'LOGIN_CHECK_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_PROFILE_ERROR = 'SAVE_PROFILE_ERROR';
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
