import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { connect } from 'react-redux';
import { create_company,activeterritoryListing,get_compnay,updateCompanyData,updateCompanyAdminData,updateTerritoryList,closeAlert } from '../../../redux/action/productAdmin/productActionCreator';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import Alert from 'react-bootstrap/Alert';

const UpdateCompnay = (props) =>{
    const [territoriesList,setTerritoriesList] =useState([]);
    const [error,setError]=useState({
        companyNameErr:'',
        companyEmailErr:'',
        companyAddress1Err:'',
        companyCityErr:'',
        companyStateErr:'',
        companyZipcodeErr:'',
        // companyAddress2Err:'',
        compnayTerritoryErr:'',
        companyPhoneNumberErr:'',
        companyAlternateNumberErr:'',
        adminFirstNameErr:'',
        adminLastNameErr:'',
        adminEmailErr:'',
        adminPhoneNumberErr:'',
        // adminGenderErr:'',
        // passwordErr:'',
    });

    useEffect(()=>{
        //console.log(props.CompanyID.companyId);
        props.activeterritoryListing().then(result => {
            setTerritoriesList(result);
            });
        props.closeAlert(false);
          // props.get_compnay(props.CompanyID.companyId).then(result => {
          //   //setTerritoriesList(result);
          //   console.log(result);
          //   setCompnayDetails(result);
          //   });
          // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  //console.log(props.companyDetails);
    //territories list
    const  {companyTerritories}  = territoriesList;
    const  options  = companyTerritories && companyTerritories.map(item => {
        const val= {  id: item.id, territoryName:  item.territoryName,label: item.territoryName,value: item.territoryName};
        return val;
      });
    
    const selectedOptions = props.companyDetails.companyTerritoryDetails && props.companyDetails.companyTerritoryDetails.map(item => {
      const val= {  id: item.id, territoryName:  item.territoryName,label: item.territoryName,value: item.territoryName};
      return val;
    });

    //change territory details
    const changeTerritortHandler =(e)=>{
      if(e === []){
        props.updateTerritoryList('');
      }else{
        props.updateTerritoryList(e);
      }
      //props.updateCompanyUserData("mailBoxDTOS",e);
    }
    //console.log(territories);
    const toggleActiveHandler = (e) =>{
      props.updateCompanyData("isActive",!props.companyDetails.companyDetailsDTO.isActive);
    }

    const validate = (e) => {
        let companyNameVal = companyNameValidation(props.companyDetails.companyDetailsDTO.companyName);
        let companyEmailVal = companyEmailValidation(props.companyDetails.companyDetailsDTO.companyEmail);
        let companyAddress1Val = companyAddress1Validation(props.companyDetails.companyDetailsDTO.address1);
        // let companyAddress2Val = companyAddress2Validation(companyAddress2);
        let companyCityVal = companyCityValidation(props.companyDetails.companyDetailsDTO.city);
        let companyStateVal = companyStateValidation(props.companyDetails.companyDetailsDTO.state);
        let companyZipcodeVal = companyZipcodeValidation(props.companyDetails.companyDetailsDTO.zipCode);
        let territoriesVal = territoriesValidation(props.companyDetails.companyTerritoryDetails);
        let companyPhoneNumberVal = companyPhoneNumberValidation(props.companyDetails.companyDetailsDTO.phoneNumber1);
        let companyAlternateNumberVal = companyAlternateNumberValidation(props.companyDetails.companyDetailsDTO.phoneNumber2);
        let adminFirstNameVal = adminFirstNameValidation(props.companyDetails.companyAdminDetailsDTO.firstName);
        let adminLastNameVal = adminLastNameValidation(props.companyDetails.companyAdminDetailsDTO.lastName);
        let adminEmailVal = adminEmailValidation(props.companyDetails.companyAdminDetailsDTO.email);
        let adminPhoneNumberVal = adminPhoneNumberValidation(props.companyDetails.companyAdminDetailsDTO.phoneNumber);
        if(companyNameVal === true && territoriesVal === true && companyCityVal === true && companyStateVal === true && companyZipcodeVal === true &&  companyEmailVal === true  && companyAddress1Val === true  && companyPhoneNumberVal === true && companyAlternateNumberVal === true && adminFirstNameVal === true && adminLastNameVal === true && adminEmailVal === true && adminPhoneNumberVal === true ){
          return true;
        }
      }
      

      function companyCityValidation(companyCity) { 
        if(!companyCity) {
          setError(prevState => ({
              ...prevState,
              companyCityErr: 'Please Enter City'
          }));
        return false;
      }
      // if (!/^[a-zA-Z ]+$/.test(companyCity)){
      //     setError(prevState => ({
      //         ...prevState,
      //         companyCityErr: 'Please Enter Only Characters'
      //     }));
      //   return false;
      // }
      if(companyCity) {
          setError(prevState => ({
              ...prevState,
              companyCityErr: ''
          }));
        return true;
      }
      }

      function companyStateValidation(companyState) { 
        if(!companyState) {
          setError(prevState => ({
              ...prevState,
              companyStateErr: 'Please Enter State'
          }));
        return false;
      }
      // if (!/^[a-zA-Z ]+$/.test(companyState)){
      //     setError(prevState => ({
      //         ...prevState,
      //         companyStateErr: 'Please Enter Only Characters'
      //     }));
      //   return false;
      // }
      if(companyState) {
          setError(prevState => ({
              ...prevState,
              companyStateErr: ''
          }));
        return true;
      }
      }

      
      function companyZipcodeValidation(companyZipcode) { 
        if(!companyZipcode) {
          setError(prevState => ({
              ...prevState,
              companyZipcodeErr: 'Please Enter Zipcode'
          }));
        return false;
      }
      if (!/^\d+$/.test(companyZipcode)){
          setError(prevState => ({
              ...prevState,
              companyZipcodeErr: 'Please Enter Only Numbers'
          }));
        return false;
      }
      if(companyZipcode && /^\d+$/.test(companyZipcode)) {
          setError(prevState => ({
              ...prevState,
              companyZipcodeErr: ''
          }));
        return true;
      }
      }
      function territoriesValidation(territoriesVal) { 
        if(territoriesVal.length === 0){
          setError(prevState => ({
              ...prevState,
              compnayTerritoryErr: 'Please Assign Territory'
          }));
          return false;
      }
      if(territoriesVal.length > 0){
          setError(prevState => ({
              ...prevState,
              compnayTerritoryErr: ''
          }));
          return true;
      }
      }

      function companyNameValidation(companyNameVal) { 
          
        if(!companyNameVal) {
            setError(prevState => ({
                ...prevState,
                companyNameErr: 'Please Enter Company Name'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(companyNameVal)){
        //     setError(prevState => ({
        //         ...prevState,
        //         companyNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(companyNameVal) {
            setError(prevState => ({
                ...prevState,
                companyNameErr: ''
            }));
          return true;
        }
      }
      function companyEmailValidation(companyEmailVal) { 
          if(!companyEmailVal) {
            setError(prevState => ({...prevState,companyEmailErr: 'Please Enter Email'}));
            return false;
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(companyEmailVal)){
            setError(prevState => ({
                ...prevState,
                companyEmailErr: 'Please Enter  Valid Email'
            }));
            return false;
          }
          if(companyEmailVal && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(companyEmailVal)) {
            setError(prevState => ({
                ...prevState,
                companyEmailErr: ''
            }));
            return true;
          }
      }
      function companyAddress1Validation(companyAddress1Val) {    
        if (companyAddress1Val === "") {
            setError(prevState => ({...prevState,companyAddress1Err: 'Please Enter Address1'}));     
          return false;
        }
        // if (!/^[0-9a-zA-Z]{3,}$/.test(companyAddress1)) {
        //     setError(prevState => ({...prevState,companyAddress1Err: 'Please Enter mininum 6 characters'}));
        //   return false;
        // } 
        if(companyAddress1Val !== "" ) {
            setError(prevState => ({...prevState,companyAddress1Err: ''}));
          return true;
        }
      }
      function companyPhoneNumberValidation(companyPhoneNumberVal) {
        if (companyPhoneNumberVal === "") {
            setError(prevState => ({...prevState,companyPhoneNumberErr: 'Please Enter Phone Number'}));     
          return false;
        }
        if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(companyPhoneNumberVal)) {
            setError(prevState => ({...prevState,companyPhoneNumberErr: 'Please Enter Valid Phone Number'}));
          return false;
        } 
        if(companyPhoneNumberVal !== "" && /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(companyPhoneNumberVal)) {
            setError(prevState => ({...prevState,companyPhoneNumberErr: ''}));
          return true;
        } 
      }
      function companyAlternateNumberValidation(companyAlternateNumberVal) {
        if (companyAlternateNumberVal !== "") {
          if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(companyAlternateNumberVal)) {
            setError(prevState => ({...prevState,companyAlternateNumberErr: 'Please Enter Valid Phone Number'}));
          return false;
        } 
        }
        
        if(companyAlternateNumberVal === "" || /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(companyAlternateNumberVal)) {
            setError(prevState => ({...prevState,companyAlternateNumberErr: ''}));
          return true;
        } 
      }
      function adminFirstNameValidation(adminFirstNameVal) {
        if(!adminFirstNameVal) {
            setError(prevState => ({...prevState,adminFirstNameErr: 'Please Enter First Name'}));
            return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(adminFirstNameVal)){
        //     setError(prevState => ({...prevState,adminFirstNameErr: 'Please Enter Only Characters'}));
        //     return false;
        // }
        if(adminFirstNameVal) {
            setError(prevState => ({...prevState,adminFirstNameErr: ''}));
          return true;
        }
      }
      function adminLastNameValidation(adminLastNameVal) {
        if(!adminLastNameVal) {
            setError(prevState => ({...prevState,adminLastNameErr: 'Please Enter Last Name'}));
            return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(adminLastNameVal)){
        //     setError(prevState => ({...prevState,adminLastNameErr: 'Please Enter Only Characters'}));
        //     return false;
        // }
        if(adminLastNameVal ) {
            setError(prevState => ({...prevState,adminLastNameErr: ''}));
          return true;
        }
      }
      function adminEmailValidation(adminEmailVal) {
        if(!adminEmailVal) {
            setError(prevState => ({...prevState,adminEmailErr: 'Please Enter Email'}));
            return false;
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminEmailVal)){
            setError(prevState => ({...prevState,adminEmailErr: 'Please Enter Valid Email'}));
            return false;
          }
          if(adminEmailVal && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminEmailVal)) {
            setError(prevState => ({...prevState,adminEmailErr: ''}));
            return true;
          }
      }
      function adminPhoneNumberValidation(adminPhoneNumberVal) {
        if (adminPhoneNumberVal !== "") {
           
          if (!/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(adminPhoneNumberVal)) {
            setError(prevState => ({...prevState,adminPhoneNumberErr: 'Please Enter Valid Phone Number'}));
            return false;
          } 
        }
        if(adminPhoneNumberVal === "" || /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/.test(adminPhoneNumberVal)) {
            setError(prevState => ({...prevState,adminPhoneNumberErr: ''}));
          return true;
        }
      }
    
    const addCompnayHandler = async (e) =>{
        e.preventDefault();
        const territoryIds=props.companyDetails.companyTerritoryDetails && props.companyDetails.companyTerritoryDetails.map(item =>{
          return item.id;
        });
        //console.log(territoryIds);
        if(validate()){
            const payload_data={
                companyAdminDetailsDTO: {
                  id:props.companyDetails.companyAdminDetailsDTO.id,
                  email: props.companyDetails.companyAdminDetailsDTO.email,
                  firstName: props.companyDetails.companyAdminDetailsDTO.firstName,
                  // gender: "MALE",
                  lastName: props.companyDetails.companyAdminDetailsDTO.lastName,
                  // password: password,
                  phoneNumber: props.companyDetails.companyAdminDetailsDTO.phoneNumber,
                },
                companyDetailsDTO: {
                  id:props.companyDetails.companyDetailsDTO.id,
                  address1: props.companyDetails.companyDetailsDTO.address1,
                  address2: props.companyDetails.companyDetailsDTO.address2,
                  companyName: props.companyDetails.companyDetailsDTO.companyName,
                  isActive: props.companyDetails.companyDetailsDTO.isActive,
                  // country: country,
                  phoneNumber1: props.companyDetails.companyDetailsDTO.phoneNumber1,
                  phoneNumber2: props.companyDetails.companyDetailsDTO.phoneNumber2,
                  companyEmail: props.companyDetails.companyDetailsDTO.companyEmail,
                  city:  props.companyDetails.companyDetailsDTO.city,
                  state:  props.companyDetails.companyDetailsDTO.state,
                  zipCode:  props.companyDetails.companyDetailsDTO.zipCode,
                },
                territoryIds: territoryIds
              };
              //console.log(payload_data);
              props.create_company(payload_data,props.history);
        
        }else{
          window.scrollTo({top:150,behavior: "smooth"});
        }
    }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Edit Company</h1>
                    <Alert id="alert" className="alert" variant={props.alertData.resultType} show={props.alertData.show} 
                    // onClose={() => setShow(false)} dismissible
                    >
                      <p>{props.alertData.msg}</p>
                    </Alert>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <h4>Company Details:</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="company">Company Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Company Name"
                                        value={props.companyDetails.companyDetailsDTO.companyName || ''}
                                        onChange={(e) => props.updateCompanyData("companyName",e.target.value)}
                                        />
                                        {error.companyNameErr ? <span className="error-span">{error.companyNameErr}</span>:null}
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="company">Company Email</label>
                                    <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Enter Company Email"
                                    value={props.companyDetails.companyDetailsDTO.companyEmail || ''}
                                    onChange={(e) =>  props.updateCompanyData("companyEmail",e.target.value)}
                                    />
                                    {error.companyEmailErr ? <span className="error-span">{error.companyEmailErr}</span>:null}
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                
                                <div className="col-md-6">
                                        <div className="form-group">
                                            {/* <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter Company Phone Number"
                                            value={companyPhoneNumber}
                                            onChange={(e)=>setCompanyPhoneNumber(e.target.value)}
                                            /> */}
                                            <label htmlFor="number">Phone Number</label>
                                            <NumberFormat 
                                            format="(###)-###-####" 
                                            mask="_" 
                                            className="form-control" 
                                            placeholder="Enter Phone Number" 
                                            value={props.companyDetails.companyDetailsDTO.phoneNumber1 || ''}

                                            onChange={(e)=>props.updateCompanyData("phoneNumber1",e.target.value)}
                                            />
                                            {error.companyPhoneNumberErr ? <span className="error-span">{error.companyPhoneNumberErr}</span>:null}
                                        </div>
                                    </div>
                                <div className="col-md-6">
                                        <div className="form-group">
                                            {/* <input 
                                            type="text" 
                                            name="adress" 
                                            id="adress" 
                                            placeholder="Enter Company Alternate Number" 
                                            className="form-control"
                                            value={companyAlternateNumber}
                                            onChange={(e)=>setCompanyAlternateNumber(e.target.value)}
                                            /> */}
                                            <label htmlFor="altnumber">Alternate Number</label>
                                            <NumberFormat 
                                            format="(###)-###-####" 
                                            mask="_" 
                                            className="form-control" 
                                            placeholder="Enter Alternate Number" 
                                            value={props.companyDetails.companyDetailsDTO.phoneNumber2 || ''}
                                            onChange={(e)=>props.updateCompanyData("phoneNumber2",e.target.value)}
                                            />
                                            {error.companyAlternateNumberErr ? <span className="error-span">{error.companyAlternateNumberErr}</span>:null}
                                        
                                        </div>
                                    </div>
                                    </div>
                                
                            <div className="row">
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="address">Company Address</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Company Address"
                                        value={props.companyDetails.companyDetailsDTO.address1 || ''}

                                        onChange={(e)=>props.updateCompanyData("address1",e.target.value)}
                                        />
                                        {error.companyAddress1Err ? <span className="error-span">{error.companyAddress1Err}</span>:null}
                                    </div>
                                </div>
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="address2">Company Address Line 2</label>
                                        <input 
                                        type="text" 
                                        name="adress" 
                                        id="adress" 
                                        placeholder="Enter Company Address Line2" 
                                        className="form-control"
                                        value={props.companyDetails.companyDetailsDTO.address2 || ''}
                                        onChange={(e)=>props.updateCompanyData("address2",e.target.value)}
                                        />
                                        {error.companyAddress2Err ? <span className="error-span">{error.companyAddress2Err}</span>:null}
                                    </div>
                                </div>
                                </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="city">City</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="City"
                                        value={props.companyDetails.companyDetailsDTO.city || ''}

                                        onChange={(e)=>props.updateCompanyData("city",e.target.value)}
                                        />
                                        {error.companyCityErr ? <span className="error-span">{error.companyCityErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="state">State</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="State"
                                        value={props.companyDetails.companyDetailsDTO.state || ''}

                                        onChange={(e)=>props.updateCompanyData("state",e.target.value)}
                                        />
                                        {error.companyStateErr ? <span className="error-span">{error.companyStateErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="zipcode">Zipcode</label>
                                        <input 
                                        type="text" 
                                        name="adress" 
                                        id="adress" 
                                        placeholder="Zipcode" 
                                        className="form-control"
                                        value={props.companyDetails.companyDetailsDTO.zipCode || ''}
                                        onChange={(e)=>props.updateCompanyData("zipCode",e.target.value)}
                                        />
                                        {error.companyZipcodeErr ? <span className="error-span">{error.companyZipcodeErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                    <label htmlFor="territories">Assign Territory</label>
                                    <MultiSelect  options={options || ''} value={selectedOptions} placeholder="Assign Territory" onChange={changeTerritortHandler}/>
  
                                    {/* <Multiselect
                                    isObject={true}
                                    displayValue="territoryName"
                                    onRemove={function noRefCheck(selectedList,removedItem){
                                      // setTerritories(selectedList);
                                      props.updateTerritoryList(selectedList);
                                    }}
                                    onSelect={function noRefCheck(selectedList,selectedItem){
                                      // setTerritories(selectedList);
                                      props.updateTerritoryList(selectedList);
                                    }}
                                    options={options}
                                    selectedValues={selectedOptions}
                                    placeholder="Assign Territory"
                                /> */}
                                {error.compnayTerritoryErr ? <span className="error-span">{error.compnayTerritoryErr}</span>:null}
                                </div>
                                </div>
                                
                                </div>
                                <div className="row">
                                  {/* <div className="col-md-4">
                                  <div className="form-group">
                                    <div className="add-photo">
                                          <input type="file" name="add-photo" id="add-photo" className="inputfile form-control inputfile-4" data-multiple-caption="{count} files selected" multiple />
                                          <label htmlFor="add-photo"><i className="fa fa-camera" aria-hidden="true"></i> <span>Add Company Logo</span></label>
                                      </div>
                                  </div>
                                  </div> */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                      <BootstrapSwitchButton
                                            checked={props.companyDetails.companyDetailsDTO.isActive}
                                            onlabel='Active'
                                            offlabel='Inactive'
                                            onChange={toggleActiveHandler}
                                            width={100} 
                                        />
                                    </div>
                                </div>
                                </div>
                           
                            <h4>Company Admin:</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="firstname">First Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter First Name"
                                        value={props.companyDetails.companyAdminDetailsDTO.firstName || ''}
                                        onChange={(e)=>props.updateCompanyAdminData("firstName",e.target.value)}
                                        />
                                        {error.adminFirstNameErr ? <span className="error-span">{error.adminFirstNameErr}</span>:null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="lastname">Last Name</label>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Enter Last Name"
                                    value={props.companyDetails.companyAdminDetailsDTO.lastName || ''}
                                    onChange={(e)=>props.updateCompanyAdminData("lastName",e.target.value)}
                                     />
                                     {error.adminLastNameErr ? <span className="error-span">{error.adminLastNameErr}</span>:null}
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="email">Enter Email</label>
                                        <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Enter Email"
                                        value={props.companyDetails.companyAdminDetailsDTO.email || ''}
                                        onChange={(e)=>props.updateCompanyAdminData("email",e.target.value)}
                                         />
                                         {error.adminEmailErr ? <span className="error-span">{error.adminEmailErr}</span>:null}
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Phone Number"
                                        value={adminPhoneNumber}
                                        onChange={(e)=>setAdminPhoneNumber(e.target.value)}
                                        /> */}
                                        <label htmlFor="number">Phone Number</label>
                                        <NumberFormat 
                                            format="(###)-###-####" 
                                            mask="_" 
                                            className="form-control" 
                                            placeholder="Enter Phone Number" 
                                            value={props.companyDetails.companyAdminDetailsDTO.phoneNumber || ''}
                                            onChange={(e)=>props.updateCompanyAdminData("phoneNumber",e.target.value)}
                                            />
                                            {error.adminPhoneNumberErr ? <span className="error-span">{error.adminPhoneNumberErr}</span>:null}
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <button className="btn btn-primary" onClick={addCompnayHandler}>Update Company</button>
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
  return {
    alertData: state.Product.alert,
    companyDetails: state.Product.companyDetails,
  }
}

export default connect(mapStateToProps,{create_company,activeterritoryListing,get_compnay,updateCompanyData,closeAlert,updateCompanyAdminData,updateTerritoryList})(UpdateCompnay);