import axios from 'axios';
import GRANDWELCOME_BASE_URL from '../../../configs/baseUrls';
import { getSession } from '../../Auth/sessionData';

export const unsetLogin = () => async dispatch => {
    try {
      const session_data = getSession();
      const config = {
        method: 'get',
        url: GRANDWELCOME_BASE_URL+'/registration/logout',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${session_data.token}`
        }
      }
      axios(config).then(result => {
        localStorage.clear();
        window.location.reload(false);
        }
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
                //;
                return 'Something Went wrong';
            }else if (err.response.status === 401) {
                return 'There is no token in header...';
          } else if (err.response.status === 500) {
                return 'Internal Server Error';
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            return 'Something Went wrong';
          } else {
            // anything else
            return 'Something Went wrong';
          }
        });      
    }
    catch (e) {
        return 'Something Went wrong';
    }

}