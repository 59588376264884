import { CREATE_COMPANY_USER_ERROR,CLOSE_ALERT,GET_ADMIN_DASHBOARD_ERROR,COMPANY_ADMIN_TERRITORIES_INFO_ERROR,
    DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR,STORE_TERRITORY_ID,STORE_TERRITORY_ID_ERROR,SAVE_COMPANY_SUBTERRITORIES_ERROR,GET_COMPANY_SUBTERRITORIES_ERROR,STORE_COMPNAY_USER_ID,COMPANY_ADMIN_TERRITORIES,UPDATE_COMPNAY_MAIL_BOX_BY_ID,UPDATE_COMPNAY_MAIL_BOX_USERLIST_BY_ID,GET_COMPNAY_MAIL_BOX_BY_ID,GET_COMPNAY_MAIL_BOX_BY_ID_ERROR,COMPANY_ADMIN_TERRITORIES_ERROR,STORE_COMPNAY_USER_ID_ERROR,UPDATE_COMPANY_USER_DATA,UPDATE_COMPANY_USER_TERRITORIES } from '../../action/compnayAdmin/companyActionTypes';
// import {setAuthHeader} from '../services/APIBuilder';

const initialState = {
    alert:{
        show:false,
        resultType: '',
        msg:'',
    },
    companyUserDetails:{},
    companyTerritories:{},
    mailBoxDetailsById:{},
    companySubTerritoryById:{}
}

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case GET_ADMIN_DASHBOARD_ERROR:
           // debugger
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case CREATE_COMPANY_USER_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case COMPANY_ADMIN_TERRITORIES_INFO_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case CLOSE_ALERT:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:action.payload,
                }
            }
        case STORE_COMPNAY_USER_ID:
            return{
                ...state,
                companyUserDetails:action.payload
            }
        case STORE_COMPNAY_USER_ID_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case UPDATE_COMPANY_USER_DATA:
            return{
                ...state,
                companyUserDetails: {
                    ...state.companyUserDetails,
                    [action.payload.name]: action.payload.value
                }
            }
        case UPDATE_COMPANY_USER_TERRITORIES:
        //
        return {
            
            ...state,
            companyUserDetails: {
                ...state.companyUserDetails,
                companyUserTerritories:action.payload
            }
        }
        case COMPANY_ADMIN_TERRITORIES:
            //
            return {
                
                ...state,
                companyTerritories:action.payload
            }
        case COMPANY_ADMIN_TERRITORIES_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case GET_COMPNAY_MAIL_BOX_BY_ID:
            return {
                ...state,
                mailBoxDetailsById:action.payload
            }
        case GET_COMPNAY_MAIL_BOX_BY_ID_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case UPDATE_COMPNAY_MAIL_BOX_BY_ID:
            return{
                ...state,
                mailBoxDetailsById: {
                    ...state.mailBoxDetailsById,
                    [action.payload.name]: action.payload.value
                }
            }
        case UPDATE_COMPNAY_MAIL_BOX_USERLIST_BY_ID:
            //
            return {
                
                ...state,
                mailBoxDetailsById: {
                    ...state.mailBoxDetailsById,
                    selectedUsers:action.payload
                }
            }
        case SAVE_COMPANY_SUBTERRITORIES_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case GET_COMPANY_SUBTERRITORIES_ERROR:
        return {
            ...state,
            alert:{
                ...alert,
                show:true,
                resultType: 'danger',
                msg:action.payload
            }
        }
        case DELETE_COMPANY_SUBTERRITORY_BY_ID_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case STORE_TERRITORY_ID:
            return{
                ...state,
                companySubTerritoryById:action.payload
            }
        case STORE_TERRITORY_ID_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        default: return state
    }

}