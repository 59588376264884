import { CREATE_COMPANY,CREATE_COMPANY_ERROR, CREATE_TERRITORY_ERROR, STORE_COMPNAY_ID,STORE_TERRITORY_ID,GET_COMPNAY_BY_ID_ERROR,UPDATE_COMPANY_DATA,UPDATE_COMPANY_ADMIN_DATA,UPDATE_COMPANY_TERRITORY_DATA,CLOSE_PRODUCT_ADMNI_ALERT, UPDATE_COMPANY_TERRITORY_DETAILS, UPDATE_COMPANY_TERRITORY_LIST,ADD_ZIPCODES_ERROR } from '../../action/productAdmin/productActionTypes';
// import {setAuthHeader} from '../services/APIBuilder';

const initialState = {
    alert:{
        show:false,
        resultType: '',
        msg:'',
    },
    companyDetails:{},
    companyTerritoryDetails:{},
}

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case CREATE_COMPANY:
            //
            return {

                ...state,
                alert:{
                    ...state.alert,
                    show:false,
                    resultType: 'success',
                    msg:action.payload
                }
            }
        case CREATE_COMPANY_ERROR:
            //
            return {
                ...state,
                alert:{
                    ...state.alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case UPDATE_COMPANY_DATA:
            return {
                ...state,
                companyDetails: {
                    ...state.companyDetails,
                    companyDetailsDTO: {
                        ...state.companyDetails.companyDetailsDTO,
                        [action.payload.name]: action.payload.value
                        }
                }
            }
        case UPDATE_COMPANY_ADMIN_DATA:
            //
            return {
                ...state,
                companyDetails: {
                    ...state.companyDetails,
                    companyAdminDetailsDTO:{
                        ...state.companyDetails.companyAdminDetailsDTO,
                        [action.payload.name]: action.payload.value
                    }
                   
                }
            }
        case UPDATE_COMPANY_TERRITORY_DATA:
            //
            return {
               
                ...state,
                companyDetails: {
                    ...state.companyDetails,
                    companyTerritoryDetails:action.payload
                }
            }
        case CREATE_TERRITORY_ERROR:
            return {
                ...state,
                alert:{
                    ...state.alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case STORE_COMPNAY_ID:
            return{
                ...state,
                    companyDetails:action.payload
            }
        case STORE_TERRITORY_ID:
            return{
                ...state,
                    companyTerritoryDetails:action.payload
            }

        case GET_COMPNAY_BY_ID_ERROR:
            return {
                ...state,
                alert:{
                    ...state.alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }

        case CLOSE_PRODUCT_ADMNI_ALERT:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:action.payload,
                }
            }

        case UPDATE_COMPANY_TERRITORY_DETAILS:
            return {
                
                ...state,
                companyTerritoryDetails: {
                    ...state.companyTerritoryDetails,
                    [action.payload.name]: action.payload.value
                }
            }
        case UPDATE_COMPANY_TERRITORY_LIST:
            return {
                ...state,
                companyTerritoryDetails: {
                    ...state.companyTerritoryDetails,
                    cityZipCodes:action.payload
                }
            }
        
        case ADD_ZIPCODES_ERROR:
            return {
                ...state,
                alert:{
                    ...state.alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
            
        default: return state
    }

}