import { GET_ALL_COMPANY_USER_TERRITORIES_ERROR,GET_ALL_USERS_SUBTERRITORY_ERROR,GET_USERS_SUBTERRITORY_BY_ID_ERROR,GET_USERS_SUBTERRITORY_BY_ID,
  GET_ALL_COMPANY_USER_TRIGGERS_ERROR,CREATE_COMPANY_USER_CAMPAIGN_ERROR,UPDATE_COMPANY_USER_CAMPAIGN_TRIGGER,UPDATE_COMPANY_USER_EMAIL_TEMPLATE,
  UPDATE_COMPANY_USER_CAMPAIGN_TERRITORIES,UPDATE_COMPANY_USER_CAMPAIGN_DATA,STORE_COMPNAY_USER_CAMPAIGN_ID,GET_MESSAGE_TEMPLATE,CHANGE_CAMPAIGN_STATUS,
  DELETE_COMPANY_USER_CAMPAIGN,DELETE_COMPANY_USER_CAMPAIGN_ERROR,DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR,SAVE_MESSAGE_TEMPLATE_ERROR,GET_MESSAGE_TEMPLATE_ERROR,
  STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR,CHANGE_CAMPAIGN_STATUS_ERROR,SAVE_MESSAGE_TEMPLATE,CLOSE_ALERT,GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR,GET_PROPERITIES_BY_CAMPAIGN_ID,
  GET_COMPANY_USERS_MAILBOX_ERROR,GET_ALL_COMPANY_USER_CAMPAIGN_ERROR, GET_PROPERITIES_DETAILS_BY_ID,GET_PROPERITIES_DETAILS_BY_ID_ERROR,
  DUPLICATE_COMPANY_USER_CAMPAIGN,GET_ALL_CONTACTS_LIST_ERROR,UNSUBSCRIBE,UNSUBSCRIBE_ERROR,
  GET_DASHBOARD_ERROR,UPDATE_CONTACT_STATUS_ERROR,UPDATE_CONTACT_STATUS,
  GET_CONTACTS_DATA_BY_ID,GET_CONTACTS_DATA_BY_ID_ERROR } from "./companyUserActionTypes";
import { API } from "../../services/APIBuilder";
import {COMPANY_USERS_TERRITORY_LIST_URL,COMPANY_USERS_TRIGGER_URL,GET_PROPERITIES_BY_PROPERTY_ID_URL,SAVE_MESSAGE_TEMPLATE_URL,GET_ALL_USERS_SUBTERRITORY,GET_ALL_USERS_SUBTERRITORY_BY_ID_URL,
  CREATE_COMPANY_USER_CAMPAIGN_URL,GET_COMPANY_USER_CAMPAIGN_BY_ID_URL,GET_MESSAGE_TEMPLATE_URL,GET_CONTACT_LIST_URL,GET_CONTACT_BY_ID_URL,
  DELETE_COMPANY_USER_CAMPAIGN_BY_ID_URL,DUPLICATE_COMPANY_USER_CAMPAIGN_BY_ID_URL,CHANGE_CAMPAIGN_STATUS_URL,GET_DASHBOARD_URL,UNSUBSCRIBE_URL,
  GET_ALL_COMPANY_USER_CAMPAIGN_URL,GET_COMPANY_USERS_MAILBOX_URL,GET_PROPERITIES_BY_CAMPAIGN_URL, UPDATE_CONTACT_STATUS_URL} from '../../../ApiServices';
import { getSession } from '../../Auth/sessionData';


export const get_all_compnay_user_territories = () => async dispatch => {
    try {
    const session_data=getSession();
      let compnay_users_territories_List= await API.get(COMPANY_USERS_TERRITORY_LIST_URL, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {
          
          return result.data;
        }
        
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
               // ;
                dispatch({
                    type: GET_ALL_COMPANY_USER_TERRITORIES_ERROR,
                    payload: err.response.data.msg
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: GET_ALL_COMPANY_USER_TERRITORIES_ERROR,
                  payload: err.response.data.msg
              })
          } else if (err.response.status === 500) {
                dispatch({
                    type: GET_ALL_COMPANY_USER_TERRITORIES_ERROR,
                    payload: err.response.data.msg
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: GET_ALL_COMPANY_USER_TERRITORIES_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: GET_ALL_COMPANY_USER_TERRITORIES_ERROR,
                payload: "Something went wrong"
            })
          }
        });
        return compnay_users_territories_List;
    }
    catch (e) {
        dispatch({
            type: GET_ALL_COMPANY_USER_TERRITORIES_ERROR,
            payload: "Something went wrong",
        })
    }
  
  }

export const get_all_contacts_list = () => async dispatch => {
  try {
  const session_data=getSession();
    let contact_List= await API.get(GET_CONTACT_LIST_URL, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
        
        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              // ;
              dispatch({
                  type: GET_ALL_CONTACTS_LIST_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_ALL_CONTACTS_LIST_ERROR,
                payload: err.response.data.msg
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_ALL_CONTACTS_LIST_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_ALL_CONTACTS_LIST_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_ALL_CONTACTS_LIST_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return contact_List;
  }
  catch (e) {
      dispatch({
          type: GET_ALL_CONTACTS_LIST_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const get_dashboard_data = () => async dispatch => {
  try {
  const session_data=getSession();
    let dashboardData= await API.get(GET_DASHBOARD_URL, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
        
        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              // ;
              dispatch({
                  type: GET_DASHBOARD_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_DASHBOARD_ERROR,
                payload: err.response.data.msg
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_DASHBOARD_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_DASHBOARD_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_DASHBOARD_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return dashboardData;
  }
  catch (e) {
      dispatch({
          type: GET_DASHBOARD_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const get_all_compnay_user_subterritories = () => async dispatch => {
    try {
    const session_data=getSession();
      let compnay_users_subterritories_List= await API.get(GET_ALL_USERS_SUBTERRITORY, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {

          return result.data;
        }
        
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
               // ;
                dispatch({
                    type: GET_ALL_USERS_SUBTERRITORY_ERROR,
                    payload: err.response.data.msg
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: GET_ALL_USERS_SUBTERRITORY_ERROR,
                  payload: err.response.data.msg
              })
          } else if (err.response.status === 500) {
                dispatch({
                    type: GET_ALL_USERS_SUBTERRITORY_ERROR,
                    payload: err.response.data.msg
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: GET_ALL_USERS_SUBTERRITORY_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: GET_ALL_USERS_SUBTERRITORY_ERROR,
                payload: "Something went wrong"
            })
          }
        });
        return compnay_users_subterritories_List;
    }
    catch (e) {
        dispatch({
            type: GET_ALL_USERS_SUBTERRITORY_ERROR,
            payload: "Something went wrong",
        })
    }
  
  }

export const get_all_compnay_user_triggers = () => async dispatch => {
    try {
    const session_data=getSession();
      let compnay_users_triggers_List= await API.get(COMPANY_USERS_TRIGGER_URL, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {

          return result.data;
        }
        
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
               // ;
                dispatch({
                    type: GET_ALL_COMPANY_USER_TRIGGERS_ERROR,
                    payload: err.response.data.msg
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: GET_ALL_COMPANY_USER_TRIGGERS_ERROR,
                  payload: err.response.data.msg
              })
          } else if (err.response.status === 500) {
                dispatch({
                    type: GET_ALL_COMPANY_USER_TRIGGERS_ERROR,
                    payload: err.response.data.msg
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: GET_ALL_COMPANY_USER_TRIGGERS_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: GET_ALL_COMPANY_USER_TRIGGERS_ERROR,
                payload: "Something went wrong"
            })
          }
        });
        return compnay_users_triggers_List;
    }
    catch (e) {
        dispatch({
            type: GET_ALL_COMPANY_USER_TRIGGERS_ERROR,
            payload: "Something went wrong",
        })
    }
  
  }

export const get_all_compnay_users_mail_boxes = () => async dispatch => {
  try {
  const session_data=getSession();
    let compnay_users_mailboxes_List= await API.get(GET_COMPANY_USERS_MAILBOX_URL, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {

        return result.data;
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              // ;
              dispatch({
                  type: GET_COMPANY_USERS_MAILBOX_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_COMPANY_USERS_MAILBOX_ERROR,
                payload: err.response.data.msg
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_COMPANY_USERS_MAILBOX_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_COMPANY_USERS_MAILBOX_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_COMPANY_USERS_MAILBOX_ERROR,
              payload: "Something went wrong"
          })
        }
      });
      return compnay_users_mailboxes_List;
  }
  catch (e) {
      dispatch({
          type: GET_COMPANY_USERS_MAILBOX_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const get_compnay_user_template_by_id = (payload_data) => async dispatch => {
  try {
  const session_data=getSession();
    await API.get(GET_MESSAGE_TEMPLATE_URL + payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      dispatch({
        type: GET_MESSAGE_TEMPLATE,
        payload: result.data,
    })
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              // ;
              dispatch({
                  type: GET_MESSAGE_TEMPLATE_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_MESSAGE_TEMPLATE_ERROR,
                payload: err.response.data.msg
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_MESSAGE_TEMPLATE_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_MESSAGE_TEMPLATE_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_MESSAGE_TEMPLATE_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  }
  catch (e) {
      dispatch({
          type: GET_MESSAGE_TEMPLATE_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const get_contact_data_by_id = (payload_data,history) => async dispatch => {
  try {
  const session_data=getSession();
    await API.get(GET_CONTACT_BY_ID_URL + payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      dispatch({
        type: GET_CONTACTS_DATA_BY_ID,
        payload: result.data,
    });
    //console.log(history)
    if(history.location.pathname === '/CompanyContact'){
      history.push('/ViewCompanyContact');
    }
    if(history.location.pathname === '/Contact'){
      history.push('/ViewContact');
    }
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              // ;
              dispatch({
                  type: GET_CONTACTS_DATA_BY_ID_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_CONTACTS_DATA_BY_ID_ERROR,
                payload: err.response.data.msg
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_CONTACTS_DATA_BY_ID_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_CONTACTS_DATA_BY_ID_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_CONTACTS_DATA_BY_ID_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  }
  catch (e) {
      dispatch({
          type: GET_CONTACTS_DATA_BY_ID_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const get_properities_by_campagin_id = (payload_data) => async dispatch => {
  try {
  const session_data=getSession();
  await API.post(GET_PROPERITIES_BY_CAMPAIGN_URL , payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //return result.data;
      dispatch({
        type: GET_PROPERITIES_BY_CAMPAIGN_ID,
        payload: result.data,
    })
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              // ;
              dispatch({
                  type: GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR,
                payload: err.response.data.msg
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  }
  catch (e) {
      dispatch({
          type: GET_PROPERITIES_BY_CAMPAIGN_ID_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const get_properities_details_by_propety_id = (payload_data,history) => async dispatch => {
  try {
  const session_data=getSession();
  await API.get(GET_PROPERITIES_BY_PROPERTY_ID_URL + payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //return result.data;
      dispatch({
        type: GET_PROPERITIES_DETAILS_BY_ID,
        payload: result.data,
    })
    //console.log(history.location.pathname);
    // if(history.location.pathname === '/ViewCompanyContact'){
    //   history.push('/ViewCompanyPropertyDetails');
    // }
    // if(history.location.pathname === '/ViewContact'){
    //   history.push('/ViewPropertyDetails');
    // }
    // if(history.location.pathname === '/ViewCampaign'){
    //   history.push('/ViewPropertyDetails');
    // }
    history.push('/ViewPropertyDetails');
      }
      
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              // ;
              dispatch({
                  type: GET_PROPERITIES_DETAILS_BY_ID_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_PROPERITIES_DETAILS_BY_ID_ERROR,
                payload: err.response.data.msg
            })
        } else if (err.response.status === 500) {
              dispatch({
                  type: GET_PROPERITIES_DETAILS_BY_ID_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_PROPERITIES_DETAILS_BY_ID_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_PROPERITIES_DETAILS_BY_ID_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  }
  catch (e) {
      dispatch({
          type: GET_PROPERITIES_DETAILS_BY_ID_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const create_campaign = (payload_data,history) => async dispatch => {
    try {
        const session_data=getSession();
      API.post(CREATE_COMPANY_USER_CAMPAIGN_URL,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {
        if(session_data.userType === "COMPANY_ADMIN"){
          history.push('/AllCampaign');
        } 
        if(session_data.userType === "COMPANY_USER"){
          history.push('/AllCampaign');
        }
        }
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
                
               // ;
                dispatch({
                    type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
                    payload: err.response.data.msg
                })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                  type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 403) {
            //;
            dispatch({
                type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 404) {
            //;
            dispatch({
                type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
                payload: err.response.data.msg
            })
        }  else if (err.response.status === 500) {
                dispatch({
                    type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
                    payload: err.response.data.msg
                })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
                type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
                payload: "Something went wrong"
            })
          } else {
            // anything else
            dispatch({
                type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
                payload: "Something went wrong"
            })
          }
        });
    
    
       
    }
    catch (e) {
        dispatch({
            type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
            payload: "Something went wrong",
        })
    }

}

export const update_contact_status = (payload_data) => async dispatch => {
  try {
      const session_data=getSession();
    API.post(UPDATE_CONTACT_STATUS_URL,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
       // history.push('/AllCampaign');
       dispatch({
        type: UPDATE_CONTACT_STATUS,
        payload: result.data.msg
    })
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              
             // ;



              dispatch({
                  type: UPDATE_CONTACT_STATUS_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: UPDATE_CONTACT_STATUS_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 403) {
          //;
          dispatch({
              type: UPDATE_CONTACT_STATUS_ERROR,
              payload: err.response.data.msg
          })
      }else if (err.response.status === 404) {
          //;
          dispatch({
              type: UPDATE_CONTACT_STATUS_ERROR,
              payload: err.response.data.msg
          })
      }  else if (err.response.status === 500) {
              dispatch({
                  type: UPDATE_CONTACT_STATUS_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: UPDATE_CONTACT_STATUS_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: UPDATE_CONTACT_STATUS_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  
  
     
  }
  catch (e) {
      dispatch({
          type: CREATE_COMPANY_USER_CAMPAIGN_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const create_template = (payload_data,history) => async dispatch => {
  try {
      const session_data=getSession();
    API.post(SAVE_MESSAGE_TEMPLATE_URL,payload_data, { headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      // debugger
      dispatch({
        type: SAVE_MESSAGE_TEMPLATE,
        payload: "Email Template saved successfully......!"
    })
        // window.location.reload(false)
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              
             // ;
              dispatch({
                  type: SAVE_MESSAGE_TEMPLATE_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: SAVE_MESSAGE_TEMPLATE_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 403) {
          //;
          dispatch({
              type: SAVE_MESSAGE_TEMPLATE_ERROR,
              payload: err.response.data.msg
          })
      }else if (err.response.status === 404) {
          //;
          dispatch({
              type: SAVE_MESSAGE_TEMPLATE_ERROR,
              payload: err.response.data.msg
          })
      }  else if (err.response.status === 500) {
              dispatch({
                  type: SAVE_MESSAGE_TEMPLATE_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: SAVE_MESSAGE_TEMPLATE_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: SAVE_MESSAGE_TEMPLATE_ERROR,
              payload: "Something went wrong"
          })
        }
      });
  }
  catch (e) {
      dispatch({
          type: SAVE_MESSAGE_TEMPLATE_ERROR,
          payload: "Something went wrong",
      })
  }

}

export const storeUserCampaignId = (payload_data,history,routrVal) => async dispatch => {
   
  try {
    const session_data=getSession();
    await API.get(GET_COMPANY_USER_CAMPAIGN_BY_ID_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
      
          dispatch({
            type: STORE_COMPNAY_USER_CAMPAIGN_ID,
            payload: result.data,

        });
        if(routrVal === "Edit"){
          if(session_data.userType === "COMPANY_ADMIN"){
            history.push('/UpdateCampaign');
          } 
          if(session_data.userType === "COMPANY_USER"){
            history.push('/UpdateCampaign');
          }
          
        }
        if(routrVal === "View"){
          history.push('/ViewCampaign');
          if(session_data.userType === "COMPANY_ADMIN"){
            history.push('/ViewCampaign');
          } 
          if(session_data.userType === "COMPANY_USER"){
            history.push('/ViewCampaign');
          }
        }
         // return result.data;
          
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: STORE_COMPNAY_USER_CAMPAIGN_ID_ERROR,
          payload: 'Something went wrong',
      })
  }

}

export const company_users_Campaign_Listing = () => async dispatch => {
    try {
        const session_data=getSession();
      let companyUsersCampaignList=await API.get(GET_ALL_COMPANY_USER_CAMPAIGN_URL,{ headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {
        //console.log(result.data.companyDTOS);
           return result.data.campaigns;
        }
      )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              
             // ;
              dispatch({
                  type: GET_ALL_COMPANY_USER_CAMPAIGN_ERROR,
                  payload: err.response.data.msg
              })
          }else if (err.response.status === 401) {
            //;
            dispatch({
                type: GET_ALL_COMPANY_USER_CAMPAIGN_ERROR,
                payload: err.response.data.msg
            })
        }else if (err.response.status === 403) {
          //;
          dispatch({
              type: GET_ALL_COMPANY_USER_CAMPAIGN_ERROR,
              payload: err.response.data.msg
          })
      }else if (err.response.status === 404) {
          //;
          dispatch({
              type: GET_ALL_COMPANY_USER_CAMPAIGN_ERROR,
              payload: err.response.data.msg
          })
      }  else if (err.response.status === 500) {
              dispatch({
                  type: GET_ALL_COMPANY_USER_CAMPAIGN_ERROR,
                  payload: err.response.data.msg
              })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
              type: GET_ALL_COMPANY_USER_CAMPAIGN_ERROR,
              payload: "Something went wrong"
          })
        } else {
          // anything else
          dispatch({
              type: GET_ALL_COMPANY_USER_CAMPAIGN_ERROR,
              payload: "Something went wrong"
          })
        }
      });
    
        return companyUsersCampaignList;
       
    }
    catch (e) {
        dispatch({
            type: GET_ALL_COMPANY_USER_CAMPAIGN_ERROR,
            payload: "Something went wrong",
        })
    }
  
  }

export const updateCompanyUserCampaignData = (name, value) => async dispatch => {
  //;
  dispatch({
      type: UPDATE_COMPANY_USER_CAMPAIGN_DATA,
      payload: {
          name,
          value
      },
  })
}

export const updateCompanyUserCampaignTerritories = (payload_data) => async dispatch => {
  // ;
  // console.log(payload_data);
  dispatch({
      type: UPDATE_COMPANY_USER_CAMPAIGN_TERRITORIES,
      payload: payload_data,
  })
}

export const updateCompanyUserCampaignTriggerData = (payload_data) => async dispatch => {
  // ;
  // console.log(payload_data);
  dispatch({
      type: UPDATE_COMPANY_USER_CAMPAIGN_TRIGGER,
      payload: payload_data,
  })
}


export const updateEmailDetails = (name,value) => async dispatch => {
  dispatch({
      type: UPDATE_COMPANY_USER_EMAIL_TEMPLATE,
      payload: {
        name,
        value
    },
  })
}

export const deleteUserCampaignId = (payload_data) => async dispatch => {
  try {
    const session_data=getSession();
    await API.delete(DELETE_COMPANY_USER_CAMPAIGN_BY_ID_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
          // return result.data;
          dispatch({
          type: DELETE_COMPANY_USER_CAMPAIGN,
          payload: result.data.msg
          })
          window.location.reload(false);
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: DELETE_COMPANY_USER_CAMPAIGN_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: DELETE_COMPANY_USER_CAMPAIGN_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: DELETE_COMPANY_USER_CAMPAIGN_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: DELETE_COMPANY_USER_CAMPAIGN_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: DELETE_COMPANY_USER_CAMPAIGN_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: DELETE_COMPANY_USER_CAMPAIGN_ERROR,
          payload: 'Something went wrong',
      })
  }

}

export const dupilcateUserCampaignId = (payload_data) => async dispatch => {
  
  try {
    const session_data=getSession();
    await API.get(DUPLICATE_COMPANY_USER_CAMPAIGN_BY_ID_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
          // return result.data;
          dispatch({
          type: DUPLICATE_COMPANY_USER_CAMPAIGN,
          payload: result.data.msg
          })
          window.location.reload(false);
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: DUPLICATE_COMPANY_USER_CAMPAIGN_ERROR,
          payload: 'Something went wrong',
      })
  }

}

export const storeSubTerritoryId = (payload_data,history) => async dispatch => {
  
  try {
    const session_data=getSession();
    await API.get(GET_ALL_USERS_SUBTERRITORY_BY_ID_URL + payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
    .then(result => {
      //console.log(result.data.companyDTOS);
      
          dispatch({
            type: GET_USERS_SUBTERRITORY_BY_ID,
            payload: result.data,

        });
        history.push('/ViewTerritory');
          // return result.data;
          
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: GET_USERS_SUBTERRITORY_BY_ID_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: GET_USERS_SUBTERRITORY_BY_ID_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: GET_USERS_SUBTERRITORY_BY_ID_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: GET_USERS_SUBTERRITORY_BY_ID_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: GET_USERS_SUBTERRITORY_BY_ID_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: GET_USERS_SUBTERRITORY_BY_ID_ERROR,
          payload: 'Something went wrong',
      })
  }

}



export const changeCampaignStatusByID = (payload_data) => async dispatch => {
   
    try {
      const session_data=getSession();
      await API.post(CHANGE_CAMPAIGN_STATUS_URL,payload_data,{ headers: { Authorization: `Bearer ${session_data.token}` } })
      .then(result => {
           // return result.data;
          dispatch({
            type: CHANGE_CAMPAIGN_STATUS,
            payload: "Status changed successfully.........!"
          })
          window.location.reload(false);
        }
      )
        .catch(err => {
          if (err.response) {
            if (err.response.status === 400) {
                //;
                dispatch({
                  type: CHANGE_CAMPAIGN_STATUS_ERROR,
                  payload: err.response.data.msg
              })
            }else if (err.response.status === 401) {
              //;
              dispatch({
                type: CHANGE_CAMPAIGN_STATUS_ERROR,
                payload: err.response.data.msg
            })
          } else if (err.response.status === 500) {
            dispatch({
              type: CHANGE_CAMPAIGN_STATUS_ERROR,
              payload: err.response.data.msg
          })
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
            dispatch({
              type: CHANGE_CAMPAIGN_STATUS_ERROR,
              payload: 'Something went wrong'
          })
            
          } else {
            // anything else
            dispatch({
              type: CHANGE_CAMPAIGN_STATUS_ERROR,
              payload: 'Something went wrong'
          })
            
          }
        });
    
    }
    catch (e) {
        dispatch({
            type: CHANGE_CAMPAIGN_STATUS_ERROR,
            payload: 'Something went wrong',
        })
    }
  }


export const closeAlert = (payload_data) => async dispatch => {
  try{
    dispatch({
      type: CLOSE_ALERT,
      payload: payload_data,
  })
  }
  catch (e) {
      dispatch({
          type: CLOSE_ALERT,
          payload: payload_data
      })
  }
}


export const unsubscribe_email = (payload_data) => async dispatch => {
   
  try {
    await API.get(UNSUBSCRIBE_URL + payload_data)
    .then(result => {
      //console.log(result.data.companyDTOS);
      
          dispatch({
            type: UNSUBSCRIBE,
            payload: 'success',

        });
          
      }
    )
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
              //;
              dispatch({
                type: UNSUBSCRIBE_ERROR,
                payload: err.response.data.msg
            })
          }else if (err.response.status === 401) {
            //;
            dispatch({
              type: UNSUBSCRIBE_ERROR,
              payload: err.response.data.msg
          })
        } else if (err.response.status === 500) {
          dispatch({
            type: UNSUBSCRIBE_ERROR,
            payload: err.response.data.msg
        })
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
          dispatch({
            type: UNSUBSCRIBE_ERROR,
            payload: 'Something went wrong'
        })
          
        } else {
          // anything else
          dispatch({
            type: UNSUBSCRIBE_ERROR,
            payload: 'Something went wrong'
        })
          
        }
      });
  
  }
  catch (e) {
      dispatch({
          type: UNSUBSCRIBE_ERROR,
          payload: 'Something went wrong',
      })
  }

}