import { CLOSE_ALERT,LOGIN_CHECK, LOGIN_CHECK_ERROR,RESET_PASSWORD_ERROR,FORGOT_PASSWORD,FORGOT_PASSWORD_ERROR,CHANGE_PASSWORD,CHANGE_PASSWORD_ERROR,GET_PROFILE,GET_PROFILE_ERROR,UPDATE_PROFILE_DATA,SAVE_PROFILE,SAVE_PROFILE_ERROR } from '../types';
// import {setAuthHeader} from '../services/APIBuilder';

const initialState = {
    userDetails: {
    },
    error: '',
    alert:{
        show:false,
        resultType: '',
        msg:'',
    }
}

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case LOGIN_CHECK:
            //
            // setAuthHeader(action.payload.token);
            return {
                ...state,
                userDetails: action.payload,
                error: "",
            }
        case LOGIN_CHECK_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        // case RESET_PASSWORD:
        //     return {
        //         ...state,
        //         alert:{
        //             ...alert,
        //             show:true,
        //             resultType: 'danger',
        //             msg:action.payload
        //         }
        //     }
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case FORGOT_PASSWORD:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'success',
                    msg:action.payload
                }
            }
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case SAVE_PROFILE:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'success',
                    msg:action.payload
                }
            }
        case SAVE_PROFILE_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case GET_PROFILE:
            return {
                ...state,
                userDetails: action.payload,
            }
        case GET_PROFILE_ERROR:
            return {
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        case UPDATE_PROFILE_DATA:
            return{
                ...state,
                userDetails: {
                    ...state.userDetails,
                    [action.payload.name]: action.payload.value
                }
            }
        case CLOSE_ALERT:
            return{
                ...state,
                alert:{
                    ...state.alert,
                    show:false,
                }
            }
        case CHANGE_PASSWORD:
            return{
                ...state,
                alert:{
                    ...alert,
                    show:true,
                    resultType: 'success',
                    msg:action.payload
                }
            }
        case CHANGE_PASSWORD_ERROR:
            return{
                ...state,
                alert:{
                    ...state.alert,
                    show:true,
                    resultType: 'danger',
                    msg:action.payload
                }
            }
        default: return state
    }

}