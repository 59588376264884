import React, { useEffect, useState } from 'react';
import { Link,withRouter } from 'react-router-dom';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";
import { connect } from 'react-redux';
import {get_all_compnay_user_subterritories,get_all_compnay_user_triggers,changeCampaignStatusByID,closeAlert,get_all_compnay_users_mail_boxes,updateCompanyUserCampaignTriggerData,create_campaign,updateCompanyUserCampaignData,updateCompanyUserCampaignTerritories} from '../../../redux/action/companyUser/companyUserActionCreator';
// import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import DrawMap from './map'
import Geocode from 'react-geocode';
import Alert from 'react-bootstrap/Alert';

const UpdateCampaign = (props) =>{
    const [triggersList,setTriggersList] =useState([]);
    const [territoriesList,setTerritoriesList] =useState([]); 
    // const [territoryName, setTerritoryName] = useState('');
    // const [polygon, setPolygon] = useState(false);
    const [disable, setDisable] = useState(false);
    // const [zipChange, setZipchange] = useState(true);
    
    // const [selectedInputList, setSelectedInputList] = useState([]);
    // const [territories,setTerritories] =useState([]);
    //const [territories,setTerritories] =useState('');
    //const [inputlistErr, setInputListErr] = useState('');
    // const [addMore, setAddMore] = useState(false);
    // const [inputList, setInputList] = useState([]);
    const [mailBoxList, setMailBoxList] = useState('');
    const [mapLatLong, setMapLatLong] = useState({lat:-34.397, lng:150.644});
    //const [mapOpen, setMapOpen] = useState(false);
    // const [editPolygonView, setEditPolygonView] = useState(false);
    const [showMap, setShowMap] = useState();
    // const [selectedTerritoryMap,setSelectedTerritoryMap] =useState();
    const [error,setError]=useState({
      campaginNameErr:'',
      campaginEmailErr:'',
      campaginTerritoryErr:'',
      triggerErr:'',
    });

    
  useEffect(()=>{
    if(props.campaignDetails.status === "ACTIVE"){
        setDisable(true)
    }else{
      setDisable(false)

    }

   // eslint-disable-next-line react-hooks/exhaustive-deps
},[setDisable]);

useEffect(()=>{
  if(props.campaignDetails.companySubTerritoriesDTO){
  Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
  Geocode.fromAddress(props.campaignDetails.companySubTerritoriesDTO[0].zipcode[0].zipCode || 'USA').then(
   (response) => {
           const data = response.results[0].geometry.location;
           setMapLatLong({lat: data.lat,lng: data.lng});
           setShowMap(true);
              },
   (error) => {
     setMapLatLong({lat:-34.397, lng:150.644});
   });
  }
  
   // eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

    useEffect(()=>{
        props.get_all_compnay_user_subterritories().then(result => {
            setTerritoriesList(result);
           
           
        })
          props.get_all_compnay_user_triggers().then(result => {
            setTriggersList(result); });
          props.get_all_compnay_users_mail_boxes().then(result => {
            setMailBoxList(result);
            })
          
    props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    // console.log(props.campaignDetails.triggerDTO.)
   
// const {emailAddresses} =mailBoxList;
const  mailBoxOptions  = mailBoxList && mailBoxList.map((item,i) => {
  return <option value={item.emailAddress} >{item.emailAddress}</option>
  });

//emailChangeHangler
const emailChangeHangler=(e)=>{
  let mailDetails=mailBoxList && mailBoxList.filter(item => item.emailAddress === (e.target.value).toString());
  props.updateCompanyUserCampaignData("mailBox",mailDetails[0]);
}

const setTriggersHandler =(e)=>{
  props.updateCompanyUserCampaignData("triggerDTO",e)
}
    const  {companySubTerritoryDTOS}  = territoriesList || {};
    const  options  = companySubTerritoryDTOS && companySubTerritoryDTOS.map(item => {
      // return <option id={item.id} key={item.id} data-key={item.id}>{item.territoryName}</option>
        const val= {  id: item.companySubTerritoryId, label:  item.territoryName,value: item.territoryName};
        return val;
      });
      
      const  triggerOptions  = triggersList && triggersList.map(item => {
        // return <option id={item.id} key={item.id} data-key={item.id}>{item.triggerDisplayName}</option>
          const val= {  id: item.id, triggerDisplayName:item.triggerDisplayName,label:  item.triggerDisplayName,value: item.triggerDisplayName};
          return val;
        });
    
    const selectedTriggerList = props.campaignDetails.triggerDTO.map(item =>{
      const val= {  id: item.id,triggerDisplayName:item.triggerDisplayName, label:  item.triggerDisplayName,value: item.triggerDisplayName};
          return val;
    })
    // const triggerIdHandler = (e) =>{

    //   let trigger=[];
    //     const selectedIndex = e.target.options.selectedIndex;
    //    let triggerId=e.target.options[selectedIndex].getAttribute('data-key');
    //    trigger={
    //        id:parseInt(triggerId),
    //        triggerDisplayName: e.target.value,
    //        triggerValue: e.target.value
    //    }
    //     props.updateCompanyUserCampaignTriggerData(trigger);
    // }

    const territoryOptions = props.campaignDetails.companySubTerritoriesDTO && props.campaignDetails.companySubTerritoriesDTO.map(item =>{
      const val= {  id: item.id,label:  item.territoryName,value: item.territoryName};
          return val;
    })
    let zipcodeData='';
    let polygonsCordsList = [];
    if(props.campaignDetails){
    if(props.campaignDetails.companySubTerritoriesDTO.length>0){
            props.campaignDetails.companySubTerritoriesDTO.map(item => {
             // console.log(item)
          const polygonCords = item.polygonsDTO.coordinates && item.polygonsDTO.coordinates.map(coordinate => 
            {
           const  latLng  = {lng:parseFloat(coordinate.longitude),lat: parseFloat(coordinate.latitude)}
          return latLng}
            )
       const zip= item.zipcode.map(zip =>
        {
          return {label: zip.zipCode, value: zip.zipCode,id:zip.id, boundaries:zip.boundaries}
        });
          zipcodeData = [...zipcodeData, ...zip];
          polygonsCordsList = [...polygonsCordsList, polygonCords];
          return zip;
          })
        }    
    };


    //change status
    const statusChangeHandler = (e,statusType) =>{
      e.preventDefault();
      props.updateCompanyUserCampaignData("status",statusType);
      const payload_data={
        campaignId: props.campaignDetails.id,
        campaignStatusEnum: statusType
      }
     // console.log(payload_data)
      props.changeCampaignStatusByID(payload_data);
      if(statusType === "ACTIVE"){
        setDisable(true)
      }
      if(statusType === "STOP" || statusType === "DRAFT"){
        setDisable(false)
      }

    }
    // console.log(zipcodeList)
    //set zipcode
    const setZipcodeHandler = (e) => {
      if(e.length !== 0){

        let value='';
       
       const territories=e.map(territory =>{
          
         value = companySubTerritoryDTOS.filter(item => item.territoryName === territory.value);
         return value[0];
        });
     // var value = companySubTerritoryDTOS.filter(item => item.territoryName === e.target.value);
      props.updateCompanyUserCampaignTerritories(territories);
         }else{

          props.updateCompanyUserCampaignTerritories('');
         }

        }

    
    // handle map
    //   const handlePolygonChange = (e) => {
    //   //debugger
    //   setPolygon(!polygon);
    //   if(e === true){
    //   Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
    //   Geocode.fromAddress(territoryName || props.campaignDetails.companySubTerritoriesDTO[0].territoryName).then(
    //     (response) => {
    //       const data = response.results[0].geometry.location;
    //       setShowMap(true);
    //       setMapLatLong({ lat: data.lat, lng: data.lng });
    //     },
    //     (error) => {
    //       setMapLatLong({ lat: -34.397, lng: 150.644 });
    //       setShowMap(true);
    //     })
    //   }else{
    //     setShowMap(false);
    //   }
    // };

  const validate = (e) => {
    // console.log('hi')
    let campaginNameVal = territoryNameValidation(props.campaignDetails.campaign_name);
    let mailboxVal = mailboxValidation(props.campaignDetails.mailBox);
    let trrigerListVal = trrigerListValidation(props.campaignDetails.triggerDTO);
    let subterritroyVal = subterritroyValidation(props.campaignDetails.companySubTerritoriesDTO);
    if(campaginNameVal === true && mailboxVal === true && trrigerListVal === true && subterritroyVal === true){
      return true;
    }
  }

  function territoryNameValidation(campaginNameVal) { 

    if(!campaginNameVal) {
        setError(prevState => ({
            ...prevState,
            territoryNameErr: 'Please Enter Territory Name'
        }));
      return false;
    }
    // if (!/^[a-zA-Z ]+$/.test(campaginNameVal)){
    //     setError(prevState => ({
    //         ...prevState,
    //         territoryNameErr: 'Please Enter Only Characters'
    //     }));
    //   return false;
    // }
    if(campaginNameVal ) {
        setError(prevState => ({
            ...prevState,
            territoryNameErr: ''
        }));
      return true;
    }
  }
  function mailboxValidation(mailboxVal) { 
    if(mailboxVal.length === 0){
        setError(prevState => ({
            ...prevState,
            campaginEmailErr: 'Please Select Email Box'
        }));
        return false;
    }
    if(mailboxVal.emailAddress !== "" ){
        setError(prevState => ({
            ...prevState,
            campaginEmailErr: ''
        }));
        return true;
    }
  }
  function trrigerListValidation(trrigerListVal) {
    if(trrigerListVal.length === 0){
        setError(prevState => ({
            ...prevState,
            triggerErr: 'Please Select Trigger'
        }));
        return false;
    }
    if(trrigerListVal.length >0){
        setError(prevState => ({
            ...prevState,
            triggerErr: ''
        }));
        return true;
    }

  }
  function subterritroyValidation(subterritroyVal) {
    if(subterritroyVal.length === 0){
        setError(prevState => ({
            ...prevState,
            campaginTerritoryErr: 'Please Select Territory'
        }));
        return false;
    }
    if(subterritroyVal.length >0){
        setError(prevState => ({
            ...prevState,
            campaginTerritoryErr: ''
        }));
        return true;
    }

  }

    const addCampaignHandler = async (e) =>{
        e.preventDefault();
        //console.log(props.campaignDetails.companySubTerritoriesDTO[0]);
      // console.log(subTerritoryDetails)
        if(validate()){
          const subTerritoryDetails = props.campaignDetails && props.campaignDetails.companySubTerritoriesDTO.map(item => {
            // console.log(item)
            return {id: item.id,territoryName:item.territoryName}})
     
        const payload_data= { 
            companySubTerritoryDTO: subTerritoryDetails,
            campaign_name: props.campaignDetails.campaign_name,
            mailBox: props.campaignDetails.mailBox,
            // source: campaginSource,
            status: props.campaignDetails.status,
            triggers: props.campaignDetails.triggerDTO,
            id:props.campaignDetails.id,
          };
      // console.log(payload_data);
       props.create_campaign(payload_data,props.history);
      }
    }


    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Update Campaign</h1>
                    <Alert id="alert" className="alert" variant={props.companyalert.resultType} show={props.companyalert.show} 
                            onClose={() => props.closeAlert(false)} dismissible
                            >
                              <p>{props.companyalert.msg}</p>
                    </Alert>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <div className="row">
                              <div className="col-lg-3">
                                    <div className="form-group" style={{display:'inline-flex',padding:'4px 0px'}}>
                                      <label>Campaign Status: &nbsp;</label> <strong>{props.campaignDetails.status}</strong>
                                    </div>
                              </div>
                              <div className="col-lg-2">
                                    <div className="form-group">
                              <button className="btn btn-primary btn-sts" disabled={disable} onClick={(e)=>statusChangeHandler(e,"ACTIVE")}>ACTIVE</button> 
                              </div>
                              </div>
                              <div className="col-lg-2">
                                
                              <div className="form-group">
                              <button className="btn btn-primary btn-sts" disabled={!disable} onClick={(e)=>statusChangeHandler(e,"STOP")}> Stop </button> 
                              </div>
                              </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="name">Campaign Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Campaign Name"
                                        value={props.campaignDetails.campaign_name || ''}
                                        onChange={(e) => props.updateCompanyUserCampaignData("campaign_name",e.target.value)}
                                        />
                                        {error.territoryNameErr ? <span className="error-span">{error.territoryNameErr}</span>:null}
                                    </div>
                                    
                                </div>
                                <div className="col-md-4">
                                    
                                    <div className="form-group">
                                    <label htmlFor="mailbox">Assign Mail Box</label>
                                    <select
                                         value={props.campaignDetails.mailBox.emailAddress || ''}
                                        defaultValue={props.campaignDetails.mailBox.emailAddress || ''}
                                         className="form-control"
                                         onChange={(e) => emailChangeHangler(e)}
                                    >
                                      {/* <option value="0">Assign Mail Box</option> */}
                                           {mailBoxOptions} 
                                        </select>
                                    {error.campaginEmailErr ? <span className="error-span">{error.campaginEmailErr}</span>:null}
                                        
                                    </div>
                                    
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                    <label htmlFor="trigger">Select Triggers</label>
                                    <MultiSelect options={triggerOptions || []} value={selectedTriggerList} placeholder="Select Triggers" onChange={setTriggersHandler} />
                                    {error.triggerErr ? <span className="error-span">{error.triggerErr}</span>:null}
                                    </div>
                                </div>
                            
                               </div>
                               <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                    <label htmlFor="territory">Assign Territory</label>
                                    <MultiSelect options={options||[]} value={territoryOptions || []} placeholder="Assign Territory" onChange={setZipcodeHandler} />

                                    {/* <select
                                         className="form-control"
                                         value={props.campaignDetails.companySubTerritoriesDTO[0].territoryName || ''}
                                        onChange={setZipcodeHandler}
                                    >
                                           {options} 
                                        </select> */}
                                  {error.campaginTerritoryErr ? <span className="error-span">{error.campaginTerritoryErr}</span>:null}

                                </div>
                                </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label htmlFor="zipcode">Assigned Zipcodes</label>
                                      <MultiSelect options={[]} value={zipcodeData || []} placeholder="Assign Zipcode" className="disabledinpt" isDisabled={true} closeMenuOnSelect={true} isSearchable={false} />
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-3">
                                    <div className="from-group">
                                      <label htmlFor="toggle" style={{visibility:'hidden',display:'block'}}>Toggle Map</label>
                                      <BootstrapSwitchButton
                                        checked={polygon}
                                        name="polygon"
                                        onlabel='Hide Map'
                                        offlabel='Show Map'
                                        onChange={handlePolygonChange}
                                        width={120}
                                      />

                                    </div>
                                  </div> */}

                                </div>
                                {showMap ? 
                                <React.Fragment>
                                <label htmlFor="ploygon">Assigned polygon:</label>
                                <DrawMap
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    lat={mapLatLong.lat}
                                    lng={mapLatLong.lng}
                                    // setpolygonsDTO={Polygons}
                                    selectedpolygonsDTO={polygonsCordsList}
                                    editPolygon={showMap}
                                    setZipcodeBoundaries={zipcodeData}
                                  /> </React.Fragment>: null}

                                {/* {mapOpen ?
                                <DrawMap
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                  selectedTerritory={selectedTerritoryMap}
                                  // territoryList={inputList}
                                  lat={mapLatLong.lat}
                                  lng={mapLatLong.lng}
                                  // territoryInputlist={setTerritoryInputList}
                                  editPolygon={editPolygonView}
                                  polylineCordsList={territoriesList}
                                />:null}
                                    {showMap ? <DrawMap 
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k&v=3.exp&libraries=geometry,drawing,places"
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `400px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                          selectedTerritory={selectedTerritoryMap}
                                          // territoryList={selectedInputList}
                                          lat={mapLatLong.lat}
                                          lng={mapLatLong.lng}
                                          // territoryInputlist={setTerritoryInputList}
                                          editPolygon={editPolygonView}
                                          polylineCordsList={territoriesList}
                                      />:null} */}
                               <br/>
                        <div className="row">
                        <button className="btn btn-primary" onClick={addCampaignHandler}>Update Campaign</button> 
                        </div>
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}

const mapStateToProps = state => {
    return {
      companyalert: state.CompanyUser.alert,
      campaignDetails: state.CompanyUser.campaignDetails,
    }
}


export default connect(mapStateToProps,{get_all_compnay_user_subterritories,get_all_compnay_user_triggers,closeAlert,changeCampaignStatusByID,updateCompanyUserCampaignTriggerData,get_all_compnay_users_mail_boxes,create_campaign,updateCompanyUserCampaignData,updateCompanyUserCampaignTerritories})(withRouter(UpdateCampaign));
//export default CreateTerritory;