import React from 'react';
// import { useLocation  } from 'react-router-dom';
import Header from '../../Components/common/Header/header';
import LoginHeader from '../../Components/common/Header/header2';
// import Footer from '../../Components/common/Footer/footer';

const Layout = (props) =>{
    // const location=useLocation();
    return(
        <React.Fragment>
            {/* {location.pathname === "/Unsubscribe" ? 'active':'inactive'} */}
            <Header style={{display: props.userType && props.userType!=='' ? 'none':'block'}}></Header>
            <LoginHeader style={{display: props.userType && props.userType!=='' ? 'block':'none'}}></LoginHeader>
            {props.routes}
        </React.Fragment>
    );
}

export default Layout;