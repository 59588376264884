 import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {get_all_contacts_list,get_contact_data_by_id,closeAlert,update_contact_status} from '../../../redux/action/companyUser/companyUserActionCreator';
import Alert from 'react-bootstrap/Alert';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Loader from '../../../Components/common/Loader';
import ReactTooltip from 'react-tooltip';

const ContactList = (props) =>{
    const [companyUsersContactList, setCompaniesUserContactList] = useState([]);
    const [loaderAction, setLoaderAction] = useState(true);

    useEffect(()=>{
        props.get_all_contacts_list().then(result => {
            setCompaniesUserContactList(result);
            setLoaderAction(false);
            });
            
    props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

    
     //initialize datatable
    

    const viewContactHandler = (e,id) =>{
        props.get_contact_data_by_id(id,props.history);
    }

    const statusChangeHandler =(e,item,i)=>{
        let newTableData = companyUsersContactList;
        newTableData[i]['status'] = e.target.value;
        setCompaniesUserContactList(newTableData);
        const payload ={
            status:e.target.value,
            campaignId: item.campaignId,
            email: item.email
        }
        //console.log(payload);
         props.update_contact_status(payload);
    }

    return(<React.Fragment>
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Contacts</h1>
                        </div>
                        <div className="short d-flex align-self-center">
                            <ReactHTMLTableToExcel  
                                    className="btn btn-primary"   
                                    table="agentcontacts"  
                                    filename="Agent Contacts"  
                                    sheet="tablexls"  
                                    buttonText="Export Contacts" 
                                /> 
                        </div>
                    </div>
                           
                             
                            <Alert id="alert" className="alert" variant={props.campaginAlert.resultType} show={props.campaginAlert.show} 
                                onClose={() => props.closeAlert(false)} dismissible
                                >
                                <p>{props.campaginAlert.msg}</p>
                                </Alert>
                    {loaderAction ? <Loader /> :companyUsersContactList.length > 0 ||  companyUsersContactList === undefined ? 
                    <div className="all-agent properties-table bg-white">
                        
                        <table className="table" id="contacts" >
                            <thead>
                                <tr>
                                    <th scope="col"><strong  className="black">Name</strong></th>
                                    <th scope="col"><strong  className="black">Email</strong></th>
                                    {/* <th scope="col"><strong  className="black">Property Address</strong></th> */}
                                    <th scope="col"><strong  className="black">Phone Number</strong></th>
                                    <th scope="col"><strong  className="black">Status</strong></th>
                                    {/* <th scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {companyUsersContactList && companyUsersContactList.map((item,i) => {
                                    // let agent_name=
                                    return <tr key={item.id}>
                                    <td><Link to="#"  data-for={ item.name && item.name.length > 30 ? "soclose":''} data-tip={item.name && item.name.length > 30 ? item.name:''} onClick={(e)=>viewContactHandler(e,item.id)} >
                                        {item.name && item.name.length > 30 ? item.name.substring(0,30)+'... ':item.name}
                                        </Link>
                                         <ReactTooltip
                                            style={{display: item.name && item.name.length > 30 ? 'block':'none'}}
                                            key={item.id}
                                            id="soclose"
                                            getContent={ dataTip => (
                                            <React.Fragment key={item.id}>
                                                <p>{dataTip}</p>
                                                    
                                            </React.Fragment>
                                            )}
                                            effect="solid"
                                            // delayHide={500}
                                            // delayShow={100}
                                            // delayUpdate={500}
                                            place="right"
                                            border={true}
                                            type="light"
                                        />
                                        </td>
                                    <td>{item.email}</td>
                                    {/* <td className="pad-tb">
                                           <span className="address" style={{display: 'flex'}}><i className="fa fa-map-marker" aria-hidden="true" style={{color:'#179bee',padding:'12px'}}></i> &nbsp;{item.address.line}, <br/> &nbsp;{item.address.city}, {item.address.state}, {item.address.postal_code}</span>
                                        </td> */}
                                    <td>{item.phone.map(phone => {return (<React.Fragment>{phone.number} - {phone.type}<br/></React.Fragment>)})}</td>
                                    <td>
                                        
                                            <select 
                                                style={{padding:'10px'}}
                                                //  value={item.status}
                                                defaultValue={item.status} 
                                                onChange={(e)=>statusChangeHandler(e,item,i)}
                                                disabled = {item.status==='DO_NOT_CONTACT' ? true : false}
                                            >
                                                {/* <option value="">Select Status</option> */}
                                                <option value="DO_NOT_CONTACT">Do not contact</option>
                                                <option value="ACTIVE">Active</option>
                                                <option value="INACTIVE">Inactive</option>
                                                {/* <option value="LEAD">Lead</option> */}
                                            </select>
                                    </td>
                                </tr>
                                })}
                                
                                
                            </tbody>
                            </table>
                    </div>:companyUsersContactList.length === 0 ? 
                    <div className="add-agent bg-white sub-section" style={{textAlign:'center'}}>
                        <h1>No Contacts Available</h1>
                    </div>
                    :null}
                    {companyUsersContactList ? 
                    <div className="all-agent properties-table bg-white">
                        
                        <table className="table" id="agentcontacts" style={{display:'none'}}>
                            <thead>
                                <tr>
                                    <th scope="col"><strong  className="black">Name</strong></th>
                                    <th scope="col"><strong  className="black">Email</strong></th>
                                    {/* <th scope="col"><strong  className="black">Property Address</strong></th> */}
                                    <th scope="col"><strong  className="black">Phone Number</strong></th>
                                    <th scope="col"><strong  className="black">Status</strong></th>
                                    {/* <th scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {companyUsersContactList && companyUsersContactList.map((item,i) => {
                                    return <tr key={item.id}>
                                    <td >{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.phone.map(phone => {return (<React.Fragment>{phone.number} - {phone.type}<br/></React.Fragment>)})}</td>
                                    <td>{item.status === "DO_NOT_CONTACT" ? "DO NOT CONTACT":item.status}</td>
                                </tr>
                                })}
                                
                                
                            </tbody>
                            </table>
                    </div>:null}

                    
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 
           
                    </React.Fragment>
    );
} 




const mapStateToProps = state => {
    return {
      campaginAlert: state.CompanyUser.alert,
    }
}

export default connect(mapStateToProps,{get_all_contacts_list,get_contact_data_by_id,closeAlert,update_contact_status})(ContactList);
