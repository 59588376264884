import React,{useEffect}  from 'react';
import { withRouter,useLocation,Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { unsubscribe_email } from '../../redux/action/companyUser/companyUserActionCreator';
import { useDispatch } from 'react-redux';

const Unsubscribe = (props) =>{
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        const verifiaction_id = location.pathname;
        dispatch(unsubscribe_email(verifiaction_id.substring(13)));
    },[location,dispatch]);
    return(<React.Fragment>
        <header className="tl-header header-style-1">
            <div className="tl-menu menu-absolute menu-sticky">
                <nav className="navbar navbar-expand-lg p-0" >
                    <div className="container">
                        <Link className="navbar-brand" to={'/'} style={{display: 'inline-block'}}>
                            <img src="assets/images/logo.png" alt="Logo" className="img-fluid" style={{width:'25%'}} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fa fa-align-justify"></i></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                            {/* <ul className="navbar-nav">
                                <li><Link to={'/'} className="active">Login</Link></li>
                            </ul>        */}
                        </div> 
                    
                    </div>
                </nav>                                
            </div>            
            </header>
            
            <div className="lt-breadcrumb">
            <div className="breadcrumb-content">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div className="title">
                            <h1>Unsubscribe</h1>
                        </div>                 
                    </div>
                </div>
            </div>
        </div>

        <div className="lt-page-content bg-color">
            <div className="lt-section">
                <div className="section-content lt-account section-padding" style={{minHeight:'60vh'}}>
                    <div className="account-content bg-white" style={{maxWidth:'1000px'}}>
                        <form action="login.html" className="lt-form">
                            {props.unsubscribe.resultType === "" ? <h4 style={{color:'black',textAlign:'center'}}>Loading...............</h4>:null}
                            {props.unsubscribe.resultType === "success" ? <h4 style={{color:'green',textAlign:'center'}}>You have been unsubscribed successfully.</h4>:null}
                            {props.unsubscribe.resultType === "danger" ? <h4 style={{color:'red',textAlign:'center'}}>Sorry, something went wrong please try again.</h4>:null}
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="tl-footer">
        <div className="footer-content bg-image" style={{backgroundImage: 'url(assets/images/bg/footer.jpg)'}}>
            <div className="container">
                <div className="footer-bottom clearfix" style={{textAlign:'center'}}>
                    {/* <div className="d-flex justify-content-between"> */}
                    <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                        {/* <div className="left-content">
                            <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                        </div>
                        <div className="right-content">
                            <ul className="global-list d-flex">
                                <li><Link to="#">Privacy</Link></li>
                                <li><Link to="#">Terms & Condition</Link></li>
                                <li><Link to="#">Contact</Link></li>
                            </ul>
                        </div>             */}
                    {/* </div>              */}
                </div>                      
            </div>              
        </div>
    </div>     
    
        </React.Fragment>);
}

const mapStateToProps = state => {
    return {
        unsubscribe: state.CompanyUser.alert,
    }
};


export default connect(mapStateToProps,{unsubscribe_email})(withRouter(Unsubscribe));