import React from 'react';
import {Link,withRouter,useHistory} from 'react-router-dom';
import { connect } from 'react-redux';
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import moment from 'moment';
import DOMPurify from 'dompurify';
// import { getSession } from '../../../redux/Auth/sessionData';
import { Button } from 'react-bootstrap';

const style = {
    textAlign: 'center',
    background: 'white',
    padding: '0px 0',
    fontSize: '30px'
  };

  const properties = {
    duration: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    indicators: true,
  };


const PropertyDetails = (props)=>{
    // const session_data=getSession();
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
    //console.log(props.propertData);
    return(<React.Fragment>
        <div className="lt-page-content bg-color">
        <div className="lt-section">
            <div className="section-content property-details section-padding" style={{padding:'20px 0 70px'}}>
                <div className="container">
                   <div className="row">
                        <div className="col-lg-12" style={{textAlign:'end'}}>
                            <div className="form-group">
                                <Button className="btn btn-primary btn-sts"  onClick={goToPreviousPath}><i className="fa fa-backward" aria-hidden="true"></i> Back</Button>
                                {/* <Link to={session_data.userType === "COMPANY_USER" ? '/ViewCampaign':'/Contact'} className="btn btn-primary btn-sts"><i className="fa fa-backward" aria-hidden="true"></i> GO Back</Link> */}
                                {/* <Link to={session_data.userType === "COMPANY_USER" ? '/ViewCampaign':'/Contact'} className="btn btn-primary btn-sts"><i className="fa fa-backward" aria-hidden="true"></i> GO Back</Link> */}
                           </div>
                        </div>
                       
                   </div>
                    <div className="property">
                    {props.propertData && props.propertData.photos.length > 0  ? 
                        <Slide {...properties}>
                            {props.propertData && props.propertData.photos.map(item=> {
                                return      <div style={style}>
                                                <img src={item} alt="Img"  style={{height:'400px'}}/>
                                            </div>  
                            })}
                        </Slide>:null}
                        <div className="bg-white sub-section property-info">
                            <div className="d-flex justify-content-between">
                                <div className="left-content">
                                    <h2>{props.propertData.propertyName || ''}</h2>
                                    <div className="d-flex map-location">
                                        <div className="left-content">
                                            <span className="map-icon"><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                                            <span>{props.propertData.address.line || ''}, {props.propertData.address.city || ''}, {props.propertData.address.state || ''},  {props.propertData.address.postal_code || ''}</span>
                                            {props.propertData.list_price ? (<React.Fragment>
                                                <div className="d-flex justify-content-between"> 
                                                    <span   style={{color: '#179bee',display:'flex'}}><p style={{color:'grey'}}>Property Listing Price: </p>&nbsp;   ${String(props.propertData.list_price).replace(/(.)(?=(\d{3})+$)/g,'$1,') || ''}</span>
                                                </div>
                                            </React.Fragment>):null} 
                            
                                        </div>
                                    </div>                                        
                                </div>
                                <div className="lt-price align-self-end">
                                    <span>Property Status:</span>
                                    <h3>
                                        {!!(props.propertData.is_sold_out) ? "Sold":
                                        // !!(props.propertData.is_for_rent !== null && props.propertData.is_for_rent) ? "Rental": 
                                        (props.propertData.is_new_listing !== null && props.propertData.is_new_listing) ? "New Listing" : "Pending"}
                                        
                                    </h3>
                                </div>
                            </div>
                            <br/> 
                            
                            <div className="info-top">
                                <div className="d-flex justify-content-between">
                                    <div className="meta">
                                        <ul className="global-list">
                                        {props.propertData.description.beds ? <li><span><img src="assets/images/others/icon7.png" alt="Img" className="img-fluid" /></span>{props.propertData.description.beds || ''} Bedrooms</li>:null}
                                        {props.propertData.description.baths ?    <li><span><img src="assets/images/others/icon8.png" alt="Img" className="img-fluid" /></span>{props.propertData.description.baths || ''} Bathrooms</li>:null}
                                        {props.propertData.description.lot_sqft ?    <li><span><img src="assets/images/others/icon9.png" alt="Img" className="img-fluid" /></span>{props.propertData.description.lot_sqft || ''} Sf.</li>:null}
                                        {props.propertData.description.type ?    <li><span><img src="assets/images/others/icon10.png" alt="Img" className="img-fluid" /></span>Type: {props.propertData.description.type || ''} </li>:null}
                                        {props.propertData.description.year_built ?    <li><span><img src="assets/images/others/icon10.png" alt="Img" className="img-fluid" /></span>Year Build: {props.propertData.description.year_built || ''} </li>:null}
                                            {/* <li><span><img src="assets/images/others/icon9.png" alt="Img" className="img-fluid" /></span>Price: {props.propertData.description.sold_price || ''} </li> */}
                                        </ul>
                                    </div>
                                    <div className="print-heart align-self-center">
                                        <div className="print">
                                            <Link to="#">Listing ID:<span>{props.propertData.listing_id || ''}</span></Link>
                                        </div>
                                         
                                    </div>                                      
                                </div>
                               
                            </div>
                        </div>
                        
                        
                        <div className="row">
                            <div className="col-md-4 col-lg-4 lt-sticky">
                                <div className="theiaStickySidebar">
                                    <div className="bg-white sub-section property-description">
                                        <div className="title">
                                            <h2>Listing Agent</h2><br/>
                                            {props.propertData.agentDetails !== null ?
                                            <lable> {props.propertData.agentDetails.unsubscribe ? "Unsubscribe":null}</lable>:null}
                                        </div>
                                        <div className="features-list">
                                            <div className="">
                                                {/* {props.propertData.agentDetailsDTOList.map(item => {
                                                    return (
                                                        <React.Fragment> */}
                                                            <h2>{props.propertData.agentDetails !== null ? props.propertData.agentDetails.name:''}</h2>
                                                            {/* <p>{props.companyName}</p> */}
                                                            {/* <span>Phone: +44 555565478</span><br/> */}
                                                            <span>Email: <br/>{props.propertData.agentDetails !== null ? props.propertData.agentDetails.email:''}</span><br/>
                                                        {/* </React.Fragment>
                                                    )
                                                })} */}
                                               {props.propertData.agentDetails !== null ? props.propertData.agentDetails.agentPhones.map(agentnumber => {
                                               return (<React.Fragment>
                                                <span>Number: <span className="black" >{agentnumber.number}</span></span><br/>
                                                <span>Type: <span className="black" >{agentnumber.type}</span></span><br/>
                                                </React.Fragment>)
                                              }):'' }
                                                       
                                            </div>
                                        </div>
                                    </div>

                                   </div>
                            </div>
                            <div className="col-md-8 col-lg-8 lt-sticky">
                                <div className="lt-sidebar theiaStickySidebar">
                                    <div className="widget-area">
                                    <div className="bg-white sub-section">
                                        <div className="ds-header d-flex justify-content-between">
                                            <div className="title">
                                                <h1><i className="fa fa-comments" aria-hidden="true"></i>Message To Lisiting Agent</h1>
                                            </div>
                                            <div className="more-icon align-self-center">
                                                <Link to="#"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></Link>
                                            </div>
                                        </div>
                                        
                                        <div className="chat-conversation">
                                            <div className="live-chat">
                                                <div className="live-chat-body">
                                                    {/* <p>You're Chatting With Jassia</p> */}
                                                    {props.propertData.messages && props.propertData.messages.map(item =>{
                                                        return (item.isResponseMessage ? 
                                                                    <div className="single-message self-message text-right chat-customercare">
                                                                        <div className="user-massage ">
                                                                            <span className="user-name">Subject: <strong>{item.messageSubject}</strong></span>
                                                                            <p><span className="color" >Message: <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message) }} /></span></p>
                                                                            <span className="m-time">{moment(item.creationTimestamp).format('DD-MM-YYYY HH:MM')}</span>
                                                                        </div>
                                                                    </div>
                                                                    :<div className="single-message chat-customer">
                                                                    {/* <div className="profile-picture">
                                                                        <img src="assets/images/db/messages/9.png" alt="Profile"/>    
                                                                    </div> */}
                                                                    <div className="user-massage ">
                                                                        <span className="user-name">Subject: <strong>{item.messageSubject}</strong></span>
                                                                        <p><span className="color" >Message: <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message) }} /></span></p>
                                                                        <span className="m-time">{moment(item.creationTimestamp).format('DD-MM-YYYY HH:MM')}</span>
                                                                    </div>
                                                                </div>)
                                                    })}
                                                    

                                                    {/* <div className="single-message self-message text-right chat-customercare">
                                                        <div className="user-massage">
                                                            <span className="user-name">Sowat Ahsan</span>
                                                            <p><span className="color">It’s Great opportunity to work.</span></p>
                                                            <span className="m-time">8:00 am</span>
                                                        </div>
                                                    </div>
                                                    <div className="single-message chat-customer">
                                                        <div className="profile-picture">
                                                            <img src="assets/images/db/messages/9.png" alt="Profile"/>    
                                                        </div>
                                                        <div className="user-massage">
                                                            <span className="user-name">Jassia Deo</span>
                                                            <p><span className="color">It’s Great opportunity to work.</span></p>
                                                            <span className="m-time">8:00 am</span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div className="live-chat-footer">
                                                    <div className="send-message">
                                                        <form action="#" className="lt-form">
                                                            <div className="form-group mb-0">
                                                                <input type="text" className="form-control" placeholder="Type Your Message"/>
                                                                <input type="submit" value="Send"/>
                                                            </div>
                                                        </form>
                                                    </div>*/}
                                                </div> 
                                            </div>                                                
                                        </div>
                                    </div>
                                </div>
                            
                                    </div>                     
                                
                            </div>
                        </div>
                    </div>                       
                </div>
            </div>
        </div>       
    </div>     
    </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
      companyalert: state.CompanyUser.alert,
      propertData: state.CompanyUser.propertyDetails,
    }
}


export default connect(mapStateToProps,null)(withRouter(PropertyDetails));
