import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {territoryListing,storeTerritoryId} from '../../../redux/action/productAdmin/productActionCreator';
import Loader from '../../../Components/common/Loader';

const TerritoriesListing = (props) =>{
    const [territoryList, setTerritoriesList] = useState([]);
    const {companyTerritories}= territoryList || {};

    useEffect(()=>{
        props.territoryListing().then(result => {
            setTerritoriesList(result);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const editTerritoryHandler = (e,territoryId) => {
        props.storeTerritoryId(territoryId,props.history);
    }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <div className="main-header">
                        <div className="main-title">
                            <h1 className="db-title">List of Territories</h1>
                        </div>
                        <div className="short d-flex align-self-center">
                           <Link to={'/AddTerritory'} className="btn btn-primary">Add Territory</Link>
                        </div>
                    </div>
                    {companyTerritories ?
                    <div className="all-agent properties-table bg-white">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Territory Name</th>
                                    <th scope="col">Territory Location</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyTerritories && companyTerritories.map(item=>{
                                    
                                    return (
                                        <tr key={item.id}>
                                            <td className="agent-name">
                                                <h2>{item.territoryName}</h2>
                                            </td>
                                            
                                            <td className="agent-status">
                                            {item.cityZipCodeDTOS.slice(0, 2).map((cityandZipcode,i) => {
                                                return ( 
                                                        <Link to="#" key={i}><i className="fa fa-map-marker " aria-hidden="true"></i> {cityandZipcode.cityName},{cityandZipcode.zipCode}</Link>
                                                        )})}
                                                {item.cityZipCodeDTOS.length > 2 ? <Link to="#" onClick={(e) => editTerritoryHandler(e,item.id)} style={{color: '#179bee'}}><i className="fa fa-link" aria-hidden="true"></i>More...</Link>:null}
                                             </td>
                                            <td >
                                                <p>{item.isActive ? 'Active':'Inactive'}</p>
                                            </td>
                                            <td className="property-option">
                                                <div className="db-action">
                                                    <Link to="#" className="edit btn" onClick={(e) => editTerritoryHandler(e,item.id)}><span><i className="fa fa-pencil-square-o" aria-hidden="true"></i></span></Link>
                                                 </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                    </div>:<Loader />}
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                
            </div>
        </div>
            </div> 
            </div> 

    );
} 

// export default CompanyListing;
export default connect(null,{territoryListing,storeTerritoryId})(TerritoriesListing);