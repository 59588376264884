import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom';

const MyVerticallyCenteredModal = (props)=> {
	// pathname
   	const path  = window.location.pathname;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen='sm-down'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {path !== "/AllCampaign" ? 'Delete SubTerritory':'Delete Campaign'}
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{display: 'inline-flex'}}>
          Are you sure you want to delete  &nbsp;<strong> {props.subterritoryName} </strong>&nbsp;{path !== "/AllCampaign" ? 'territory':'campaign'} ?
          
          </p> 
        </Modal.Body>
        <Modal.Footer>
          <Link onClick={props.onHide} className="btn btn-primary" style={{height: '30px',padding:'4px 40px',borderColor: 'grey',backgroundColor: 'grey',color: 'white'}}>Cancel</Link>
          <Link onClick={props.deleteSubterritory} className="btn btn-primary" style={{height: '30px',padding:'4px 40px'}}>{props.deleteText && props.deleteText ? "Deleting.....":"Delete"}</Link>
        </Modal.Footer>
      </Modal>
    );
  }
  

export default MyVerticallyCenteredModal;