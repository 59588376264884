import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {  create_template,get_all_compnay_user_triggers,get_compnay_user_template_by_id,updateEmailDetails,closeAlert} from '../../../redux/action/companyUser/companyUserActionCreator';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Alert from 'react-bootstrap/Alert';


const MessageTemplate = (props) => {
  const [triggersList, setTriggersList] = useState();
  const [showEmaildata, setShowEmailData] = useState(false);
  const [selectedTriggerList, setSelectedTriggerList] = useState([]); 
  // const TextData = props.emailDetails.emailTemplate +  "<br/>If you would prefer not receiving our emails, please click <a href='#'>here</a>"; 
   const [error,setError]=useState({
        triggerErr:'',
        subjectErr:'',
        templateErr:'',
    });
   
  useEffect(() => {
      props.get_all_compnay_user_triggers().then(result => {
        setTriggersList(result);
      })
      props.closeAlert(false)
   // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  
  const triggerOptions = triggersList && triggersList.map(item => {
    return <option id={item.id} key={item.id} data-key={item.id}>{item.triggerDisplayName}</option>
  });

  //set trigger
  const setTriggerHandler =(e)=>{
    //console.log(e.target.value)
    setError(prevState => ({
      ...prevState,
      subjectErr: '',
      templateErr:'',
      triggerErr:''
  }));
    if(e.target.value !== "" && e.target.value !== "0"){
    const selectedIndex = e.target.options.selectedIndex;
    let triggerId=e.target.options[selectedIndex].getAttribute('data-key');
    //console.log(triggerId);
    props.get_compnay_user_template_by_id(triggerId);
    setShowEmailData(true);
    const triggerDetails = triggersList.filter(item => item.triggerDisplayName === e.target.value);
    //props.updateEmailDetails("triggerDTO",triggerDetails);
    setSelectedTriggerList(triggerDetails);
  }
  if(e.target.value === "0"){
    setShowEmailData(false);
  }
  }
  

  //add text handler
  const addTextHandler = (e,textType) =>{
    e.preventDefault();
    // if(textType === "propertyName"){
    //   if(props.emailDetails.emailTemplate === null){
    //     props.updateEmailDetails("emailTemplate","{PROPERTY_NAME}")
    //   }else{
    //   props.updateEmailDetails("emailTemplate",props.emailDetails.emailTemplate+"{PROPERTY_NAME}")

    //   }
    // }
    if(textType === "propertyAddress"){
      if(props.emailDetails.emailTemplate === null){
      props.updateEmailDetails("emailTemplate","{PROPERTY_ADDRESS}")
    }else{
      
      props.updateEmailDetails("emailTemplate",props.emailDetails.emailTemplate+"{PROPERTY_ADDRESS}")
    }
    }
    if(textType === "agentName"){
      if(props.emailDetails.emailTemplate === null){
        props.updateEmailDetails("emailTemplate","{AGENT_NAME}")
      }else{
      props.updateEmailDetails("emailTemplate",props.emailDetails.emailTemplate+"{AGENT_NAME}")

      }
    }
    // props.updateEmailDetails("emailTemplate",data)
    
  }


  const validate = (e) => {
    let triggerVal = triggerValidation(selectedTriggerList);
    let subjectVal = subjectValidation(props.emailDetails.emailSubject);
    let templateVal = templateValidation(props.emailDetails.emailTemplate);
    // if(selectedTriggerList.length > 0){
    if( triggerVal === true && subjectVal === true  && templateVal === true ){
      return true;
    }
    // }else{
    //   if( triggerVal === true ){
    //     return true;
    //   }
    // }
  }

  function triggerValidation(selectedTriggerList) { 
    if(selectedTriggerList.length === 0){
      setError(prevState => ({
          ...prevState,
          triggerErr: 'Please Select Trigger'
      }));
      return false;
  }
  if(selectedTriggerList.length >0){
      setError(prevState => ({
          ...prevState,
          triggerErr: ''
      }));
      return true;
  }

  }
  function subjectValidation(subjectVal) { 
      if(subjectVal === "" ) {
        setError(prevState => ({...prevState,subjectErr: 'Email Subject should not be empty'}));
        return false;
      }
      if (subjectVal !== "" ){
        setError(prevState => ({
            ...prevState,
            subjectErr: ''
        }));
        return true;
      }
  }
  function templateValidation(templateVal) {
    if(templateVal === "" ) {
      setError(prevState => ({...prevState,templateErr: 'Email Template should not be empty'}));
      return false;
    }
    if (templateVal !== "" ){
      setError(prevState => ({
          ...prevState,
          templateErr: ''
      }));
      return true;
    }
  }

  //create message template
  const messageTemplateHandler = async (e) => {
    e.preventDefault();
    //console.log(props.emailDetails.triggerDTO[0])
    if(validate()){
   const payload_data = {
    //   triggers : selectedTriggersList,
    emailSubject: props.emailDetails.emailSubject,
    emailTemplate: props.emailDetails.emailTemplate,
    id: props.emailDetails.id || null,
    triggerDTO: selectedTriggerList[0]
    };
   // console.log(payload_data);
   props.create_template(payload_data, props.history);
  }else{
    window.scrollTo({top:50,behavior: "smooth"});
  }
  }

  if(props.companyalert.show === true || props.companyalert.show === false){
    window.scrollTo({top: 50,behavior: "smooth"});
  }
  return (
    <div className="tl-section">
      <div className="tl-dashboard bg-color">
        <div className="container-fluid p-0">
          <div className="main">
            <h1 className="db-title">Message Template</h1>
            <Alert id={props.companyalert.show === true ? 'alert':null} className={props.companyalert.show === true ? 'alert':null} variant={props.companyalert.resultType} show={props.companyalert.show} 
                     onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.companyalert.msg}</p>
            </Alert>
            <div className="add-agent bg-white sub-section">
              <form action="#" className="lt-form">
                <div className="row">
                  <div className="col-md-4">

                    <div className="form-group">
                      <label htmlFor="trrigers">Select Trigger </label>
                      <select
                        className="form-control"
                        onChange={setTriggerHandler}
                      >
                        <option value="0">Select Trigger Status</option>
                        {triggerOptions}
                      </select>
                      {error.triggerErr ? <span className="error-span">{error.triggerErr}</span>:null}
                    </div>

                  </div>
                  {showEmaildata ? 
                  <React.Fragment>
                   <div className="col-md-8">

                    <div className="form-group">
                      
                  <label htmlFor="Subject">Email Subject </label>
                        <input
                            className="form-control"
                            placeholder="Enter Subject"
                            value={props.emailDetails.emailSubject || ''}
                            onChange={(e)=>props.updateEmailDetails("emailSubject",e.target.value)}
                        />
                      {error.subjectErr ? <span className="error-span">{error.subjectErr}</span>:null}
                    </div>
                  </div>
                  </React.Fragment>
                  :null}
                </div>
                {showEmaildata ? 
                
                <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="template">Email Template:  </label>
                          <div style={{textAlign:'right',margin: '-34px 0 10px 0'}}>
                            {/* <button className="btn btn-primary btn-sts"  onClick={(e)=>addTextHandler(e,"propertyName")}>Add Property Name</button> &nbsp; */}
                            <button className="btn btn-primary btn-sts"  onClick={(e)=>addTextHandler(e,"propertyAddress")}>Add Property Address</button> &nbsp;
                            <button className="btn btn-primary btn-sts"   onClick={(e)=>addTextHandler(e,"agentName")}>Add Agent Name</button> 
                            </div>
                        <div className="editor">
                            <CKEditor
                              id="idofeditor"
                              name="idofeditor"
                              editor={ClassicEditor}
                              data={props.emailDetails.emailTemplate || ''}
                              onChange={(event, editor) => {
                                  const data = editor.getData();
                                  props.updateEmailDetails("emailTemplate",data)
                              }}
                            />
                        </div>
                        {error.templateErr ? <span className="error-span">{error.templateErr}</span>:null}
                    </div>
                </div>
                </React.Fragment>
                :null}
                <br/>
                <button className="btn btn-primary" onClick={messageTemplateHandler}>Update Message Template</button>
              </form>
            </div>
            <div className="copy-right text-center">
              <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

const mapStateToProps = state => {
  return {
    companyalert: state.CompanyUser.alert,
    emailDetails: state.CompanyUser.emailTemplateDetails,
  }
}

export default connect(mapStateToProps, { get_all_compnay_user_triggers,create_template,get_compnay_user_template_by_id,closeAlert,updateEmailDetails })(withRouter(MessageTemplate));
