import React, { useEffect, useState } from 'react';
import { Link,withRouter } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { connect } from 'react-redux';
import {getall_cities_zipcodes,create_territory,updateCompanyTerritoryData,updateTerritoriesList,closeAlert} from '../../../redux/action/productAdmin/productActionCreator';
import {MultiSelect} from "../../../Components/common/Multiselect/multiSelect";

const EditTerritory = (props) =>{
    // const [territoryName,setTerritoryName] =useState('');
    const [citieszipcodeList,setCitieszipcodeList] =useState([]);
    const [zipcodeList,setZipcodeList] =useState([]);
    const [citiesList,setCitiesList] =useState([]);
     const [selectedZipcodeData,setSelectedZipcodeData] = useState([]);
    const [updateCitieszipcodeList,setUpdateCitieszipcodeList] =useState([]);
    // const [isActive,setisActive] =useState(true);
    const [error,setError]=useState({
        territoryNameErr:'',
        citiesListErr:'',
        zipcodeListErr:'',
    });

    useEffect(()=>{
        props.territoryDetails.cityZipCodes && props.getall_cities_zipcodes(props.territoryDetails.cityZipCodes[0].stateCode).then(result => {
            setCitieszipcodeList(result);
            let zipcodeFilter=[];
            let zip=[];
            props.territoryDetails.cityZipCodes && props.territoryDetails.cityZipCodes.forEach(item => {
               zip= result.cities && result.cities.filter(zip => zip.cityName === item.cityName);
               zipcodeFilter=[...zipcodeFilter,...zip]
            });
          setSelectedZipcodeData(zipcodeFilter);
        });
        props.closeAlert(false);
        setCitiesList(props.territoryDetails.cityZipCodes && props.territoryDetails.cityZipCodes.map(item => {
            const val= { label:  item.cityName, value:  item.cityName, zipcode: item.zipcodes,cityName: item.cityName  };
                return val;
            }));
            let zipcodes= [];
            let city= '';
            // let zipcodeFilter=[];
            props.territoryDetails.cityZipCodes && props.territoryDetails.cityZipCodes.forEach(item => {
                city= item.cityName;
                // zipcodeFilter= citieszipcodeList && citieszipcodeList.cities.filter(item => item.cityName === city)
                const zipcodesWithCityName = item.zipcodes.map(zip => {
                const val= {cityName: city, zipCode: zip,label: zip,value:zip};
                return val;
                // return zip;
                    })
            zipcodes= [...zipcodes,...zipcodesWithCityName];
            });
            setUpdateCitieszipcodeList(zipcodes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[setSelectedZipcodeData]);
    useEffect(()=>{
        let zipcodes= [];
        let city= '';
        selectedZipcodeData && selectedZipcodeData.forEach(item => {
            city= item.cityName;
            const zipcodesWithCityName = item.zipcodes.map(zip => {
            const val= {cityName: city, zipCode: zip,label: zip,value:zip};
            return val;
                })
        zipcodes= [...zipcodes,...zipcodesWithCityName];
        });
            setZipcodeList(zipcodes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedZipcodeData]);

    useEffect(()=>{
        if(updateCitieszipcodeList.length > 0 && zipcodeList.length > 0){
            const zipList = updateCitieszipcodeList.filter(item => zipcodeList.some(list => list.value === item.value));
            setUpdateCitieszipcodeList(zipList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[zipcodeList]);

    const  {cities}  = citieszipcodeList ||{};
    debugger;
    const  options  = cities && cities.map(item => {
        const val= { label:  item.cityName, value:  item.cityName, zipcode: item.zipcodes ,cityName: item.cityName  };
        return val;
    });

    const setCityZipcode =(e)=>{
        setCitiesList(e);
        let zipcodes= [];
        let city= '';
        const selected_options = options.filter(item => e.find(i => i.cityName === item.label));
        selected_options && selected_options.forEach(item => {
            city= item.label;
            let zipcodesWithCityName = item.zipcode.map(zip => {
              let val= {cityName: city, zipCode: zip,label: zip,value:zip};
              return val;
               // return zip;
                })
            zipcodes= [...zipcodes,...zipcodesWithCityName];
        });
        setZipcodeList(zipcodes);
        if(e.length === 0){
            setUpdateCitieszipcodeList([]);
            setZipcodeList([]);
        }
        //  setUpdateCitieszipcodeList(zipcodes);
    }
   
    const setZipcodeData = (e) =>{
    //    setCitiesList([...new Set(e.map(item => {
    //     const val= { label:  item.cityName, value:  item.cityName, zipcodes: item.zipcodes,cityName: item.cityName  };
    //         return val;
    //     }))])
       setUpdateCitieszipcodeList(e);
        //  if(e.length === 0){
        //      setCitiesList([]);
        //  }
    }

    const toggleActiveHandler = (e) =>{
        props.updateCompanyTerritoryData("isActive",!props.territoryDetails.isActive);
    }
    
    const validate = (e) => {
        let territoryNameVal = territoryNameValidation(props.territoryDetails.territoryName);
        let citiesListVal = citiesListValidation(citiesList);
        let zipcodeListVal = zipcodeListValidation(updateCitieszipcodeList);
        if(territoryNameVal === true && citiesListVal === true && zipcodeListVal === true){
          return true;
        }
      }
    
      function territoryNameValidation(territoryNameVal) { 
          
        if(!territoryNameVal) {
            setError(prevState => ({
                ...prevState,
                territoryNameErr: 'Please Enter Territory Name'
            }));
          return false;
        }
        // if (!/^[a-zA-Z ]+$/.test(territoryNameVal)){
        //     setError(prevState => ({
        //         ...prevState,
        //         territoryNameErr: 'Please Enter Only Characters'
        //     }));
        //   return false;
        // }
        if(territoryNameVal) {
            setError(prevState => ({
                ...prevState,
                territoryNameErr: ''
            }));
          return true;
        }
      }
      function citiesListValidation(citiesListVal) {  
          //console.log(citiesList.length);  
        if(citiesListVal.length === 0){
            setError(prevState => ({
                ...prevState,
                citiesListErr: 'Please Select City'
            }));
            return false;
        }
        if(citiesListVal.length > 0){
            setError(prevState => ({
                ...prevState,
                citiesListErr: ''
            }));
            return true;
        }
      }
      function zipcodeListValidation(zipcodeListVal) {
        if(zipcodeListVal.length === 0){
            setError(prevState => ({
                ...prevState,
                zipcodeListErr: 'Please Select Zipcode'
            }));
            return false;
        }
        if(zipcodeListVal.length >0){
            setError(prevState => ({
                ...prevState,
                zipcodeListErr: ''
            }));
            return true;
        }
        
      }

    const addTerritoryHandler = async (e) =>{
        e.preventDefault();
        const payload_data= {
                id: props.territoryDetails.id,
                // cityZipCodes: props.territoryDetails.cityZipCodes,
                cityZipCodes: updateCitieszipcodeList,
                isActive: props.territoryDetails.isActive,
                territoryName: props.territoryDetails.territoryName,
        };
       //console.log(payload_data);
         if(validate()){
            props.create_territory(payload_data,props.history);
           }
    }

    return(
        <div className="tl-section">
        <div className="tl-dashboard bg-color">
            <div className="container-fluid p-0">
                <div className="main">
                    <h1 className="db-title">Edit Territory</h1>
                    <div className="add-agent bg-white sub-section">
                        <form action="#" className="lt-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="territory">Territory Name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Enter Territory Name"
                                        value={props.territoryDetails.territoryName || ''}
                                        onChange={(e) => props.updateCompanyTerritoryData("territoryName",e.target.value)}
                                        />
                                        {error.territoryNameErr ? <span className="error-span">{error.territoryNameErr}</span>:null}
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                    <label htmlFor="city">Select State</label>
                                    <input 
                                        type="text" 
                                        className="form-control inpt-disabled" 
                                        value={props.territoryDetails.cityZipCodes[0].stateName || ''}
                                        disabled
                                    />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                <label htmlFor="cityname">Select City</label>
                                <MultiSelect options={ options || []} value={ citiesList || []} placeholder="Select City" onChange={setCityZipcode} />
                                {error.citiesListErr ? <span className="error-span">{error.citiesListErr}</span>:null}
                                    </div>
                                </div>
                            <div className="col-md-6">
                                    <div className="form-group">
                                    <label htmlFor="zipcode">Select Zipcodes</label>
                                <MultiSelect options={zipcodeList || []} value={updateCitieszipcodeList || []} placeholder="Select City" onChange={setZipcodeData} />
                                {error.zipcodeListErr ? <span className="error-span">{error.zipcodeListErr}</span>:null}
                                         
                                    </div>
                                </div>
                            
                                </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <BootstrapSwitchButton
                                            checked={props.territoryDetails.isActive}
                                            onlabel='Active'
                                            offlabel='Inactive'
                                            onChange={toggleActiveHandler}
                                            width={100} 
                                        />
                                    </div>
                                </div>
                            </div>    
                        <button className="btn btn-primary" onClick={addTerritoryHandler}>Update Territory</button> 
                        </form>
                    </div>
                    <div className="copy-right text-center">
                        <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    );
}


const mapStateToProps = state => {
    return {
      alertData: state.Product.alert,
      territoryDetails: state.Product.companyTerritoryDetails,
    }
  }

export default connect(mapStateToProps,{getall_cities_zipcodes,create_territory,updateCompanyTerritoryData,updateTerritoriesList,closeAlert})(withRouter(EditTerritory));
//export default CreateTerritory;