import React,{useState,useEffect} from 'react';
import { Link,useLocation  } from 'react-router-dom';
import { getSession } from '../../../redux/Auth/sessionData';
import { connect } from 'react-redux';
import { unsetLogin } from '../../../redux/action/accountAction/logoutAction';

const LoginHeader = (props) =>{
    const [userType,setUserType] =useState('');
    const sessions_data = getSession(); 
    const location=useLocation();

    //console.log(location.substring(1));

    useEffect(()=>{
        
        if(sessions_data && sessions_data.userType==="PRODUCT_ADMIN"){
            setUserType('PRODUCT_ADMIN');
        }
        if(sessions_data &&  sessions_data.userType==="COMPANY_ADMIN"){
            setUserType('COMPANY_ADMIN');
        }
        if(sessions_data &&  sessions_data.userType==="COMPANY_USER"){
            setUserType('COMPANY_USER');
        }
        
    },[sessions_data]);


    //logout Handler
    const logoutHandler = () =>{
        props.unsetLogin();
    }

    return(
        <React.Fragment>
            { userType === 'PRODUCT_ADMIN' ?
            <header  className= "tl-header db-header" style={{display: location.pathname.substring(0, location.pathname.indexOf(':')) === "/Unsubscribe" ? 'none':null}}>
            <div className="side-menu-content">
                <span className="tl-toggler"><i className="fa fa-bars" aria-hidden="true"></i></span>
                <div className="side-menu" style={{overflow: 'auto'}}>
                    <ul className="global-list">
                        {/* <li><span className="heading nb">Main</span></li>
                        <li><Link to={'/Dashboard'}><span><i className="fa fa-tachometer fa-fw" aria-hidden="true"></i></span>Dashboard</Link></li> */}
                        {/* <li><span className="heading">Zipcode</span></li>
                        <li className={location.pathname === "/ZipcodeList" ? 'active':'inactive'}><Link to={'/ZipcodeList'}><span><i className="fa fa-map-pin fa-fw" aria-hidden="true"></i></span>All Zipcodes</Link></li>
                        <li className={location.pathname === "/AddZipcode" ? 'active':'inactive'}><Link to={'/AddZipcode'}><span><i className="fa fa-map-pin fa-fw" aria-hidden="true"></i></span>Add Zipcodes</Link></li> */}
                        <li><span className="heading">Territory</span></li>
                        <li className={location.pathname === "/TerritoryList" ? 'active':'inactive'}><Link to={'/TerritoryList'}><span><i className="fa fa-globe fa-fw" aria-hidden="true"></i></span>All Territories</Link></li>
                        <li className={location.pathname === "/AddTerritory" ? 'active':'inactive'}><Link to={'/AddTerritory'}><span><i className="fa fa-map-marker fa-fw" aria-hidden="true"></i></span>Add Territory</Link></li>
                        <li><span className="heading">Company</span></li>
                        <li className={location.pathname === "/CompanyList" ? 'active':'inactive'}><Link to={'/CompanyList'}><span><i className="fa fa-user-o fa-fw" aria-hidden="true"></i></span>All Companies</Link></li>
                        <li className={location.pathname === "/AddCompany" ? 'active':'inactive'}><Link to={'/AddCompany'}><span><i className="fa fa-user-plus fa-fw" aria-hidden="true"></i></span>Add Company</Link></li>
                       </ul>
                </div>             
            </div>          
            <div className="tl-menu menu-absolute menu-sticky">
                <nav className="navbar navbar-expand-lg p-0">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="#">
                            <img src="assets/images/logo2.png" alt="Logo" className="img-fluid logo-lg logoimg" style={{width: '25%'}}/>
                            <img src="assets/images/logo-sm.png" alt="Logo" className="img-fluid logo-sm" style={{width: '25%'}} />
                        </Link>                        
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fa fa-align-justify"></i></span>
                        </button>
                        <div className="right-content">
                            {/* <form className="tl-search-form align-self-center" role="search" action="#">
                                <input type="search" className="form-control" placeholder="Search here..." />
                                <button type="submit" id="search-submit" className="btn"><i className="fa fa-search"></i></button>
                            </form> */}

                            {/* <div className="tl-search">
                                <div className="search-icon">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className="search-form text-center open-search">
                                    <div className="close-search">
                                        <span><i className="fa fa-times-circle" aria-hidden="true"></i></span>
                                    </div>
                                    <form action="#" id="search" method="get">
                                        <input id="inputhead" name="search" type="text" placeholder="Enter keyword..." />
                                        <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </form>
                                </div>                    
                            </div> */}

                            <div className="tl-user-option align-self-center">
                                {/* <ul className="global-list d-flex align-self-center">
                                    <li><Link to="#"><i className="fa fa-th-large" aria-hidden="true"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-envelope-o" aria-hidden="true"></i> <span>3</span></Link></li>
                                    <li><Link to="#"><i className="fa fa-bell-o" aria-hidden="true"></i></Link></li>
                                </ul> */}
                                <ul className="global-list d-flex align-self-center">
                                   <li style={{color:'white',height:'10px'}}>{sessions_data.firstName} {sessions_data.lastName}</li>
                                </ul>
                                <div className="tl-user align-self-center">
                                    <div className="author dropdown" style={{height:'28px'}}>
                                        <Link to="#" className="dropdown-toggle" type="button" id="tl-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user fa-2x" aria-hidden="true" style={{color:'white'}} ></i></Link>

                                        <div className="dropdown-menu" aria-labelledby="tl-dropdown">
                                            <Link  className="dropdown-item" to={'/Profile'}> Profile</Link>
                                            {/* <Link  className="dropdown-item" to="#">Settings</Link> */}
                                            <div className="dropdown-divider"></div>
                                            <Link  className="dropdown-item text-danger" to="#" onClick={logoutHandler}> Logout</Link>
                                        </div>                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>                              
            </div>      
            
            </header>:null}
            { userType === 'COMPANY_ADMIN' ?
            <header  className= "tl-header db-header" style={{display: location.pathname.substring(0, location.pathname.indexOf(':')) === "/Unsubscribe" ? 'none':null}}>
            { location.pathname !== "/ViewPropertyDetails" ? 
            <div className="side-menu-content">
                <span className="tl-toggler"><i className="fa fa-bars" aria-hidden="true"></i></span>
                <div className="side-menu" style={{overflow: 'auto'}}>
                    <ul className="global-list">
                        <li><span className="heading nb">Main</span></li>
                        <li className={location.pathname === "/CompanyDashboard" ? 'active':'inactive'}><Link to={'/CompanyDashboard'} ><span><i className="fa fa-tachometer fa-fw" aria-hidden="true"></i></span>Dashboard</Link></li>
                        {/* <li><span className="heading">Campagin</span></li>
                        <li className={location.pathname === "/AllCampaign" ? 'active':'inactive'}><Link to={'/AllCampaign'}><span><i className="fa fa-user-o fa-fw" aria-hidden="true"></i></span>All Campaigns</Link></li>
                        <li className={location.pathname === "/AddCampaign" ? 'active':'inactive'}><Link to={'/AddCampaign'}><span><i className="fa fa-user-plus fa-fw" aria-hidden="true"></i></span>Add Campaign</Link></li> */}
                        <li><span className="heading">Company Users</span></li>
                        <li className={location.pathname === "/AllUsers" ? 'active':'inactive'}><Link to={'/AllUsers'}><span><i className="fa fa-user-o fa-fw" aria-hidden="true"></i></span>All Users</Link></li>
                        <li className={location.pathname === "/AddUser" ? 'active':'inactive'}><Link to={'/AddUser'}><span><i className="fa fa-user-plus fa-fw" aria-hidden="true"></i></span>Add User</Link></li>
                        <li><span className="heading">Mail Boxes</span></li>
                        <li className={location.pathname === "/AllMailBoxes" ? 'active':'inactive'}><Link to={'/AllMailBoxes'}><span><i className="fa fa-th fa-fw" aria-hidden="true"></i></span>All Mail Boxes</Link></li>
                        <li className={location.pathname === "/AddMailBox" ? 'active':'inactive'}><Link to={'/AddMailBox'}><span><i className="fa fa-envelope fa-fw" aria-hidden="true"></i></span>Add Mail Box</Link></li>
                        <li><span className="heading">Territory</span></li>
                        <li className={location.pathname === "/CompnayTerritoryList" ? 'active':'inactive'}><Link to={'/CompnayTerritoryList'}><span><i className="fa fa-globe fa-fw" aria-hidden="true"></i></span>Company Territories</Link></li>
                        <li className={location.pathname === "/AllSubTerritories" ? 'active':'inactive'}><Link to={'/AllSubTerritories'}><span><i className="fa fa-globe fa-fw" aria-hidden="true"></i></span>All SubTerritory</Link></li>
                        <li className={location.pathname === "/AddSubTerritory" ? 'active':'inactive'}><Link to={'/AddSubTerritory'}><span><i className="fa fa-globe fa-fw" aria-hidden="true"></i></span>Add SubTerritory</Link></li>
                        <li><span className="heading">Contact</span></li>
                        <li className={location.pathname === "/CompanyContact" ? 'active':'inactive'}><Link to={'/CompanyContact'}><span><i className="fa fa-globe fa-fw" aria-hidden="true"></i></span>All Contacts</Link></li>
                        
                    </ul>
                </div>             
            </div>:null}          
            <div className="tl-menu menu-absolute menu-sticky">
                <nav className="navbar navbar-expand-lg p-0">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="#">
                            <img src="assets/images/logo2.png" alt="Logo" className="img-fluid logo-lg logoimg" style={{width: '25%'}}/>
                            <img src="assets/images/logo-sm.png" alt="Logo" className="img-fluid logo-sm" style={{width: '25%'}} />
                        </Link>                        
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fa fa-align-justify"></i></span>
                        </button>
                        <div className="right-content">
                            {/* <form className="tl-search-form align-self-center" role="search" action="#">
                                <input type="search" className="form-control" placeholder="Search here..." />
                                <button type="submit" id="search-submit" className="btn"><i className="fa fa-search"></i></button>
                            </form> */}

                            {/* <div className="tl-search">
                                <div className="search-icon">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className="search-form text-center open-search">
                                    <div className="close-search">
                                        <span><i className="fa fa-times-circle" aria-hidden="true"></i></span>
                                    </div>
                                    <form action="#" id="search" method="get">
                                        <input id="inputhead" name="search" type="text" placeholder="Enter keyword..." />
                                        <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </form>
                                </div>                    
                            </div>*/} 

                            <div className="tl-user-option align-self-center">
                                <ul className="global-list d-flex align-self-center">
                                   <li style={{color:'white',height:'10px'}}>{sessions_data.firstName} {sessions_data.lastName}</li>
                                </ul>
                                <div className="tl-user align-self-center">
                                    <div className="author dropdown" style={{height:'28px'}}>
                                        <Link to="#" className="dropdown-toggle" type="button" id="tl-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user fa-2x" aria-hidden="true" style={{color:'white'}} ></i></Link>

                                        <div className="dropdown-menu" aria-labelledby="tl-dropdown">
                                            <Link  className="dropdown-item" to={'/CompanyProfile'}> Profile</Link>
                                            {/* <Link  className="dropdown-item" to="#">Settings</Link> */}
                                            <div className="dropdown-divider"></div>
                                            <Link  className="dropdown-item text-danger" to="#" onClick={logoutHandler}> Logout</Link>
                                        </div>                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>                              
            </div>      
            
            </header>:null}


            { userType === 'COMPANY_USER' ?
            <header  className= "tl-header db-header" style={{display: location.pathname.substring(0, location.pathname.indexOf(':')) === "/Unsubscribe" ? 'none':null}}>
            {location.pathname !== "/ViewPropertyDetails" ? 
            <div className="side-menu-content">
                <span className="tl-toggler"><i className="fa fa-bars" aria-hidden="true"></i></span>
                <div className="side-menu" style={{overflow: 'auto'}}>
                    <ul className="global-list">
                        <li><span className="heading nb">Main</span></li>
                        <li className={location.pathname === "/Dashboard" ? 'active':'inactive'}><Link to={'/Dashboard'} ><span><i className="fa fa-tachometer fa-fw" aria-hidden="true"></i></span>Dashboard</Link></li>
                        <li><span className="heading">Campaign</span></li>
                        <li className={location.pathname === "/AllCampaign" ? 'active':'inactive'}><Link to={'/AllCampaign'}><span><i className="fa fa-user-o fa-fw" aria-hidden="true"></i></span>All Campaigns</Link></li>
                        <li className={location.pathname === "/AddCampaign" ? 'active':'inactive'}><Link to={'/AddCampaign'}><span><i className="fa fa-user-plus fa-fw" aria-hidden="true"></i></span>Add Campaign</Link></li>
                        <li><span className="heading">Territory</span></li>
                        <li className={location.pathname === "/AllTerritories" ? 'active':'inactive'}><Link to={'/AllTerritories'}><span><i className="fa fa-globe fa-fw" aria-hidden="true"></i></span>All Territories</Link></li>
                        <li><span className="heading">Template</span></li>
                        <li className={location.pathname === "/MessageTemplate" ? 'active':'inactive'}><Link to={'/MessageTemplate'}><span><i className="fa fa-globe fa-fw" aria-hidden="true"></i></span>Message Template</Link></li>
                        <li><span className="heading">Contact</span></li>
                        <li className={location.pathname === "/Contact" ? 'active':'inactive'}><Link to={'/Contact'}><span><i className="fa fa-globe fa-fw" aria-hidden="true"></i></span>All Contacts</Link></li>
                        
                    </ul>
                </div>             
            </div>          
            :null
            }
            <div className="tl-menu menu-absolute menu-sticky">
                <nav className="navbar navbar-expand-lg p-0">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="#">
                            <img src="assets/images/logo2.png" alt="Logo" className="img-fluid logo-lg logoimg" style={{width: '25%'}}/>
                            <img src="assets/images/logo-sm.png" alt="Logo" className="img-fluid logo-sm" style={{width: '25%'}} />
                        </Link>                        
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fa fa-align-justify"></i></span>
                        </button>
                        <div className="right-content">
                            {/* <form className="tl-search-form align-self-center" role="search" action="#">
                                <input type="search" className="form-control" placeholder="Search here..." />
                                <button type="submit" id="search-submit" className="btn"><i className="fa fa-search"></i></button>
                            </form> */}

                            {/* <div className="tl-search">
                                <div className="search-icon">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </div>
                                <div className="search-form text-center open-search">
                                    <div className="close-search">
                                        <span><i className="fa fa-times-circle" aria-hidden="true"></i></span>
                                    </div>
                                    <form action="#" id="search" method="get">
                                        <input id="inputhead" name="search" type="text" placeholder="Enter keyword..." />
                                        <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    </form>
                                </div>                    
                            </div> */}

                            <div className="tl-user-option align-self-center">
                                {/* <ul className="global-list d-flex align-self-center">
                                    <li><Link to="#"><i className="fa fa-th-large" aria-hidden="true"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-envelope-o" aria-hidden="true"></i> <span>3</span></Link></li>
                                    <li><Link to="#"><i className="fa fa-bell-o" aria-hidden="true"></i></Link></li>
                                </ul> */}
                                <ul className="global-list d-flex align-self-center">
                                   <li style={{color:'white',height:'10px'}}>{sessions_data.firstName} {sessions_data.lastName}</li>
                                </ul>
                                <div className="tl-user align-self-center">
                                    <div className="author dropdown" style={{height:'28px'}}>
                                        <Link to="#" className="dropdown-toggle" type="button" id="tl-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user fa-2x" aria-hidden="true" style={{color:'white'}} ></i></Link>

                                        <div className="dropdown-menu" aria-labelledby="tl-dropdown">
                                            <Link  className="dropdown-item" to={'/UserProfile'}> Profile</Link>
                                            {/* <Link  className="dropdown-item" to="#">Settings</Link> */}
                                            <div className="dropdown-divider"></div>
                                            <Link  className="dropdown-item text-danger" to="#" onClick={logoutHandler}> Logout</Link>
                                        </div>                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>                              
            </div>      
            
            </header>:null}

             
        </React.Fragment>

    );
}


export default connect(null,{unsetLogin})(LoginHeader);
// export default Header;