import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { MultiSelect } from "../../../Components/common/Multiselect/multiSelect";
import { connect } from 'react-redux';
import { get_all_compnay_user_subterritories, get_all_compnay_user_triggers, get_all_compnay_users_mail_boxes, create_campaign,closeAlert } from '../../../redux/action/companyUser/companyUserActionCreator';
import DrawMap from './map';
import Geocode from 'react-geocode';
import Alert from 'react-bootstrap/Alert';
// import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const CreateCampaign = (props) => {
  const [campaginName, setcampaginName] = useState('');
  const [campaginEmail, setCampaginEmail] = useState([]);
  const [mapLatLong, setMapLatLong] = useState({ lat: -34.397, lng: 150.644 });
  const [selectedSubterritory, setSelectedPolygons] = useState('');
  const [selectedterritoriesList, setSelectedterritoriesList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState('');
  const [triggersList, setTriggersList] = useState([]);
  const [selectedTriggersList, setSelectedTriggersList] = useState([]);
  // const [triggerId,setTriggersVal] =useState();
  const [territoriesList, setTerritoriesList] = useState([]);
  const [mapOpen, setMapOpen] = useState(false);
  const [showMap, setShowMap] = useState();
  //const [territories,setTerritories] =useState('');
  // const [inputlistErr, setInputListErr] = useState('');
  // const [addMore, setAddMore] = useState(false);
  // const [inputList, setInputList] = useState([]);
  const [mailBoxList, setMailBoxList] = useState([]);
  //const [selectedMailBoxList, setSelectedMailBoxList] = useState([]);
   const [error,setError]=useState({
        campaginNameErr:'',
        campaginEmailErr:'',
        campaginTerritoryErr:'',
        triggerErr:'',
    });

  useEffect(() => {
    props.get_all_compnay_user_subterritories().then(result => {
      setTerritoriesList(result);
    });
    props.get_all_compnay_user_triggers().then(result => {
      setTriggersList(result);
    });
    props.get_all_compnay_users_mail_boxes().then(result => {
      setMailBoxList(result);
    })
    props.closeAlert(false);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //set input list for map
  //   const setTerritoryInputList = (list) =>{
  //       setInputList(list);
  //   }

  //   //set ziplist
  //   const zipcodeList =(e,id,index)=>{
  //     id.zipCodeDTOS =e;
  //      const list = [...inputList];
  //      list[index] = id;
  //      setInputList(list);
  //  }


  //console.log(selectedTerritoryMap);
  const mailBoxOptions = mailBoxList && mailBoxList.map((item, i) => {
    return <option id={item.id} key={i} >{item.emailAddress}</option>
  });

  const { companySubTerritoryDTOS } = territoriesList || {};
  const options = companySubTerritoryDTOS && companySubTerritoryDTOS.map(item => { 
    const val= {  id: item.id, label: item.territoryName,value: item.territoryName,isActive: item.isActive};
      return val;
    // return <option id={item.id} key={item.id} data-key={item.id}>{item.territoryName}</option>
    // const val= {  id: item.id, territoryName:  item.territoryName};
    // return val;
  });

  const triggerOptions = triggersList && triggersList.map(item => {
    const val= {  id: item.id, label: item.triggerDisplayName,value: item.triggerValue};
      return val;
  });


  //emailChangeHangler
  const emailChangeHangler=(e)=>{
    let mailDetails=mailBoxList.filter(item => item.emailAddress === e.target.value)
    setCampaginEmail(mailDetails);
  }

  //console.log(triggerOptions);
  // const zipCodesList =  companySubTerritoryDTOS && companySubTerritoryDTOS.map(item => {
  //  return item.zipcode.map(zip => {
  //     const val= {  id: zip.id, label: zip.zipCode,value: zip.zipCode};
  //     return val});
  //    // return zipcode;
  //   });
  //   console.log(zipCodesList)
  // const zip = zipCodesList && zipCodesList.map(zip => {
  //   // console.log(zip)
  //   return  {  id: zip.id, label: zip.label,value: zip.value};
  // });

  // console.log(zip);
  // const triggerIdHandler = (e) =>{
  //     const selectedIndex = e.target.options.selectedIndex;
  //    let triggerId=e.target.options[selectedIndex].getAttribute('data-key')
  //   setTriggersVal(triggerId);
  // }

  const setZipcodeHandler = (e) => {
   if(e.length !== 0){
     let value='';
    let zipcodes = [];
    let polygonsCordsList = [];
    
    e.map(territory =>{
       
      value = companySubTerritoryDTOS.filter(item => item.territoryName === territory.value);
      value && value.forEach(item => {
        const polygonCords = item.polygonsDTO.coordinates.map(coordinate => 
          {
         const  latLng  = {lng:parseFloat(coordinate.longitude),lat: parseFloat(coordinate.latitude)}
        return latLng}
          )
        const zipcodesWithSubTerritoriesName = item.zipcode.map(zip => {
          const val = { label: zip.zipCode, value: zip.zipCode,id:zip.id, boundaries:zip.boundaries};
          return val;
          // return zip;
        })
        zipcodes = [...zipcodes, ...zipcodesWithSubTerritoriesName];
        polygonsCordsList = [...polygonsCordsList, polygonCords];
      });
      return zipcodes;
     });  
    setZipcodeList(zipcodes);
    setSelectedterritoriesList(e);
    setSelectedPolygons(polygonsCordsList);
   // setSelectedPolygons(value);

    
    // setPolygon(false);
  
    setInterval(
      Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k"),
      Geocode.fromAddress(zipcodes[0].value).then(
      (response) => {
        const data = response.results[0].geometry.location;
        setShowMap(true);
        
    setMapOpen(true);
        setMapLatLong({ lat: data.lat, lng: data.lng });
      },
      (error) => {
        setMapLatLong({ lat: -34.397, lng: 150.644 });
        setShowMap(true);
      })
      ,50000);
    }else{
      setShowMap(false);
      
    setMapOpen(false);
    setZipcodeList();
    setSelectedPolygons();
    setSelectedterritoriesList();
    }



  }
// console.log(selectedSubterritory)
 //show map
  // const handlePolygonChange = (e) => {
  //   //debugger
  //   setPolygon(!polygon);
  //   if(e === true){
  //   Geocode.setApiKey("AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k");
  //   Geocode.fromAddress(territoryName).then(
  //     (response) => {
  //       const data = response.results[0].geometry.location;
  //       setShowMap(true);
  //       setMapLatLong({ lat: data.lat, lng: data.lng });
  //     },
  //     (error) => {
  //       setMapLatLong({ lat: -34.397, lng: 150.644 });
  //       setShowMap(true);
  //     })
  //   }else{
  //     setShowMap(false);
  //   }
  // };
  // selectedSubterritory && selectedSubterritory.forEach(subterritory => {
  //   const polygon = [];
  //   subterritory.polygonsDTO.coordinatesDTOS.forEach(coordinate => 
  //   polygon.push(coordinate)
  //   )
  //   polygons.push(polygon);
  //   // setPolygons(polygons=>[...polygons,polygon])
  // });
  const validate = (e) => {
      let campaginNameVal = territoryNameValidation(campaginName);
      let mailboxVal = mailboxValidation(campaginEmail);
      let trrigerListVal = trrigerListValidation(selectedTriggersList);
      let subterritroyVal = subterritroyValidation(selectedSubterritory);
      if(campaginNameVal === true && mailboxVal === true && trrigerListVal === true && subterritroyVal === true){
        return true;
      }
    }

    function territoryNameValidation(campaginName) { 

      if(!campaginName) {
          setError(prevState => ({
              ...prevState,
              territoryNameErr: 'Please Enter Territory Name'
          }));
        return false;
      }
      // if (!/^[a-zA-Z ]+$/.test(campaginName)){
      //     setError(prevState => ({
      //         ...prevState,
      //         territoryNameErr: 'Please Enter Only Characters'
      //     }));
      //   return false;
      // }
      if(campaginName) {
          setError(prevState => ({
              ...prevState,
              territoryNameErr: ''
          }));
        return true;
      }
    }
    function mailboxValidation(campaginEmail) {  
      if(campaginEmail.length === 0){
          setError(prevState => ({
              ...prevState,
              campaginEmailErr: 'Please Select Email Box'
          }));
          return false;
      }
      if(campaginEmail.length > 0){
          setError(prevState => ({
              ...prevState,
              campaginEmailErr: ''
          }));
          return true;
      }
    }
    function trrigerListValidation(selectedTriggersList) {
      if(selectedTriggersList.length === 0){
          setError(prevState => ({
              ...prevState,
              triggerErr: 'Please Select Trigger'
          }));
          return false;
      }
      if(selectedTriggersList.length >0){
          setError(prevState => ({
              ...prevState,
              triggerErr: ''
          }));
          return true;
      }

    }
    function subterritroyValidation(selectedSubterritory) {
      if(selectedSubterritory.length === 0){
          setError(prevState => ({
              ...prevState,
              campaginTerritoryErr: 'Please Select Territory'
          }));
          return false;
      }
      if(selectedSubterritory.length >0){
          setError(prevState => ({
              ...prevState,
              campaginTerritoryErr: ''
          }));
          return true;
      }

    }


  const addCampaignHandler = async (e) => {
    e.preventDefault();
    //console.log(citiesList);companySubTerritoryId: 12, territoryName: "california",
     if(validate()){
      const subTerritoryDetails = selectedterritoriesList && selectedterritoriesList.map(item =>  { 
        return {id: item.id,territoryName:item.label,isActive:item.isActive}});
      const payload_data = {
        companySubTerritoryDTO:subTerritoryDetails,
        campaign_name: campaginName,
        mailBox: campaginEmail[0],
        triggers : selectedTriggersList,
        status: "DRAFT"
      };
      //console.log(payload_data);
      props.create_campaign(payload_data, props.history);
      
       }
  }

  return (
    <div className="tl-section">
      <div className="tl-dashboard bg-color">
        <div className="container-fluid p-0">
          <div className="main">
            <h1 className="db-title">Add Campaign</h1>
            <Alert id="alert" className="alert" variant={props.campaginAlert.resultType} show={props.campaginAlert.show} 
                    onClose={() => props.closeAlert(false)} dismissible
                    >
                      <p>{props.campaginAlert.msg}</p>
                    </Alert>
            <div className="add-agent bg-white sub-section">
              <form action="#" className="lt-form">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="campaign">Campaign Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Campaign Name"
                        value={campaginName}
                        onChange={(e) => setcampaginName(e.target.value)}
                      />
                      {error.territoryNameErr ? <span className="error-span">{error.territoryNameErr}</span>:null}
                    </div>

                  </div>
                  <div className="col-md-4">

                    <div className="form-group">
                    <label htmlFor="mailbox">Assign Mail Box</label>
                      <select
                        className="form-control"
                        onChange={(e) => emailChangeHangler(e)}
                      >
                        <option value="0">Assign Mail Box</option>
                        {mailBoxOptions}
                      </select>
                      {error.campaginEmailErr ? <span className="error-span">{error.campaginEmailErr}</span>:null}
                    </div>

                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                    <label htmlFor="trigger">Select Triggers</label>
                      <MultiSelect options={triggerOptions || []} value={selectedTriggersList} placeholder="Select Triggers" onChange={setSelectedTriggersList} />

                      {/* <select
                                         className="form-control"
                                            defaultValue="0"
                                            onChange={(e)=>triggerIdHandler(e)}
                                            required 
                                            >
                                            <option value="0">Select Trigger</option>
                                            {triggerOptions}
                                        </select> */}
                      {error.triggerErr ? <span className="error-span">{error.triggerErr}</span>:null}

                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                    <label htmlFor="territory">Assign Territory</label>
                    <MultiSelect options={options || []} value={selectedterritoriesList || []} placeholder="Select Territory" onChange={setZipcodeHandler} />
                      
                      {/* <select
                        className="form-control"
                        onChange={setZipcodeHandler}
                      >
                        <option value="0">Assign Territory</option>
                        {options}
                      </select> */}
                      {error.campaginTerritoryErr ? <span className="error-span">{error.campaginTerritoryErr}</span>:null}
                    </div>
                  </div>
                  {mapOpen ? (<React.Fragment>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="zipcode">Assigned Zipcodes</label>
                        <MultiSelect options={[]} value={zipcodeList || []} placeholder="Assign Zipcodes" className="disabledinpt" isDisabled={true} closeMenuOnSelect={true} isSearchable={false} />
                      </div>
                    </div>
                    {/* <div className="col-lg-3">
                      <div className="from-group">
                      <label htmlFor="toggle" style={{visibility:'hidden',display:'block'}}>Toggle Map</label>
                        <BootstrapSwitchButton
                          checked={polygon}
                          name="polygon"
                          onlabel='Hide &#x1f4cc;'
                          offlabel='Show &#x1f4cc;'
                          onChange={handlePolygonChange}
                          width={100}
                        />

                      </div>
                    </div> */}
                    </React.Fragment>) : null}

                </div>
                {showMap ? <React.Fragment>
                  <label htmlFor="ploygon">Assigned polygon:</label> 
                  <DrawMap
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4WgeEc45s-xeWRg9it8n3T5kb6m2z_8k&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  lat={mapLatLong.lat}
                  lng={mapLatLong.lng}
                  // setpolygonsDTO={polygons}
                  selectedpolygonsDTO={selectedSubterritory}
                  editPolygon={showMap}
                  setZipcodeBoundaries={zipcodeList}
                /><br/></React.Fragment> : null}

                <button className="btn btn-primary" onClick={addCampaignHandler}>Add Campaign</button>
              </form>
            </div>
            <div className="copy-right text-center">
              <span>Leadomatic &copy; 2024 Developed by <Link to="#" onClick={()=> window.open("https://cloudfirstgroup.com/", "_blank")}>Cloud First</Link></span>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

const mapStateToProps = state => {
  return {
    campaginAlert: state.CompanyUser.alert,
  }
}

export default connect(mapStateToProps, { get_all_compnay_user_subterritories,closeAlert, get_all_compnay_user_triggers, get_all_compnay_users_mail_boxes, create_campaign })(withRouter(CreateCampaign));
//export default CreateTerritory;