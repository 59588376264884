import { GRANDWELCOME_DOMAIN } from "../configs/baseUrls";

export const CITYS_ZIPCODES_URL = `${GRANDWELCOME_DOMAIN}/registration/get-all-city-zipcodes/`;
export const ALL_STATECODES_URL = `${GRANDWELCOME_DOMAIN}/registration/get-all-state-codes`;
export const ADD_ZIPCODES_URL = `${GRANDWELCOME_DOMAIN}/product-admin/insert-zipcodes`;
export const CREATE_COMPNAY_URL = `${GRANDWELCOME_DOMAIN}/product-admin/save-company`;
export const GET_COMPNAY_URL = `${GRANDWELCOME_DOMAIN}/product-admin/get-company-by-id/`;
export const COMPNAY_LIST_URL = `${GRANDWELCOME_DOMAIN}/product-admin/get-all-companies`;
export const CREATE_TERRITORY_URL = `${GRANDWELCOME_DOMAIN}/product-admin/save-company-territories`;
export const TERRITORY_LIST_URL = `${GRANDWELCOME_DOMAIN}/product-admin/get-all-company-territories`;
export const GET_TERRITORY_URL = `${GRANDWELCOME_DOMAIN}/company-admin/get-company-territory-by-id/`;
export const GET_COMPNAY_USER_URL = `${GRANDWELCOME_DOMAIN}/company-admin/get-company-user-by-id/`;
export const GET_ALL_COMPNAY_USER_URL = `${GRANDWELCOME_DOMAIN}/company-admin/get-all-company-users-by-role`;
export const TERRITORY_ACTIVE_LIST_URL = `${GRANDWELCOME_DOMAIN}/product-admin/get-all-active-company-territories`;
export const CREATE_COMPNAY_USER_URL = `${GRANDWELCOME_DOMAIN}/company-admin/save-company-user`;
export const CREATE_COMPNAY_MAIL_BOX = `${GRANDWELCOME_DOMAIN}/company-admin/save-mail-box`;
export const GET_ALL_COMPNAY_MAIL_BOX = `${GRANDWELCOME_DOMAIN}/company-admin/get-all-mail-boxes`;
export const GET_ALL_COMPNAY_MAIL_BOX_BY_ID_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-mail-box-by-id/`;
export const COMPNAY_USERS_LIST_URL = `${GRANDWELCOME_DOMAIN}/company-admin/get-all-company-users`;
export const COMPANY_TERRITORY_LIST_URL = `${GRANDWELCOME_DOMAIN}/company-admin/get-company-territories`;
export const COMPANY_TERRITORY_INFO_LIST_URL = `${GRANDWELCOME_DOMAIN}/company-admin/get-company-territories-info`;
export const COMPANY_USERS_TERRITORY_LIST_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-company-user-territories`;
export const COMPANY_USERS_TRIGGER_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-all triggers`;
export const CREATE_COMPANY_USER_CAMPAIGN_URL = `${GRANDWELCOME_DOMAIN}/company-user/save-campaign`;
export const GET_ALL_COMPANY_USER_CAMPAIGN_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-all-campaigns`;
export const GET_COMPANY_USERS_MAILBOX_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-mail-boxes`;
export const GET_COMPANY_USER_CAMPAIGN_BY_ID_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-campaign-details-by-id/`;
export const DELETE_COMPANY_USER_CAMPAIGN_BY_ID_URL = `${GRANDWELCOME_DOMAIN}/company-user/delete-campaign-by-id/`;
export const DUPLICATE_COMPANY_USER_CAMPAIGN_BY_ID_URL = `${GRANDWELCOME_DOMAIN}/company-user/duplicate-campaign-by-id/`;
export const SAVE_COMPANY_SUBTERRITORIES = `${GRANDWELCOME_DOMAIN}/company-admin/save-company-subterritory`;
export const GET_COMPANY_SUBTERRITORIES = `${GRANDWELCOME_DOMAIN}/company-admin/get-all-company-subterritories`;
export const GET_COMPANY_SUBTERRITORIES_BY_ID = `${GRANDWELCOME_DOMAIN}/company-admin/get-company-subterritory-by-id/`;
export const DELETE_COMPANY_SUBTERRITORY_BY_ID = `${GRANDWELCOME_DOMAIN}/company-admin/delete-company-subterritory-by-id/`;
export const GET_ALL_USERS_SUBTERRITORY = `${GRANDWELCOME_DOMAIN}/company-user/get-all-company-subterritories`;
export const GET_ALL_USERS_SUBTERRITORY_BY_ID_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-company-subterritory-by-id/`;
export const SAVE_MESSAGE_TEMPLATE_URL = `${GRANDWELCOME_DOMAIN}/company-user/save-message-template`;
export const GET_MESSAGE_TEMPLATE_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-email-template-by-trigger-id/`;
export const CHANGE_CAMPAIGN_STATUS_URL = `${GRANDWELCOME_DOMAIN}/company-user/change-campaign-status`;
export const GET_PROPERITIES_BY_CAMPAIGN_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-campaign-property-list-by-id`;
export const GET_PROPERITIES_BY_PROPERTY_ID_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-campaign-property-by-id/`;
export const GET_CONTACT_LIST_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-contact-list`;
export const GET_DASHBOARD_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-dashboard-details`;
export const GET_CONTACT_BY_ID_URL = `${GRANDWELCOME_DOMAIN}/company-user/get-agent-details-by-id/`;
export const UPDATE_CONTACT_STATUS_URL = `${GRANDWELCOME_DOMAIN}/company-user/update-contact-status`;
export const UNSUBSCRIBE_URL = `${GRANDWELCOME_DOMAIN}/registration/unsubscribe/`;